import * as THREE from "three";
import { GLTFLoader } from "../lib/GLTFLoader.js";
import { GLTFExporter } from "../lib/GLTFExporter.js";
import { USDZExporter } from "../lib/USDZExporter.js";
import * as SceneUtils from "../lib/SceneUtils.js";

export const oModelViewer = {
  raf: null,
  pxToMeter: 1 / 2835, // 3779.527559 for 96 dpi and 2835 for 72 dpi
  multiplier: 5,

  /**
   * @param {HTMLElement} oTargetElem
   * @returns {void}
   */
  init: (oTargetElem) => {
    const oSize = oTargetElem.getBoundingClientRect();

    // Create components
    oModelViewer.scene = new THREE.Scene();

    oModelViewer.camera = new THREE.PerspectiveCamera(100, oSize.width / oSize.height, 0.1, 1000);
    oModelViewer.camera.position.set(0, 0, 0.5);
    oModelViewer.camera.updateProjectionMatrix();

    oModelViewer.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true
    });
    oModelViewer.renderer.setPixelRatio(window.devicePixelRatio);
    oModelViewer.renderer.setSize(oSize.width, oSize.height);
    oModelViewer.renderer.setClearColor(0x000000, 0);

    oModelViewer.container = oTargetElem;

    // Create canvas
    oTargetElem.appendChild(oModelViewer.renderer.domElement);

    // Update size on events
    window.addEventListener('resize', oModelViewer.resize);
    window.addEventListener('orientationchange', oModelViewer.resize);
  },

  /**
   * @returns {void}
   */
  resize: () => {
    const oSize = oModelViewer.container.getBoundingClientRect();

    if (oModelViewer.image?.cube) {
      // Resize dependent on screen orientation
      const bHorizontal = oModelViewer.image.width >= oModelViewer.image.height;
      oModelViewer.sceneSize.width = bHorizontal ? oSize.width : oModelViewer.sceneSize.height * oModelViewer.image.aspect;
      oModelViewer.sceneSize.height = bHorizontal ? oModelViewer.sceneSize.width * Math.pow(oModelViewer.image.aspect, -1) : oSize.height;
    }
    //  else {
    //   oModelViewer.sceneSize.width = oSize.width;
    //   oModelViewer.sceneSize.height = oSize.height;
    // }

    oModelViewer.camera.aspect = oModelViewer.sceneSize.width / oModelViewer.sceneSize.height;
    oModelViewer.camera.updateProjectionMatrix();
    oModelViewer.renderer.setSize(oModelViewer.sceneSize.width, oModelViewer.sceneSize.height);
    oModelViewer.render();
  },

  /**
   * @returns {void}
   */
  render: () => oModelViewer.renderer.render(oModelViewer.scene, oModelViewer.camera),

  /**
   * @returns {void}
   */
  clearScene: () => {
    delete (oModelViewer.image);
    delete (oModelViewer.model);

    while (oModelViewer.scene.children.length > 0) oModelViewer.scene.remove(oModelViewer.scene.children[0]);
  },

  /**
   * @returns {void}
   */
  addLights: () => {
    [{
      x: 0,
      y: 0,
      z: 100
    },
    {
      x: 0,
      y: 0,
      z: -100
    },
    {
      x: 0,
      y: 100,
      z: 0
    },
    {
      x: 0,
      y: -100,
      z: 0
    },
    {
      x: 100,
      y: 0,
      z: 0
    },
    {
      x: -100,
      y: 0,
      z: 0
    }
    ].forEach(oCoords => {
      const oSpotLight = new THREE.SpotLight(0xFFFFFF);
      oSpotLight.position.set(oCoords.x, oCoords.y, oCoords.z);

      oSpotLight.castShadow = false;

      oSpotLight.shadow.mapSize.width = 1024;
      oSpotLight.shadow.mapSize.height = 1024;

      oSpotLight.shadow.camera.near = 500;
      oSpotLight.shadow.camera.far = 4000;
      oSpotLight.shadow.camera.fov = 30;

      oModelViewer.scene.add(oSpotLight);

      oSpotLight.target.position.set(0, 0, -1);
    });
  },

  /**
   * @param {string} sModelUrl
   * @returns {Promise<boolean>}
   */
  load3d: (sModelUrl) => new Promise(resolve => {
    new GLTFLoader().load(
      sModelUrl,
      gltf => {
        oModelViewer.model = gltf.scene;
        oModelViewer.scene.add(gltf.scene);
        resolve();
      },
      undefined,
      oError => {
        console.log('Error while loading model', oError);
        resolve();
      });
  }),

  /**
   * @param {string} sImageUrl
   * @param {'wall' | 'floor'} sPlacement
   * @param {boolean} bUsdzHack
   * @returns {Promise<any>}
   */
  loadImage3d: async (sImageUrl, sPlacement = 'wall', bUsdzHack = false, iHeight = null) => {
    oModelViewer.sceneSize = {
      width: 0,
      height: 0
    };

    oModelViewer.image = {
      width: 0,
      height: 0,
      depth: 10,
      aspect: 0,
      placement: sPlacement,
      cube: null,
      cubeScaleCopy: null,
      lastRotation: {
        x: 0,
        y: 0,
        z: 0
      }
    };

    const [oTexture, oTexture2] = await Promise.all([
      new Promise(resolve => new THREE.TextureLoader().load(
        sImageUrl,
        oTexture => {
          oModelViewer.sceneSize.width = oTexture.image.width;
          oModelViewer.sceneSize.height = oTexture.image.height;
          oModelViewer.image.width = oTexture.image.width;
          oModelViewer.image.height = oTexture.image.height;
          oModelViewer.image.aspect = oTexture.image.width / oTexture.image.height;

          // Override image size
          if (iHeight) {
            oModelViewer.image.height = iHeight * oModelViewer.pxToMeter;
            oModelViewer.image.width = oModelViewer.image.height * oModelViewer.image.aspect;
          }

          oTexture.wrapS = THREE.RepeatWrapping;

          if (bUsdzHack) {
            oTexture.encoding = THREE.sRGBEncoding;
            oTexture.flipY = false;
            oTexture.repeat.x = - 1;
          }

          resolve(oTexture);
        },
        () => null,
        oError => {
          console.log('Error while loading texture', oError);
          resolve(null);
        }
      )),
      new Promise(resolve => new THREE.TextureLoader().load(
        sImageUrl,
        oTexture => {
          oTexture.wrapS = THREE.RepeatWrapping;

          if (bUsdzHack) {
            oTexture.encoding = THREE.sRGBEncoding;
            oTexture.flipY = false;
          } else {
            oTexture.repeat.x = -1;
          }

          resolve(oTexture);
        },
        () => null,
        oError => {
          console.log('Error while loading texture', oError);
          resolve(null);
        }
      ))
    ]);

    if (!oTexture || !oTexture2) return;

    const bTop = oModelViewer.image.placement === 'floor';

    const oTextureFront = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 1,
      map: oTexture
    });

    const oTextureBack = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 1,
      map: oTexture2
    });
    oTextureBack.color.setHSL(0, 0, 0.1);

    const oTextureTransparent = new THREE.MeshStandardMaterial({
      transparent: true,
      color: 0x000000,
      opacity: 0
    });

    const aMaterials = [null, null, null, null].map(() => oTextureTransparent); // Right, left, top, bottom, front, back
    aMaterials.splice((bTop ? 2 : 4), 0, ...[oTextureFront, oTextureBack]); // Insert textures after 2nd or 4th position

    oModelViewer.image.cube = new SceneUtils.createMeshesFromMultiMaterialMesh(new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), aMaterials));
    oModelViewer.scene.add(oModelViewer.image.cube);

    // Render
    const oSize = oModelViewer.container.getBoundingClientRect();

    oModelViewer.image.cube.scale.x = (oModelViewer.image.width >= oModelViewer.image.height ? oSize.width : (oSize.height * oModelViewer.image.aspect)) * oModelViewer.multiplier * oModelViewer.pxToMeter;
    oModelViewer.image.cube.scale[bTop ? 'z' : 'y'] = (oModelViewer.image.width >= oModelViewer.image.height ? (oSize.width * Math.pow(oModelViewer.image.aspect, -1)) : oSize.height) * oModelViewer.multiplier * oModelViewer.pxToMeter;
    oModelViewer.image.cube.scale[bTop ? 'y' : 'z'] = oModelViewer.image.depth * oModelViewer.multiplier * oModelViewer.pxToMeter;

    oModelViewer.image.cubeScaleCopy = {
      x: oModelViewer.image.cube.scale.x,
      y: oModelViewer.image.cube.scale.y,
      z: oModelViewer.image.cube.scale.z
    };

    oModelViewer.image.cube.position[bTop ? 'y' : 'z'] = oModelViewer.image.depth * oModelViewer.multiplier * oModelViewer.pxToMeter * -1;
  },

  /**
   * @returns {void}
   */
  animate: () => {
    if (oModelViewer.raf !== null) cancelAnimationFrame(oModelViewer.raf);
    oModelViewer.animateRaf();
  },

  /**
   * @returns {void}
   */
  animateRaf: () => {
    oModelViewer.raf = requestAnimationFrame(oModelViewer.animateRaf);

    if (oModelViewer.stopAnimation || !oModelViewer.image?.cube) return;

    const fnDegToRad = iDeg => iDeg * Math.PI / 180;

    if (!oModelViewer.animationDirection) oModelViewer.animationDirection = 1;
    if (Math.abs(oModelViewer.image?.cube?.rotation?.y || 0) >= fnDegToRad(20)) oModelViewer.animationDirection *= -1;

    // Resize dependent on screen orientation
    const oSize = oModelViewer.container.getBoundingClientRect();
    const bTop = oModelViewer.placement === 'floor';
    const iMultiplier = oModelViewer.multiplier * oModelViewer.pxToMeter;

    oModelViewer.image.cube.scale.x = (oModelViewer.image.width >= oModelViewer.image.height ? oSize.width : (oSize.height * oModelViewer.image.aspect)) * oModelViewer.multiplier * oModelViewer.pxToMeter;
    oModelViewer.image.cube.scale[bTop ? 'z' : 'y'] = (oModelViewer.image.width >= oModelViewer.image.height ? (oSize.width * Math.pow(oModelViewer.image.aspect, -1)) : oSize.height) * oModelViewer.multiplier * oModelViewer.pxToMeter;
    oModelViewer.image.cube.scale[bTop ? 'y' : 'z'] = oModelViewer.image.depth * oModelViewer.multiplier * oModelViewer.pxToMeter;

    oModelViewer.image.cube.position[bTop ? 'y' : 'z'] = oModelViewer.image.depth * iMultiplier * -1;

    // Rotation
    const [iX, iY, iZ] = [
      bTop ? fnDegToRad(60) : 0,
      (oModelViewer.image.cube?.rotation?.y || 0) + fnDegToRad(0.25) * oModelViewer.animationDirection,
      0
    ];
    oModelViewer.image.cube.rotation.x = iX;
    oModelViewer.image.cube.rotation.y = iY;
    oModelViewer.image.cube.rotation.z = iZ;
    oModelViewer.image.lastRotation.x = iX;
    oModelViewer.image.lastRotation.y = iY;
    oModelViewer.image.lastRotation.z = iZ;

    oModelViewer.render();
  },

  /**
   * @returns {Promise<{usdz: any;glb?: undefined;} | {glb: any;usdz: any;}}>
   */
  createFiles: async () => {
    // Stop animation
    oModelViewer.stopAnimation = true;

    // Wait 100ms to be sure the rotation is 0
    await new Promise(resolve => setTimeout(resolve, 100));

    // Reset scale and rotation
    if (oModelViewer.image?.cube) {
      oModelViewer.image.cube.scale.x = oModelViewer.image.width * oModelViewer.pxToMeter;
      oModelViewer.image.cube.scale.y = oModelViewer.image.height * oModelViewer.pxToMeter;
      oModelViewer.image.cube.scale.z = oModelViewer.image.depth * oModelViewer.pxToMeter;

      oModelViewer.image.cube.rotation.x = 0;
      oModelViewer.image.cube.rotation.y = 0;
      oModelViewer.image.cube.rotation.z = 0;
    }

    oModelViewer.render();

    const oFiles = oModelViewer.model ? {
      usdz: null
    } : {
      glb: null,
      usdz: null
    };

    const oOptions = {
      trs: true,
      onlyVisible: true,
      truncateDrawRange: true,
      binary: true,
      maxTextureSize: 4096
    };

    // Convert scene to 3d files
    if (oFiles.glb !== undefined) {
      try {
        await new Promise(resolve => setTimeout(resolve, 20));

        await new Promise(resolve => {
          const fnSuccess = oData => {
            if (oData instanceof ArrayBuffer) {
              const oFile = new Blob([oData], {
                type: 'application/octet-stream'
              });
              oFiles.glb = oFile;
            }
            resolve();
          };

          new GLTFExporter().parse(oModelViewer.scene, fnSuccess, oOptions);
        });
      } catch (oError) {
        console.log(oError);
      }
    }

    if (oFiles.usdz !== undefined) {
      try {
        const oExporter = await new USDZExporter();
        const oResultUsdz = await oExporter.parse(oModelViewer.scene);

        oFiles.usdz = await new Blob([oResultUsdz], {
          type: 'application/octet-stream'
        });
      } catch (oError) {
        console.log(oError);
      }
    }

    // Apply original scale and rotation
    if (oModelViewer.image?.cube) {
      oModelViewer.image.cube.rotation.x = oModelViewer.image.lastRotation?.x || 0;
      oModelViewer.image.cube.rotation.y = oModelViewer.image.lastRotation?.y || 0;
      oModelViewer.image.cube.rotation.z = oModelViewer.image.lastRotation?.z || 0;

      const bTop = oModelViewer.image.placement === 'floor';
      const oSize = oModelViewer.container.getBoundingClientRect();
      oModelViewer.image.cube.scale.x = (oModelViewer.image.width >= oModelViewer.image.height ? oSize.width : (oSize.height * oModelViewer.image.aspect)) * oModelViewer.multiplier * oModelViewer.pxToMeter;
      oModelViewer.image.cube.scale[bTop ? 'z' : 'y'] = (oModelViewer.image.width >= oModelViewer.image.height ? (oSize.width * Math.pow(oModelViewer.image.aspect, -1)) : oSize.height) * oModelViewer.multiplier * oModelViewer.pxToMeter;
      oModelViewer.image.cube.scale[bTop ? 'y' : 'z'] = oModelViewer.image.depth * oModelViewer.multiplier * oModelViewer.pxToMeter;
    }

    oModelViewer.render();

    oModelViewer.stopAnimation = false;
    oModelViewer.animate();

    return oFiles;
  },

  /**
    * @param {Blob} oFile
    * @param {string} sFileName
    * @param {string} sFileType
    * @returns {void}
    */
  downloadFile: (oFile, sFileName, sFileType) => {
    const oLink = document.createElement('a');
    oLink.style.display = 'none';
    document.body.appendChild(oLink);
    oLink.href = URL.createObjectURL(oFile);
    oLink.setAttribute('download', `${sFileName}.${sFileType}`);
    oLink.click();
    oLink.remove();
  }
};

export const oModelViewer2 = {
  raf: null,
  pxToMeter: 1 / 2835, // 3779.527559 for 96 dpi and 2835 for 72 dpi
  multiplier: 5,

  /**
   * @param {HTMLElement} oTargetElem
   * @returns {void}
   */
  init: (oTargetElem) => {
    const oSize = oTargetElem.getBoundingClientRect();

    // Create components
    oModelViewer2.scene = new THREE.Scene();

    oModelViewer2.camera = new THREE.PerspectiveCamera(100, oSize.width / oSize.height, 0.1, 1000);
    oModelViewer2.camera.position.set(0, 0, 0.5);
    oModelViewer2.camera.updateProjectionMatrix();

    oModelViewer2.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true
    });
    oModelViewer2.renderer.setPixelRatio(window.devicePixelRatio);
    oModelViewer2.renderer.setSize(oSize.width, oSize.height);
    oModelViewer2.renderer.setClearColor(0x000000, 0);

    oModelViewer2.container = oTargetElem;

    // Create canvas
    oTargetElem.appendChild(oModelViewer2.renderer.domElement);

    // Update size on events
    window.addEventListener('resize', oModelViewer2.resize);
    window.addEventListener('orientationchange', oModelViewer2.resize);
  },

  /**
   * @returns {void}
   */
  resize: () => {
    const oSize = oModelViewer2.container.getBoundingClientRect();

    if (oModelViewer2.image?.cube) {
      // Resize dependent on screen orientation
      const bHorizontal = oModelViewer2.image.width >= oModelViewer2.image.height;
      oModelViewer2.sceneSize.width = bHorizontal ? oSize.width : oModelViewer2.sceneSize.height * oModelViewer2.image.aspect;
      oModelViewer2.sceneSize.height = bHorizontal ? oModelViewer2.sceneSize.width * Math.pow(oModelViewer2.image.aspect, -1) : oSize.height;
    }
    //  else {
    //   oModelViewer2.sceneSize.width = oSize.width;
    //   oModelViewer2.sceneSize.height = oSize.height;
    // }

    oModelViewer2.camera.aspect = oModelViewer2.sceneSize.width / oModelViewer2.sceneSize.height;
    oModelViewer2.camera.updateProjectionMatrix();
    oModelViewer2.renderer.setSize(oModelViewer2.sceneSize.width, oModelViewer2.sceneSize.height);
    oModelViewer2.render();
  },

  /**
   * @returns {void}
   */
  render: () => oModelViewer2.renderer.render(oModelViewer2.scene, oModelViewer2.camera),

  /**
   * @returns {void}
   */
  clearScene: () => {
    delete (oModelViewer2.image);
    delete (oModelViewer2.model);

    while (oModelViewer2.scene.children.length > 0) oModelViewer2.scene.remove(oModelViewer2.scene.children[0]);
  },

  /**
   * @returns {void}
   */
  addLights: () => {
    [{
      x: 0,
      y: 0,
      z: 100
    },
    {
      x: 0,
      y: 0,
      z: -100
    },
    {
      x: 0,
      y: 100,
      z: 0
    },
    {
      x: 0,
      y: -100,
      z: 0
    },
    {
      x: 100,
      y: 0,
      z: 0
    },
    {
      x: -100,
      y: 0,
      z: 0
    }
    ].forEach(oCoords => {
      const oSpotLight = new THREE.SpotLight(0xFFFFFF);
      oSpotLight.position.set(oCoords.x, oCoords.y, oCoords.z);

      oSpotLight.castShadow = false;

      oSpotLight.shadow.mapSize.width = 1024;
      oSpotLight.shadow.mapSize.height = 1024;

      oSpotLight.shadow.camera.near = 500;
      oSpotLight.shadow.camera.far = 4000;
      oSpotLight.shadow.camera.fov = 30;

      oModelViewer2.scene.add(oSpotLight);

      oSpotLight.target.position.set(0, 0, -1);
    });
  },

  /**
   * @param {string} sModelUrl
   * @returns {Promise<boolean>}
   */
  load3d: (sModelUrl) => new Promise(resolve => {
    new GLTFLoader().load(
      sModelUrl,
      gltf => {
        oModelViewer2.model = gltf.scene;
        oModelViewer2.scene.add(gltf.scene);
        resolve();
      },
      undefined,
      oError => {
        console.log('Error while loading model', oError);
        resolve();
      });
  }),

  /**
   * @param {string} sImageUrl
   * @param {'wall' | 'floor'} sPlacement
   * @param {boolean} bUsdzHack
   * @returns {Promise<any>}
   */
  loadImage3d: async (sImageUrl, sPlacement = 'wall', bUsdzHack = false, iHeight = null) => {
    oModelViewer2.sceneSize = {
      width: 0,
      height: 0
    };

    oModelViewer2.image = {
      width: 0,
      height: 0,
      depth: 10,
      aspect: 0,
      placement: sPlacement,
      cube: null,
      cubeScaleCopy: null,
      lastRotation: {
        x: 0,
        y: 0,
        z: 0
      }
    };

    const [oTexture, oTexture2] = await Promise.all([
      new Promise(resolve => new THREE.TextureLoader().load(
        sImageUrl,
        oTexture => {
          oModelViewer2.sceneSize.width = oTexture.image.width;
          oModelViewer2.sceneSize.height = oTexture.image.height;
          oModelViewer2.image.width = oTexture.image.width;
          oModelViewer2.image.height = oTexture.image.height;
          oModelViewer2.image.aspect = oTexture.image.width / oTexture.image.height;

          // Override image size
          if (iHeight) {
            oModelViewer2.image.height = iHeight * oModelViewer2.pxToMeter;
            oModelViewer2.image.width = oModelViewer2.image.height * oModelViewer2.image.aspect;
          }

          oTexture.wrapS = THREE.RepeatWrapping;

          if (bUsdzHack) {
            oTexture.encoding = THREE.sRGBEncoding;
            oTexture.flipY = false;
            oTexture.repeat.x = - 1;

            const oMapCanvas = document.createElement('canvas');
            oMapCanvas.width = oTexture.image.width;
            oMapCanvas.height = oTexture.image.height;

            const oCtx = oMapCanvas.getContext('2d');
            oCtx.translate(oTexture.image.width / 2, oTexture.image.height / 2);
            oCtx.rotate(Math.PI);
            oCtx.scale(-1, 1);
            oCtx.translate(-oTexture.image.width / 2, -oTexture.image.height / 2);
            oCtx.drawImage(oTexture.image, 0, 0, oTexture.image.width, oTexture.image.height);

            const oTextureRotate = new THREE.Texture(oMapCanvas);
            oTextureRotate.needsUpdate = true;

            resolve(oTextureRotate);
          } else {
            resolve(oTexture);
          }
        },
        () => null,
        oError => {
          console.log('Error while loading texture', oError);
          resolve(null);
        }
      )),
      new Promise(resolve => new THREE.TextureLoader().load(
        sImageUrl,
        oTexture => {
          oTexture.wrapS = THREE.RepeatWrapping;

          if (bUsdzHack) {
            oTexture.encoding = THREE.sRGBEncoding;
            oTexture.flipY = false;
          } else {
            oTexture.repeat.x = -1;
          }

          resolve(oTexture);
        },
        () => null,
        oError => {
          console.log('Error while loading texture', oError);
          resolve(null);
        }
      ))
    ]);

    if (!oTexture || !oTexture2) return;

    const bTop = oModelViewer2.image.placement === 'floor';

    const oTextureFront = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 1,
      map: oTexture
    });

    const oTextureBack = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 1,
      map: oTexture2
    });
    oTextureBack.color.setHSL(0, 0, 0.1);

    const oTextureTransparent = new THREE.MeshStandardMaterial({
      transparent: true,
      color: 0x000000,
      opacity: 0
    });

    const aMaterials = [null, null, null, null].map(() => oTextureTransparent); // Right, left, top, bottom, front, back
    aMaterials.splice((bTop ? 2 : 4), 0, ...[oTextureFront, oTextureBack]); // Insert textures after 2nd or 4th position

    oModelViewer2.image.cube = new SceneUtils.createMeshesFromMultiMaterialMesh(new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), aMaterials));
    oModelViewer2.scene.add(oModelViewer2.image.cube);

    // Render
    const oSize = oModelViewer2.container.getBoundingClientRect();

    oModelViewer2.image.cube.scale.x = (oModelViewer2.image.width >= oModelViewer2.image.height ? oSize.width : (oSize.height * oModelViewer2.image.aspect)) * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
    oModelViewer2.image.cube.scale[bTop ? 'z' : 'y'] = (oModelViewer2.image.width >= oModelViewer2.image.height ? (oSize.width * Math.pow(oModelViewer2.image.aspect, -1)) : oSize.height) * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
    oModelViewer2.image.cube.scale[bTop ? 'y' : 'z'] = oModelViewer2.image.depth * oModelViewer2.multiplier * oModelViewer2.pxToMeter;

    oModelViewer2.image.cubeScaleCopy = {
      x: oModelViewer2.image.cube.scale.x,
      y: oModelViewer2.image.cube.scale.y,
      z: oModelViewer2.image.cube.scale.z
    };

    oModelViewer2.image.cube.position[bTop ? 'y' : 'z'] = oModelViewer2.image.depth * oModelViewer2.multiplier * oModelViewer2.pxToMeter * -1;
  },

  /**
   * @returns {void}
   */
  animate: () => {
    if (oModelViewer2.raf !== null) cancelAnimationFrame(oModelViewer2.raf);
    oModelViewer2.animateRaf();
  },

  /**
   * @returns {void}
   */
  animateRaf: () => {
    oModelViewer2.raf = requestAnimationFrame(oModelViewer2.animateRaf);

    if (oModelViewer2.stopAnimation || !oModelViewer2.image?.cube) return;

    const fnDegToRad = iDeg => iDeg * Math.PI / 180;

    if (!oModelViewer2.animationDirection) oModelViewer2.animationDirection = 1;
    if (Math.abs(oModelViewer2.image?.cube?.rotation?.y || 0) >= fnDegToRad(20)) oModelViewer2.animationDirection *= -1;

    // Resize dependent on screen orientation
    const oSize = oModelViewer2.container.getBoundingClientRect();
    const bTop = oModelViewer2.placement === 'floor';
    const iMultiplier = oModelViewer2.multiplier * oModelViewer2.pxToMeter;

    oModelViewer2.image.cube.scale.x = (oModelViewer2.image.width >= oModelViewer2.image.height ? oSize.width : (oSize.height * oModelViewer2.image.aspect)) * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
    oModelViewer2.image.cube.scale[bTop ? 'z' : 'y'] = (oModelViewer2.image.width >= oModelViewer2.image.height ? (oSize.width * Math.pow(oModelViewer2.image.aspect, -1)) : oSize.height) * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
    oModelViewer2.image.cube.scale[bTop ? 'y' : 'z'] = oModelViewer2.image.depth * oModelViewer2.multiplier * oModelViewer2.pxToMeter;

    oModelViewer2.image.cube.position[bTop ? 'y' : 'z'] = oModelViewer2.image.depth * iMultiplier * -1;

    // Rotation
    const [iX, iY, iZ] = [
      bTop ? fnDegToRad(60) : 0,
      (oModelViewer2.image.cube?.rotation?.y || 0) + fnDegToRad(0.25) * oModelViewer2.animationDirection,
      0
    ];
    oModelViewer2.image.cube.rotation.x = iX;
    oModelViewer2.image.cube.rotation.y = iY;
    oModelViewer2.image.cube.rotation.z = iZ;
    oModelViewer2.image.lastRotation.x = iX;
    oModelViewer2.image.lastRotation.y = iY;
    oModelViewer2.image.lastRotation.z = iZ;

    oModelViewer2.render();
  },

  /**
   * @returns {Promise<{usdz: any;glb?: undefined;} | {glb: any;usdz: any;}}>
   */
  createFiles: async () => {
    // Stop animation
    oModelViewer2.stopAnimation = true;

    // Wait 100ms to be sure the rotation is 0
    await new Promise(resolve => setTimeout(resolve, 100));

    // Reset scale and rotation
    if (oModelViewer2.image?.cube) {
      oModelViewer2.image.cube.scale.x = oModelViewer2.image.width * oModelViewer2.pxToMeter;
      oModelViewer2.image.cube.scale.y = oModelViewer2.image.height * oModelViewer2.pxToMeter;
      oModelViewer2.image.cube.scale.z = oModelViewer2.image.depth * oModelViewer2.pxToMeter;

      oModelViewer2.image.cube.rotation.x = 0;
      oModelViewer2.image.cube.rotation.y = 0;
      oModelViewer2.image.cube.rotation.z = 0;
    }

    oModelViewer2.render();

    const oFiles = oModelViewer2.model ? {
      usdz: null
    } : {
      glb: null,
      usdz: null
    };

    const oOptions = {
      trs: true,
      onlyVisible: true,
      truncateDrawRange: true,
      binary: true,
      maxTextureSize: 4096
    };

    // Convert scene to 3d files
    if (oFiles.glb !== undefined) {
      try {
        await new Promise(resolve => setTimeout(resolve, 20));

        await new Promise(resolve => {
          const fnSuccess = oData => {
            if (oData instanceof ArrayBuffer) {
              const oFile = new Blob([oData], {
                type: 'application/octet-stream'
              });
              oFiles.glb = oFile;
            }
            resolve();
          };

          new GLTFExporter().parse(oModelViewer2.scene, fnSuccess, oOptions);
        });
      } catch (oError) {
        console.log(oError);
      }
    }

    if (oFiles.usdz !== undefined) {
      try {
        const oExporter = await new USDZExporter();
        const oResultUsdz = await oExporter.parse(oModelViewer2.scene);

        oFiles.usdz = await new Blob([oResultUsdz], {
          type: 'application/octet-stream'
        });
      } catch (oError) {
        console.log(oError);
      }
    }

    // Apply original scale and rotation
    if (oModelViewer2.image?.cube) {
      oModelViewer2.image.cube.rotation.x = oModelViewer2.image.lastRotation?.x || 0;
      oModelViewer2.image.cube.rotation.y = oModelViewer2.image.lastRotation?.y || 0;
      oModelViewer2.image.cube.rotation.z = oModelViewer2.image.lastRotation?.z || 0;

      const bTop = oModelViewer2.image.placement === 'floor';
      const oSize = oModelViewer2.container.getBoundingClientRect();
      oModelViewer2.image.cube.scale.x = (oModelViewer2.image.width >= oModelViewer2.image.height ? oSize.width : (oSize.height * oModelViewer2.image.aspect)) * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
      oModelViewer2.image.cube.scale[bTop ? 'z' : 'y'] = (oModelViewer2.image.width >= oModelViewer2.image.height ? (oSize.width * Math.pow(oModelViewer2.image.aspect, -1)) : oSize.height) * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
      oModelViewer2.image.cube.scale[bTop ? 'y' : 'z'] = oModelViewer2.image.depth * oModelViewer2.multiplier * oModelViewer2.pxToMeter;
    }

    oModelViewer2.render();

    oModelViewer2.stopAnimation = false;
    oModelViewer2.animate();

    return oFiles;
  },

  /**
    * @param {Blob} oFile
    * @param {string} sFileName
    * @param {string} sFileType
    * @returns {void}
    */
  downloadFile: (oFile, sFileName, sFileType) => {
    const oLink = document.createElement('a');
    oLink.style.display = 'none';
    document.body.appendChild(oLink);
    oLink.href = URL.createObjectURL(oFile);
    oLink.setAttribute('download', `${sFileName}.${sFileType}`);
    oLink.click();
    oLink.remove();
  }
};
