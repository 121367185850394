<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="!isLoading" class="form-container">
      <div class="box box-large">
        <div class="form-element welcome-text">
          <div v-html="text.login_text"></div>
          <div v-if="showWelcomeText" v-html="text.login_welcome"></div>
         </div>

        <form @submit.prevent="submit" class="form-element">
          <div class="form-row">
            <label class="form-label" for="email">{{ text.login_email }}:</label>
            <input class="form-field" type="email" id="email" name="email" v-model="form.email" />
          </div>
          <div class="form-row">
            <label class="form-label" for="password">{{ text.login_password }}:</label>
            <input class="form-field" type="password" id="password" name="password" v-model="form.password" />
          </div>
          <div v-if="termsRevalidationRequired" class="form-row form-row-checkbox" :class="{ 'annoying-highlight': !form.terms }">
            <input class="form-checkbox" id="form-terms" type="checkbox" name="terms" v-model="form.terms">
            <label class="form-label form-checkbox-label" for="form-terms" v-html="text.register_terms"></label>
          </div>
          <div class="form-double-col-flex">

            <button class="btn" type="submit" :disabled="!form.email || !form.password">{{ text.login_login }}</button>
            <p class="no-margin full-width text-right">
              {{ text.login_no_account_yet }}
              <router-link :to="`/${this.$route.meta.language}/register`">
                {{ text.login_sign_up }}
              </router-link>
            </p>

            <p class="no-margin full-width"><router-link :to="`/${this.$route.meta.language}/request-password`">{{ text.login_password_reset }}</router-link></p>
          </div>
        </form>
      </div>
    </article>

    <article v-if="!isLoading && faq.length > 0" class="form-container">
      <h2 class="form-element box-unstyled">{{ text.login_faq }}</h2>
    </article>

    <Accordeon v-for="item, index in faq" :key="index" :index="index" :title="item.question" :content="item.answer" />

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnRunSimultaneously, fnSetPageInformations } from '@/modules/globalFunctions.js';
import MessageBox from '@/components/MessageBox.vue';
import Accordeon from '@/components/Accordeon.vue';
import Loader from "@/components/Loader.vue";

const oTextTemplate = {
  login_text: '',
  login_welcome: '',
  login_email: '',
  login_password: '',
  login_no_account_yet: '',
  login_login: '',
  login_sign_up: '',
  login_msg_incorrect_credentials: '',
  login_faq: '',
  login_logout_success: '',
  register_terms: '',
  login_password_reset: '',
  login_suspended: '',
  login_registered_incomplete: ''
};

export default {
  name: 'Login',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    MessageBox,
    Accordeon,
    Loader
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_login',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      form: {
        email: '',
        password: '',
        terms: false
      },
      showWelcomeText: false,
      faq: [],
      message: oMessageBoxDataTemplate,
      termsRevalidationRequired: false
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  methods: {
    ...mapActions(['LogIn']),


    /**
     * Initialization function
     */
    async init() {
      const _this = this;

      this.isLoading = true;

      if (this.$route?.query?.registration === 'successful') this.showWelcomeText = true;

      this.faq = [];

      await fnRunSimultaneously([
        async () => await fnSetPageInformations(_this, oTextTemplate),

        // Get FAQs
        async () => {
          const oResponseFaq = await axios.post('https://arxafrica.net/lib/php/data/getFaq.php', {
            language: _this.$route.meta.language,
            role: 'artist'
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          _this.faq = oResponseFaq?.data || [];
        }
      ], `${this.metaData.page} | FAQ`);

      // Show logout message, do not
      if (localStorage.getItem('logout') !== null) {
        this.$refs.messageBox.showMessage('success', this.text.login_logout_success, 2000); // Call child method
        localStorage.removeItem('logout');
      }

      // Show custom message popup
      if (localStorage.getItem('msg') !== null && localStorage.getItem('msgtype') !== null) {
        this.$refs.messageBox.showMessage(localStorage.getItem('msgtype'), localStorage.getItem('msg'), 2000); // Call child method
        localStorage.removeItem('msg');
        localStorage.removeItem('msgtype');
      }

      this.isLoading = false;
    },


    /**
     * Trigger user login
     */
    async submit() {
      const User = new FormData();
      Object.keys(this.form).forEach(sKey => User.append(sKey, this.form[sKey]));

      try {
        const {
          needsRevalidation: bNeedsRevalidation,
          suspended: bIsSuspended,
          registrationFinished: bRegistrationFinished,
          userExists: bUserExists
        } = await this.LogIn(User);

        // Show suspension message and return
        if (bIsSuspended) this.$refs.messageBox.showMessage('error', this.text.login_suspended);
        else if (!bUserExists) this.$refs.messageBox.showMessage('error', this.text.login_msg_incorrect_credentials);
        else if (!bRegistrationFinished) this.$refs.messageBox.showMessage('error', this.text.login_registered_incomplete);

        if (bIsSuspended || !bRegistrationFinished || !bUserExists) return;

        this.termsRevalidationRequired = bNeedsRevalidation;

        // Abort login
        if (bNeedsRevalidation) return;

        this.$router.push(`/${this.$route.meta.language}/`);
      } catch (error) {
        this.$refs.messageBox.showMessage('error', this.text.login_msg_incorrect_credentials);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";

.welcome-text {
  margin-bottom: 2rem;
}
</style>
