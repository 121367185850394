<template>
  <main>
    <header class="menu-spacer"></header>

    <article v-if="!isLoading" id="exhibitions">
      <div class="box dashboard-card new-entry">
        <form v-if="exhibitions" @submit.prevent="submit">
          <label class="form-label" for="name">{{ text.exhibitions_new_exhibition }}</label>
          <input class="form-field" type="text" id="name" name="name" v-model="form.name" :placeholder="text.exhibitions_exhibition_title">
          <button class="btn" type="submit" :disabled="!form.name">{{ text.exhibitions_submit_exhibition }}</button>
        </form>
        <div v-else v-html="text.exhibitions_no_exhibitions_error"></div>
      </div>
    </article>

    <article v-if="!isLoading" id="exhibitions" class="exhibition-list">
      <section class="exhibition-preview js-bggradient" v-for="exhibition, index in exhibitions" :key="exhibition.id" :style="{ animationDelay: `${(index + 1) * animationDuration}s`}">
        <header>
          <h2>{{ exhibition.name }}</h2>
        </header>
        <div class="image">
          <img :src="exhibition.preview_image ? `https://arxafrica.net/assets/${exhibition.preview_image}/thumbnailv` : 'https://arxafrica.net/images/placeholder.png'" :alt="exhibition.name" draggable="false" width="auto" height="auto"/>
        </div>
        <div class="text" v-html="`${exhibition.description || ''}`.split('\n').join('<br/>')"></div>
        <div class="actions">
          <button class="btn small small-padding" @click="edit(exhibition)">{{ text.exhibitions_action_edit }}</button>
          <button class="btn small small-padding">{{ text.exhibitions_action_statistics }}</button>
          <button class="btn small small-padding" @click="open(exhibition)">{{ text.exhibitions_action_artworks }}</button>
          <div class="artwork-counter">
              {{ exhibition.artworks.length || 0 }}
              <Icon name="artwork" class="inline-svg"/>
          </div>
        </div>
        <footer class="bold">
          <p>{{ exhibition.dateString }}</p>
          <p><span>{{ exhibition.city }}</span> <span>{{ exhibition.location }}</span></p>
        </footer>
      </section>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { oMetaDataTemplate, fnRunSimultaneously, fnInitDraggables, fnWait, fnSetPageInformations, fnApplyBgGradient } from '@/modules/globalFunctions.js';
import Loader from "@/components/Loader.vue";
import Icon from "@/components/Icon.vue";
import $ from 'jquery';

const oTextTemplate = {
  exhibitions_new_exhibition: '',
  exhibitions_exhibition_title: '',
  exhibitions_submit_exhibition: '',
  exhibitions_no_exhibitions_error: '',
  exhibitions_action_edit: '',
  exhibitions_action_statistics: '',
  exhibitions_action_artworks: ''
};

export default {
  name: 'Exhibitions',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    Loader,
    Icon
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_exhibitions',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      form: {
        name: ''
      },
      animationDuration: 0.125,
      exhibitions: null
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  mounted() {
    window.addEventListener('resize', this.adjustCardHeight);
    window.addEventListener('orientationchange', this.adjustCardHeight);
  },
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapGetters({ IsExhibitor: 'isExhibitor', Exhibitions: 'StateExhibitions', User: 'StateUser' }),
  },
  methods: {
    ...mapActions(['CreateExhibition', 'GetExhibitions', 'SetExhibition']),


    /**
     * Initialization function
     */
    async init() {
      // Workaround for wrong routing component directly after changing the user
      if (!this.IsExhibitor) {
        location.reload();
        return;
      }

      const _this = this;
      this.isLoading = true;

      await fnSetPageInformations(this, oTextTemplate);

      await fnRunSimultaneously([
        // Get Exhibitions
        _this.GetExhibitions
      ], `${this.metaData.page} | Exhibitions`);

      // Format exhibition attributes
      this.exhibitions = this.Exhibitions.map(oExhibition => {
        /**
         * Returns formatted date in an array [dd, mm, yyyy]
         *
         * @param {Date} oDate Date object
         * @returns {string[]} Formatted date parts
         */
        const fnGetFormattedDate = oDate => {
          try {
            return [
              ('0' + oDate.getDate()).substr(-2),
              ('0' + (oDate.getMonth() + 1)).substr(-2),
              oDate.getFullYear().toString()
            ];
          } catch (oError) {
            return ['', '', ''];
          }
        };

        // Empty start or end date will result in the date value 01.01.1970 !
        const aDateStart = fnGetFormattedDate(new Date(oExhibition.start_date));
        const aDateEnd = fnGetFormattedDate(new Date(oExhibition.end_date));
        const aDates = [];

        // Check for the same year
        if (aDateStart[2] === aDateEnd[2]) aDateStart[2] = ''; // Clear year if start and end are equal
        if (oExhibition.start_date !== null) aDates.push(aDateStart.join('.'));
        if (oExhibition.end_date !== null) aDates.push(aDateEnd.join('.'));

        return {
          ...oExhibition,
          dateString: aDates.join(' - ')
        };
      });


      // Fix card height (respecting aspect ratio)
      this.adjustCardHeight();

      this.isLoading = false;
      await fnWait(1);

      [...$('.js-bggradient')].forEach(oElem => fnApplyBgGradient($(oElem)));

      fnInitDraggables();
      this.adjustCardHeight();
    },


    /**
     * Fix card height while respecting aspect ratio
     */
    adjustCardHeight() {
      // Set card height to fix scroll bar issues
      [...$('.list-group .dashboard-card')].forEach(oElem => {
        const $elem = $(oElem).find('.preview, .vimeo-thumb');
        const sPadding = $elem.css('padding-top');
        if (sPadding) $elem.css('height', sPadding);
      });
    },


    /**
     * Route to exhibition
     *
     * @param {any} oExhibition Exhibition data
     */
    open(oExhibition) {
      this.SetExhibition(oExhibition);
      this.$router.push(`/${this.$route.meta.language}/exhibition/${oExhibition.id}`); // go back
    },


    /**
     * Route to edit exhibition
     */
    edit(oExhibition) {
      this.SetExhibition(oExhibition);
      this.$router.push(`/${this.$route.meta.language}/exhibition/${oExhibition.id}/edit`);
    },


    /**
     * Create a new exhibition
     *
     * @returns {void}
     */
    async submit() {
      try {
        await this.CreateExhibition(this.form);

        if (!this.Exhibitions.length) return;

        const oExhibition = this.Exhibitions[0];
        this.SetExhibition(oExhibition);
        this.$router.push(`/${this.$route.meta.language}/exhibition/${oExhibition.id}`);
      } catch (error) {
        throw "Sorry you can't make an exhibition now!"
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";

$exhibitions-gap: 2rem;
$new-entry-gap: 0.5rem;

.exhibition-preview {
  display: grid;
  row-gap: $gap-size;
  column-gap: $gap-size;
  padding: $gap-size;
  border-radius: $gap-size;
  grid-template-areas:
    "image"
    "name"
    "textpreview"
    "eventinfo"
    "actions";
  grid-template-rows: 20rem max-content max-content max-content max-content;
  width: 100%;
  transition: all 0.25s linear;
  filter: drop-shadow(0 0 0.25rem #000);
  animation: showBox 0.5s ease;
  animation-fill-mode: both;

  @include breakpoint(medium) {
    $image-width: 10.5rem;

    grid-template-areas:
      "image name"
      "image textpreview"
      "image eventinfo"
      "image actions";
    grid-template-columns: $image-width calc(100% - #{$image-width + $gap-size});
    grid-template-rows: max-content max-content 1fr max-content;
  }

  & > header {
    grid-area: name;
    font-style: italic;
    font-size: 2rem;
    color: $color-2;
  }

  & > .image {
    @include flex(center, center, initial);
    @include overflow(hidden, hidden);

    grid-area: image;
    border-radius: 0.5rem;
    min-height: 10rem;
    min-width: Max(10rem, 100%);

    img {
      height: 100%;
      width: auto;
      min-height: 10rem;
      max-height: 20rem;
      max-width: unset;
      border-radius: 1rem;
    }
  }

  & > .text {
    grid-area: textpreview;
    font-weight: 300;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: $gap-size;
  }

  & > .actions {
    grid-area: actions;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    gap: 0.5rem;

    .btn {
      min-width: 5rem;
    }
  }

  & > footer {
    grid-area: eventinfo;
    font-weight: 900;
    font-size: 1.5rem;

    p > span {
      &:nth-of-type(1) {
        color: $color-2;
      }
      &:nth-of-type(2) {
        color: $color-1;
      }
    }
  }
}

.new-entry {
  width: clamp(20rem, calc((100% - 4rem) / 3), 40rem);
  max-width: 100%;
  margin: auto;

  & > form {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    row-gap: $new-entry-gap;
  }
}

.artwork-counter {
  margin-left: auto;
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.25rem;
  }
}

@keyframes showBox {
  0% {
    opacity: 0;
    transform: translate(0, 10rem);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}
</style>
