<template>
  <svg v-if="name === 'ar'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.201 13.468">
    <path fill="currentColor" d="M598.6,1964.662a.125.125,0,0,0-.129-.1h-1.56a.125.125,0,0,0-.129.1l-2.146,6.716a.108.108,0,0,0,.01.1.111.111,0,0,0,.09.035H596a.125.125,0,0,0,.129-.1l.288-.974c.013-.026.03-.04.05-.04h2.444c.02,0,.036.013.049.04l.3.974a.124.124,0,0,0,.129.1h1.262q.109,0,.109-.089a.11.11,0,0,0-.01-.05Zm-.04,4.659h-1.749c-.033,0-.047-.02-.039-.059l.874-2.951c.007-.02.016-.031.03-.035s.023.008.03.035l.894,2.951C598.617,1969.3,598.6,1969.322,598.564,1969.322Z" transform="translate(-590.779 -1961.294)"/>
    <path fill="currentColor" d="M595.422,1950H579.71a2.2,2.2,0,0,0-2.245,2.245v8.978a2.2,2.2,0,0,0,2.245,2.245h15.712a2.2,2.2,0,0,0,2.245-2.245v-8.978A2.2,2.2,0,0,0,595.422,1950Zm1.122,11.213a1.692,1.692,0,0,1-.184.672.915.915,0,0,1-.938.46h-15.7a1.688,1.688,0,0,1-.672-.184.914.914,0,0,1-.46-.938v-8.969a1.686,1.686,0,0,1,.184-.672.915.915,0,0,1,.938-.46h15.7a1.692,1.692,0,0,1,.672.184.915.915,0,0,1,.46.938Z" transform="translate(-577.465 -1950)"/>
    <path fill="currentColor" d="M629.6,1968.566a.047.047,0,0,1,.03-.069,1.8,1.8,0,0,0,.944-.681,1.965,1.965,0,0,0,.338-1.157,2.246,2.246,0,0,0-.263-1.1,1.815,1.815,0,0,0-.745-.735,2.332,2.332,0,0,0-1.118-.258h-2.841a.119.119,0,0,0-.119.119v6.716a.119.119,0,0,0,.119.119h1.162a.118.118,0,0,0,.119-.119v-2.712a.044.044,0,0,1,.05-.05h.924a.064.064,0,0,1,.06.039l1.242,2.752a.14.14,0,0,0,.139.089h1.222c.073,0,.109-.026.109-.079a.153.153,0,0,0-.02-.06Zm-.343-1.252a.951.951,0,0,1-.681.248h-1.3a.044.044,0,0,1-.05-.05v-1.7a.044.044,0,0,1,.05-.05h1.3a.942.942,0,0,1,.681.253.865.865,0,0,1,.263.651A.848.848,0,0,1,629.259,1967.315Z" transform="translate(-614.972 -1961.294)"/>
  </svg>
  <svg v-else-if="name === 'artwork'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.722 70">
    <path fill="currentColor" d="M74.873,0H12.848C3.421,0,0,7.684,0,12.848v44.3C0,66.579,7.684,70,12.848,70H74.873c9.427,0,12.849-7.684,12.849-12.848v-44.3C87.722,3.421,80.038,0,74.873,0Zm4.874,57.11c-.041,1.836-.729,4.915-4.874,4.915h-62c-1.837-.044-4.9-.738-4.9-4.873V12.89c.04-1.836.728-4.915,4.873-4.915H74.832c1.835.04,4.915.728,4.915,4.873Z"/>
    <path fill="currentColor" d="M31.975,27.378a3.145,3.145,0,0,0-4.449,0L15.335,39.569a3.145,3.145,0,0,0-.921,2.224V52.682a3.145,3.145,0,0,0,3.145,3.145H52.83a3.146,3.146,0,0,0,2.225-5.37Z"/>
    <path fill="currentColor" d="M60.39,17.485a7.669,7.669,0,1,0,7.668,7.668A7.67,7.67,0,0,0,60.39,17.485Z"/>
  </svg>
  <svg v-else-if="name === 'close'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.299 12.3">
    <path fill="currentColor" d="M35.943-66.026l-3.857-3.855,3.854-3.857a1.128,1.128,0,0,0,0-1.6l-.358-.359a1.113,1.113,0,0,0-.8-.333h0a1.122,1.122,0,0,0-.8.332L30.125-71.84l-3.857-3.854a1.127,1.127,0,0,0-1.6,0l-.359.355a1.12,1.12,0,0,0-.334.8,1.128,1.128,0,0,0,.332.8l3.859,3.856-3.855,3.857a1.129,1.129,0,0,0,0,1.6l.358.358a1.118,1.118,0,0,0,.8.333,1.133,1.133,0,0,0,.8-.332l3.855-3.858,3.856,3.853a1.125,1.125,0,0,0,.8.332,1.129,1.129,0,0,0,.8-.33l.358-.357a1.11,1.11,0,0,0,.334-.8,1.12,1.12,0,0,0-.33-.8Z" transform="translate(-23.977 76.031)"/>
  </svg>
  <svg v-else-if="name === 'delete'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.495 14.426">
    <path fill="currentColor" d="M617.278,1853.983v.892a.39.39,0,0,1-.39.39H606.173a.389.389,0,0,1-.389-.39v-.892a.389.389,0,0,1,.389-.389h3.533v-.2a.389.389,0,0,1,.389-.389h3.144a.39.39,0,0,1,.39.389v.2h3.261a.389.389,0,0,1,.389.39Zm-2.429,11.179a.606.606,0,0,1-1.212,0v-6.826a.606.606,0,0,1,1.212,0Zm-2.694,0a.607.607,0,0,1-1.213,0v-6.826a.607.607,0,0,1,1.213,0Zm-2.694,0a.607.607,0,0,1-1.213,0v-6.826a.607.607,0,0,1,1.213,0Zm7.108-9.305H606.493a.692.692,0,0,0-.7.8l1.314,9.966a.951.951,0,0,0,.914.8h7.026a.95.95,0,0,0,.914-.8l1.313-9.966a.691.691,0,0,0-.7-.8Z" transform="translate(-605.783 -1853)"/>
  </svg>
  <svg v-else-if="name === 'handle'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(0 0)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(0 8.147)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(0 16.296)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(8.147 0)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(8.147 8.147)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(8.147 16.296)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(16.296 0)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(16.296 8.147)"/>
    <circle fill="currentColor" cx="1.852" cy="1.852" r="1.852" transform="translate(16.296 16.296)"/>
  </svg>
  <svg v-else-if="name === 'heart'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.242 13.022">
    <path fill="currentColor" d="M18.821-82A3.871,3.871,0,0,0,15-78.23c0,3.673,4.174,7.082,7.121,9.252,2.947-2.171,7.121-5.579,7.121-9.252A3.871,3.871,0,0,0,25.421-82c-1.323,0-2.453,1.055-3.3,2.056C21.273-80.945,20.144-82,18.821-82Z" transform="translate(-15 82)" />
  </svg>
  <svg v-else-if="name === 'help'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/>
  </svg>
  <svg v-else-if="name === 'location' && type" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g v-if="type == 'indoor'">
      <g style="isolation:isolate">
        <path d="M13.356,42h1.4a.143.143,0,0,1,.143.144v8.113a.133.133,0,0,1-.042.1.135.135,0,0,1-.1.042h-1.4a.135.135,0,0,1-.1-.042.137.137,0,0,1-.042-.1V44.965c0-.033-.009-.049-.025-.049s-.032.012-.047.036l-1.273,1.992a.186.186,0,0,1-.168.1h-.708a.187.187,0,0,1-.168-.1L9.553,44.952c-.017-.024-.032-.034-.048-.029s-.025.021-.025.053v5.281a.133.133,0,0,1-.042.1.137.137,0,0,1-.1.042h-1.4a.143.143,0,0,1-.144-.143V42.144a.139.139,0,0,1,.042-.1.137.137,0,0,1,.1-.042h1.4a.189.189,0,0,1,.168.1l1.8,2.8q.036.072.072,0l1.811-2.8A.189.189,0,0,1,13.356,42Z" style="fill:#269ad6"/>
        <path d="M21.553,50.28,21.192,49.1c-.015-.032-.036-.048-.059-.048H18.181c-.025,0-.044.016-.06.048l-.349,1.176a.15.15,0,0,1-.155.12H16.093a.138.138,0,0,1-.109-.042.134.134,0,0,1-.011-.126l2.591-8.112a.152.152,0,0,1,.157-.12H20.6a.152.152,0,0,1,.157.12l2.592,8.112a.15.15,0,0,1,.011.06q0,.108-.132.108H21.709A.151.151,0,0,1,21.553,50.28ZM18.6,47.748h2.112c.048,0,.063-.024.048-.072l-1.08-3.564q-.012-.048-.036-.042a.059.059,0,0,0-.037.042l-1.055,3.564Q18.541,47.748,18.6,47.748Z" style="fill:#269ad6"/>
        <path d="M28.86,50.292l-1.5-3.324a.076.076,0,0,0-.072-.048H26.173a.054.054,0,0,0-.061.06v3.277a.133.133,0,0,1-.042.1.137.137,0,0,1-.1.042H24.564a.135.135,0,0,1-.1-.042.137.137,0,0,1-.042-.1V42.144a.139.139,0,0,1,.042-.1.135.135,0,0,1,.1-.042H28a2.818,2.818,0,0,1,1.351.312,2.191,2.191,0,0,1,.9.888,2.711,2.711,0,0,1,.318,1.332,2.366,2.366,0,0,1-.408,1.4,2.165,2.165,0,0,1-1.139.822.058.058,0,0,0-.037.084l1.632,3.4a.19.19,0,0,1,.025.072q0,.1-.132.1H29.028A.171.171,0,0,1,28.86,50.292Zm-2.748-6.78v2.052a.054.054,0,0,0,.061.06h1.571a1.149,1.149,0,0,0,.822-.3,1.023,1.023,0,0,0,.319-.78,1.04,1.04,0,0,0-.319-.785,1.133,1.133,0,0,0-.822-.307H26.173A.054.054,0,0,0,26.112,43.512Z" style="fill:#269ad6"/>
        <path d="M31.831,50.358a.137.137,0,0,1-.042-.1V42.144a.139.139,0,0,1,.042-.1.137.137,0,0,1,.1-.042h1.4a.137.137,0,0,1,.1.042.135.135,0,0,1,.042.1V45.5c0,.024.008.038.025.043a.049.049,0,0,0,.048-.019l2.771-3.444A.231.231,0,0,1,36.505,42h1.584q.095,0,.12.054a.128.128,0,0,1-.024.126L35.509,45.6c-.025.033-.029.06-.012.084l2.832,4.548a.5.5,0,0,1,.024.084c0,.056-.041.084-.121.084H36.648a.173.173,0,0,1-.168-.1l-2.135-3.456a.039.039,0,0,0-.036-.024.067.067,0,0,0-.048.024l-.756.949a.166.166,0,0,0-.025.071v2.389a.133.133,0,0,1-.042.1.137.137,0,0,1-.1.042h-1.4A.137.137,0,0,1,31.831,50.358Z" style="fill:#269ad6"/>
        <path d="M45.091,43.41a.143.143,0,0,1-.1.042H41.053a.054.054,0,0,0-.061.06v1.86a.054.054,0,0,0,.061.06h2.58a.138.138,0,0,1,.1.042.137.137,0,0,1,.042.1V46.74a.139.139,0,0,1-.042.1.141.141,0,0,1-.1.041h-2.58a.054.054,0,0,0-.061.06v1.944a.054.054,0,0,0,.061.06h3.935a.147.147,0,0,1,.1.042.143.143,0,0,1,.042.1v1.164a.141.141,0,0,1-.042.1.143.143,0,0,1-.1.042H39.444a.135.135,0,0,1-.1-.042.133.133,0,0,1-.042-.1V42.144A.143.143,0,0,1,39.444,42h5.544a.143.143,0,0,1,.145.144v1.164A.141.141,0,0,1,45.091,43.41Z" style="fill:#269ad6"/>
        <path d="M50.748,50.292l-1.5-3.324a.077.077,0,0,0-.072-.048H48.061a.054.054,0,0,0-.061.06v3.277a.137.137,0,0,1-.042.1.139.139,0,0,1-.1.042h-1.4a.135.135,0,0,1-.1-.042.133.133,0,0,1-.042-.1V42.144A.143.143,0,0,1,46.452,42h3.432a2.814,2.814,0,0,1,1.35.312,2.188,2.188,0,0,1,.9.888,2.711,2.711,0,0,1,.318,1.332,2.373,2.373,0,0,1-.408,1.4,2.167,2.167,0,0,1-1.14.822.057.057,0,0,0-.036.084l1.632,3.4a.184.184,0,0,1,.024.072c0,.064-.044.1-.131.1H50.916A.17.17,0,0,1,50.748,50.292ZM48,43.512v2.052a.054.054,0,0,0,.061.06h1.571a1.149,1.149,0,0,0,.822-.3,1.022,1.022,0,0,0,.318-.78,1.039,1.039,0,0,0-.318-.785,1.133,1.133,0,0,0-.822-.307H48.061A.054.054,0,0,0,48,43.512Z" style="fill:#269ad6"/>
      </g>
      <path d="M21.429,13.714h3.714v3.715H21.429ZM20.5,21.143h5.571a2.787,2.787,0,0,0,2.786-2.786V12.786A2.787,2.787,0,0,0,26.071,10H20.5a2.787,2.787,0,0,0-2.786,2.786v5.571A2.787,2.787,0,0,0,20.5,21.143Z" style="fill:#269ad6"/>
      <path d="M21.429,28.571h3.714v3.715H21.429Zm-3.715,4.643A2.787,2.787,0,0,0,20.5,36h5.571a2.787,2.787,0,0,0,2.786-2.786V27.643a2.787,2.787,0,0,0-2.786-2.786H20.5a2.787,2.787,0,0,0-2.786,2.786Z" style="fill:#269ad6"/>
      <path d="M36.286,13.714H40v3.715H36.286Zm4.643,7.429a2.786,2.786,0,0,0,2.785-2.786V12.786A2.786,2.786,0,0,0,40.929,10H35.357a2.787,2.787,0,0,0-2.786,2.786v5.571a2.787,2.787,0,0,0,2.786,2.786Z" style="fill:#269ad6"/>
      <rect x="36.286" y="28.571" width="3.714" height="3.714" style="fill:#269ad6"/>
      <rect x="40" y="24.857" width="3.714" height="3.714" style="fill:#269ad6"/>
      <rect x="32.571" y="24.857" width="3.714" height="3.714" style="fill:#269ad6"/>
      <rect x="40" y="32.286" width="3.714" height="3.714" style="fill:#269ad6"/>
      <rect x="32.571" y="32.286" width="3.714" height="3.714" style="fill:#269ad6"/>
    </g>
    <g v-else-if="type == 'outdoor'" transform="translate(-1 -2.146)">
      <rect width="60" height="60" style="fill:none"/>
      <g style="isolation:isolate">
        <path d="M21.228,50.124a2.689,2.689,0,0,1-1.1-1.038,2.992,2.992,0,0,1-.39-1.542V44.856a3.03,3.03,0,0,1,.39-1.548,2.645,2.645,0,0,1,1.1-1.038,3.81,3.81,0,0,1,3.246-.006,2.73,2.73,0,0,1,1.091.978,2.523,2.523,0,0,1,.391,1.374V44.7a.143.143,0,0,1-.042.1.139.139,0,0,1-.1.042h-1.4a.137.137,0,0,1-.1-.042.135.135,0,0,1-.042-.1v-.036a1.256,1.256,0,0,0-.384-.93,1.392,1.392,0,0,0-1.021-.378,1.376,1.376,0,0,0-1.037.4,1.44,1.44,0,0,0-.39,1.049v2.785a1.383,1.383,0,0,0,.42,1.049,1.48,1.48,0,0,0,1.068.4,1.45,1.45,0,0,0,1-.329,1.166,1.166,0,0,0,.372-.918v-.54a.054.054,0,0,0-.06-.061H22.884a.139.139,0,0,1-.1-.042.137.137,0,0,1-.042-.1v-1.1a.14.14,0,0,1,.042-.1.139.139,0,0,1,.1-.042h2.928a.139.139,0,0,1,.1.042.145.145,0,0,1,.042.1v1.584a3.139,3.139,0,0,1-.384,1.584,2.541,2.541,0,0,1-1.087,1.026,3.546,3.546,0,0,1-1.626.354A3.456,3.456,0,0,1,21.228,50.124Z" style="fill:#269ad6"/>
        <path d="M32.111,42.312a2.286,2.286,0,0,1,.931.918,2.758,2.758,0,0,1,.329,1.363,2.555,2.555,0,0,1-.342,1.332,2.328,2.328,0,0,1-.96.893,3.062,3.062,0,0,1-1.422.318h-1.7a.052.052,0,0,0-.059.06v3.061a.141.141,0,0,1-.042.1.143.143,0,0,1-.1.042h-1.4a.141.141,0,0,1-.1-.042.137.137,0,0,1-.042-.1V42.133a.141.141,0,0,1,.042-.1.145.145,0,0,1,.1-.042H30.72A2.883,2.883,0,0,1,32.111,42.312Zm-.756,3.15a1.094,1.094,0,0,0,.336-.834,1.13,1.13,0,0,0-.336-.852,1.223,1.223,0,0,0-.887-.324H28.943a.052.052,0,0,0-.059.06V45.72a.052.052,0,0,0,.059.06h1.525A1.232,1.232,0,0,0,31.355,45.462Z" style="fill:#269ad6"/>
        <path d="M35.615,50.19a2.48,2.48,0,0,1-1.109-.876,2.284,2.284,0,0,1-.391-1.326v-.264a.143.143,0,0,1,.145-.144h1.368a.137.137,0,0,1,.1.042.135.135,0,0,1,.042.1v.18a.985.985,0,0,0,.445.811,1.973,1.973,0,0,0,1.2.329,1.416,1.416,0,0,0,.947-.27.848.848,0,0,0,.313-.666.672.672,0,0,0-.193-.486,1.708,1.708,0,0,0-.521-.348c-.221-.1-.57-.238-1.051-.414A10.467,10.467,0,0,1,35.55,46.3a2.742,2.742,0,0,1-.937-.787,1.975,1.975,0,0,1-.378-1.23,2.171,2.171,0,0,1,.372-1.26,2.366,2.366,0,0,1,1.033-.828,3.777,3.777,0,0,1,1.524-.288,3.9,3.9,0,0,1,1.626.324,2.641,2.641,0,0,1,1.115.906,2.31,2.31,0,0,1,.4,1.35v.18a.143.143,0,0,1-.144.144H38.783a.133.133,0,0,1-.1-.042.135.135,0,0,1-.042-.1v-.1a1.09,1.09,0,0,0-.414-.858,1.692,1.692,0,0,0-1.134-.354,1.435,1.435,0,0,0-.882.24.781.781,0,0,0-.318.661.719.719,0,0,0,.185.5,1.639,1.639,0,0,0,.553.366c.243.108.622.255,1.134.438a14.352,14.352,0,0,1,1.338.552,2.666,2.666,0,0,1,.876.714,1.878,1.878,0,0,1,.389,1.23,2.151,2.151,0,0,1-.827,1.783A3.534,3.534,0,0,1,37.3,50.5,4.274,4.274,0,0,1,35.615,50.19Z" style="fill:#269ad6"/>
      </g>
      <path d="M33.269,30.911h0c-.359.51-.719.99-1.049,1.469a3.238,3.238,0,0,1-2.58,1.319,3.159,3.159,0,0,1-2.58-1.319c-.331-.48-.69-.959-1.05-1.469-2.459.54-4.109,1.619-4.109,2.849,0,1.8,3.449,3.24,7.739,3.24,4.259,0,7.738-1.44,7.738-3.24C37.377,32.531,35.7,31.451,33.269,30.911Z" style="fill:#269ad6"/>
      <path d="M30.9,31.451h0c2.4-3.3,7.379-10.678,7.379-14.307a8.639,8.639,0,0,0-17.277,0c0,3.63,5.009,10.979,7.379,14.307A1.574,1.574,0,0,0,30.9,31.451ZM25.889,17.144a3.75,3.75,0,1,1,3.75,3.749A3.752,3.752,0,0,1,25.889,17.144Z" style="fill:#269ad6"/>
    </g>
    <g v-else>
      <path class="location-none" fill="currentColor" d="M29.5,14A12.5,12.5,0,1,0,42,26.5h0A12.5,12.5,0,0,0,29.5,14Zm-1.023,4.9a.261.261,0,0,1,.192-.079H30.75a.27.27,0,0,1,.271.269v0l-.249,9.952a.27.27,0,0,1-.269.271H28.917a.27.27,0,0,1-.271-.269v0L28.4,19.092A.262.262,0,0,1,28.477,18.9Zm2.25,15.255a1.562,1.562,0,0,1-1.153.452,1.527,1.527,0,0,1-1.142-.452A1.584,1.584,0,0,1,27.991,33a1.512,1.512,0,0,1,1.438-1.583c.048,0,.1,0,.145,0a1.584,1.584,0,0,1,1.153.441A1.528,1.528,0,0,1,31.18,33a1.567,1.567,0,0,1-.453,1.153Z"/>
    </g>
  </svg>
  <svg v-else-if="name === 'missingmodel'" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.54,11L13,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L16.54,11z M11,7H2v2h9V7z M21,13.41L19.59,12L17,14.59 L14.41,12L13,13.41L15.59,16L13,18.59L14.41,20L17,17.41L19.59,20L21,18.59L18.41,16L21,13.41z M11,15H2v2h9V15z"/>
  </svg>
  <svg v-else-if="name === 'openexternal'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
    <path fill="currentColor" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
  </svg>
  <svg v-else-if="name === 'private'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.999 21.5">
    <g transform="translate(-717.065 -2240.102)">
      <path fill="currentColor" d="M740.734,2240.394a1,1,0,0,0-1.414,0l-3.164,3.163c-6.55-1.74-13.524.733-18.9,7.455a.865.865,0,0,0,0,1.087,24.1,24.1,0,0,0,5.36,5l-2.8,2.8a1,1,0,1,0,1.414,1.414l19.5-19.5A1,1,0,0,0,740.734,2240.394Zm-15.08,11.166a6.41,6.41,0,0,1,6.409-6.409,6.338,6.338,0,0,1,2.13.37l-8.171,8.17A6.343,6.343,0,0,1,725.654,2251.56Z" />
      <path fill="currentColor" d="M733.67,2252.3a2.411,2.411,0,0,0,2.41-2.412,2.353,2.353,0,0,0-.091-.62l-2.922,2.946A2.39,2.39,0,0,0,733.67,2252.3Z" />
      <path fill="currentColor" d="M746.873,2251.012a22.776,22.776,0,0,0-6.784-5.88l-2.759,2.781a6.406,6.406,0,0,1-8.872,8.944l-2.153,2.171a16.01,16.01,0,0,0,5.758,1.088c5.635,0,10.8-3.013,14.806-8.018l0,0A.861.861,0,0,0,746.873,2251.012Z" />
    </g>
  </svg>
  <svg v-else-if="name === 'snapshot'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14.12 4l1.83 2H20v12H4V6h4.05l1.83-2h4.24M15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2zm-3 7c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"/>
  </svg>
  <svg v-else-if="name === 'status'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.42 31.421">
    <path fill="#009ee3" d="M869.226,1666.564a15.753,15.753,0,1,0,12.544,4.55,15.709,15.709,0,0,0-12.544-4.55Zm9.888,12.118L869.8,1687.9a1.5,1.5,0,0,1-2.087,0l-5.488-5.242a1.5,1.5,0,0,1-.044-2.118h0a1.5,1.5,0,0,1,2.119-.044h0l4.428,4.249,8.295-8.189a1.493,1.493,0,0,1,2.1,2.123Z" transform="translate(-854.965 -1666.499)"/>
  </svg>
  <svg v-else-if="name === 'trash'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.281 20.431">
    <path fill="currentColor" d="M622.064,1854.392v1.263a.552.552,0,0,1-.552.552H606.335a.552.552,0,0,1-.551-.552v-1.263a.551.551,0,0,1,.551-.551h5v-.288a.551.551,0,0,1,.551-.551h4.452a.552.552,0,0,1,.552.551v.288h4.619a.552.552,0,0,1,.551.552Zm-3.44,15.833a.859.859,0,0,1-1.717,0v-9.667a.859.859,0,0,1,1.717,0Zm-3.816,0a.859.859,0,0,1-1.718,0v-9.667a.859.859,0,0,1,1.718,0Zm-3.816,0a.859.859,0,0,1-1.718,0v-9.667a.859.859,0,0,1,1.718,0Zm10.067-13.179H606.789a.98.98,0,0,0-1,1.135l1.862,14.115a1.346,1.346,0,0,0,1.294,1.135h9.95a1.345,1.345,0,0,0,1.294-1.135l1.86-14.115a.979.979,0,0,0-.995-1.135Z" transform="translate(-605.783 -1853)"/>
  </svg>
  <svg v-else-if="name === 'arrowleft'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/>
  </svg>
  <svg v-else-if="name === 'arrowright'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/>
  </svg>
</template>

<script>
// List of all icon names. Needs to be complete due to property check
const aIcons = [
  'ar',
  'artwork',
  'close',
  'delete',
  'handle',
  'heart',
  'help',
  'location',
  'missingmodel',
  'openexternal',
  'private',
  'snapshot',
  'status',
  'trash',
  'arrowleft',
  'arrowright'
];

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      validator: sValue => aIcons.indexOf(sValue) !== -1
    },
    type: {
      type: String, // 'none' | 'indoor' | 'outdoor'
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.location-none {
  color: $color-3;
}

.location-indoor {
  color: $color-1;
}

.location-outdoor {
  color: $color-6;
}
</style>
