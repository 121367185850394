<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="!isLoading" class="form-container">
      <div class="form-element box box-large">
        <form @submit.prevent="submit">
          <div class="form-row">
            <label class="form-label" for="email">{{ text.request_pw_email }}:</label>
            <input class="form-field" type="email" id="email" name="email" v-model="form.email" />
          </div>
          <div class="form-double-col-flex">
            <button class="btn" :disabled="!form.email" type="submit">{{ text.request_pw_reset }}</button>
          </div>
        </form>
      </div>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnSetPageInformations } from '@/modules/globalFunctions.js';
import MessageBox from '@/components/MessageBox.vue';
import Loader from "@/components/Loader.vue";

const oTextTemplate = {
  request_pw_email: '',
  request_pw_reset: '',
  request_pw_success: '',
  request_pw_error: ''
};

export default {
  name: 'RequestPassword',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    MessageBox,
    Loader
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_request_pw',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      form: {
        email: '',
        reset_url: process.env.VUE_APP_RESET_PASSWORD_URL
      },
      message: oMessageBoxDataTemplate,
      registrationSuccessful: false
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  methods: {
    /**
     * Initialization function
     */
    async init() {
      this.isLoading = true;

      await fnSetPageInformations(this, oTextTemplate);

      this.isLoading = false;
    },


    /**
     * Request reset password mail
     */
    async submit() {
      const oResponse = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        url: `${process.env.VUE_APP_ARTISTS_API}/users/check_existance`,
        data: this.form
      });

      if (!oResponse?.data) {
        this.$refs.messageBox.showMessage('success', this.text.request_pw_success);
        return;
      }

      try {
        const oResponse = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          url: `${process.env.VUE_APP_API_URL}/auth/password/request`,
          data: this.form
        });

        if ([200, 204].indexOf(oResponse?.status) !== -1) {
          if (oResponse.data == '') {
            this.$refs.messageBox.showMessage('success', this.text.request_pw_success);
            this.form.email = '';
          }
          if (oResponse.data?.errors?.shift()?.extensions?.code == 'RECORD_NOT_UNIQUE') this.$refs.messageBox.showMessage('error', this.text.request_pw_error);
        }
      } catch (error) {
        this.$refs.messageBox.showMessage('error', error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";
</style>
