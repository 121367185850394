<template>
  <div id="body">
    <Header :texts="text" />
    <router-view />
    <Footer :texts="text" />
    <BugReport :texts="text" />
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import { fnGetTexts, fnRunSimultaneously, fnCloseMenu, fnGetStoredTexts, fnUpdateStoredTexts, fnSetCssVariables, fnCheckFlexGapFix } from '@/modules/globalFunctions.js';
import $ from 'jquery';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BugReport from "@/components/BugReport.vue";

const oTextTemplate = {
  about: '',
  artist_registration_1: '',
  artist_registration_2: '',
  artists: '',
  artists_dashboard: '',
  artists_logout: '',
  artists_profile: '',
  artists_register: '',
  arx_logo_alt_text: '',
  arxafrica_project_site: '',
  dataprotection: '',
  enter_exhibition_1: '',
  enter_exhibition_2: '',
  exhibitions: '',
  faq: '',
  impressum: '',
  login: '',
  press: '',
  start: '',
  supported_by: '',
  terms_and_conditions: '',
  bug_help: 'Help',
  bug_email: 'Email',
  bug_name: 'Name',
  bug_subject: 'Subject',
  bug_description: 'Describe your problem',
  bug_submit: 'Submit',
  bug_report_form: 'Report a problem',
  bug_report_mail: 'Or send an e-mail to',
  bug_success: `<p>Thank you for your help!</p><p>We will take care of your request as soon as possible.</p>`
};

export default {
  components: {
    Header,
    Footer,
    BugReport
  },
  // Global meta informations, specified informations in each view
  metaInfo: {
    titleTemplate: '%s',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'UTF-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'theme-color', content: '#e94d18' }
    ],
    link: [
      { rel: 'icon', type: 'image/svg+xml', href: 'https://arxafrica.net/images/favicon.svg', sizes: 'any' }
    ]
  },
  data() {
    return {
      text: {
        ...oTextTemplate
      },
      lastTokenRefreshStart: 0
    };
  },
  async created() {
    // Add fix-flex-gap class to html element (e.g. if browser is safari and version is less than 14.1)
    if (fnCheckFlexGapFix()) $('html').addClass('fix-flex-gap');

    await this.setPageInformations();

    this.triggerRefresh();
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.setPageInformations();
      fnCloseMenu();
      window.scrollTo(0, 0);
      this.triggerRefresh();
    }
  },
  computed: {
    ...mapGetters({ IsAuthenticated: 'isAuthenticated', Token: 'StateRefreshToken', Expires: 'StateExpiresAt' }),
  },
  methods: {
    ...mapActions(['RefreshToken']),


    /**
     * Refresh login token
     */
    triggerRefresh() {
      const iTimeStart = new Date().getTime();
      const _this = this;

      // Check if user is logged in
      if (this.IsAuthenticated) {
        this.lastTokenRefreshStart = iTimeStart;
        setTimeout(() => {
          const bIsLastInstance = _this.lastTokenRefreshStart === iTimeStart;

          // Check if user is still logged in
          if (_this.IsAuthenticated && bIsLastInstance) {
            // Refresh token
            _this.RefreshToken();
            _this.triggerRefresh();
          }
        }, _this.Expires - new Date().getTime() - 5000 /* Set timeout to expiry - 5s */);
      }
    },


    /**
     * Set stored texts | Fetch and update stored texts
     */
    async setPageInformations() {
      const _this = this;

      // Set stored texts from localStorage
      this.text = {
        ...this.text,
        ...fnGetStoredTexts(oTextTemplate, this.$route.meta.language)
      };

      // Get maintained and translated texts and metadata informations from Directus
      await fnRunSimultaneously([
        async () => {
          const oTexts = await fnGetTexts(_this.$route.meta.language, 'other_texts', Object.keys(oTextTemplate));

          fnUpdateStoredTexts(oTexts, _this.$route.meta.language);

          _this.text = {
            ..._this.text,
            ...oTexts || {}
          };
        }
      ], 'App | other_texts');
    }
  }
}


// Set CSS variables
window.addEventListener('resize', fnSetCssVariables);
window.addEventListener('orientationchange', fnSetCssVariables);
fnSetCssVariables();


// Vanilla image lazy load
document.addEventListener('DOMContentLoaded', () => {
  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    h._hjSettings = { hjid: 2705449, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

  let aImages = [];
  if ('IntersectionObserver' in window) {
    aImages = [].slice.call(document.querySelectorAll('img.lazy'));

    // Create new observer object
    const oImageObserver = new IntersectionObserver(entries => {
      // Loop through IntersectionObserverEntry objects
      entries
        .filter(oEntry => oEntry.isIntersecting)
        .forEach(oEntry => {
          // Do these if the target intersects with the root
          const oImage = oEntry.target;
          oImage.src = oImage.dataset.src;
          oImage.classList.remove('lazy');
          oImageObserver.unobserve(oImage);
        });
    });

    // Loop through and observe each image
    aImages.forEach(oImage => oImageObserver.observe(oImage));
  } else {
    // `document.querySelectorAll` does not work in Opera Mini
    aImages = document.getElementsByClassName('lazy');
    // https://stackoverflow.com/questions/3871547/js-iterating-over-result-of-getelementsbyclassname-using-array-foreach
    [].forEach.call(aImages, oImage => {
      oImage.src = oImage.dataset.src;
      oImage.classList.remove('lazy');
      oImage.height = 'auto';
    });
  }
});
</script>

<style lang="scss">
@import "./scss/_global.scss";
@import "./scss/_button.scss";
@import "./scss/_navigationbar.scss";
@import "./scss/_footer.scss";
</style>
