<template>
  <article class="message-box-container" :class="message.type">
    <div class="message-box box" :class="[message.type, ...message.classes].join(' ')">
      <button class="close" @click="clearMessage()">&times;</button>
      <div class="message-box-content" v-html="`<p>${message.text}</p>`" :class="{ 'max-height': (message.buttons || []).length }"></div>
      <div class="message-box-buttons">
        <button
          v-for="btn, index in message.buttons"
          :key="index"
          :class="btn.class"
          @click="callFn(btn.fn, btn.context)"
          type="button"
        >{{ btn.label }}</button>
      </div>
    </div>
  </article>
</template>

<script>
import { fnWait } from '@/modules/globalFunctions.js';

export default {
  name: 'MessageBox',
  props: {
    messageData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: this.messageData,
    }
  },
  methods: {
    /**
     * @param {'info' | 'success' | 'warning' | 'error'} sType
     * @param {string} sText
     * @param {number} iTimeToRemove
     * @param {string[]} aClasses
     * @param {{label: string, class: string, fn: (_this: any) => void}[]} aButtons
     * @param {*} _this Button function context
     */
    async showMessage(sType, sText, iTimeToRemove = 0, aClasses = [], aButtons = [], _this = undefined) {
      const iTimestamp = new Date().getTime();

      this.message = {
        ...this.message,
        show: true,
        type: sType,
        text: sText,
        classes: aClasses,
        buttons: aButtons.map(oBtn => ({ ...oBtn, context: _this })),
        timestamp: iTimestamp
      };

      // Close message box after x ms
      if (!!iTimeToRemove && iTimeToRemove > 0) {
        const _this = this;
        await fnWait(iTimeToRemove);
        if (_this.message.timestamp === iTimestamp) _this.clearMessage();
      }
    },


    /**
     * Hide message popup
     */
    clearMessage() {
      this.message = {
        ...this.message,
        show: false,
        type: 'hidden',
        text: '',
        classes: [],
        buttons: []
      };
    },


    /**
     * Dynamic function call for message box popup
     * @param {*} fn Function name
     * @param {*} _this Function context
     */
    callFn(fn = () => { }, _this) {
      this.clearMessage();
      fn(_this);
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_box.scss";
@import "./../scss/_messagebox.scss";
</style>
