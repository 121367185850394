<template>
  <main>
    <header class="menu-spacer"></header>

    <article :class="{ hidden: !isLoading }">
      <section id="titlebar-outer">
        <h2>{{ exhibition.name }}</h2>
        <button
          class="btn secondary border-black small small-padding"
          @click="edit()"
        >
          {{ text.exhibition_edit_exhibition }}
        </button>
        <button
          class="btn secondary border-black small small-padding"
          @click="openAr()"
        >
          AR
        </button>

        <div id="navigation-outer" class="exhibition-navigation">
          <button
            type="button"
            @click="switchActive('nav', 'selectedArtwork')"
            :class="{ active: active.nav === 'selectedArtwork' }"
          >
            {{ text.exhibition_select_artwork }}
          </button>
          <button
            type="button"
            @click="switchActive('nav', 'walkthrough')"
            :class="{ active: active.nav === 'walkthrough' }"
          >
            {{ text.exhibition_walkthrough }}
          </button>
          <button
            type="button"
            @click="switchActive('nav', 'map')"
            :class="{ active: active.nav === 'map' }"
          >
            {{ text.exhibition_map }}
          </button>
        </div>
      </section>

      <section id="exhibitions">
        <div id="titlebar">
          <h2>{{ exhibition.name }}</h2>
          <button class="btn secondary small small-padding" @click="edit()">
            {{ text.exhibition_edit_exhibition }}
          </button>
          <button class="btn secondary small small-padding" @click="openAr()">
            AR
          </button>

          <div id="navigation-inner" class="exhibition-navigation">
            <button
              type="button"
              @click="switchActive('nav', 'selectedArtwork')"
              :class="{ active: active.nav === 'selectedArtwork' }"
            >
              {{ text.exhibition_select_artwork }}
            </button>
            <button
              type="button"
              @click="switchActive('nav', 'walkthrough')"
              :class="{ active: active.nav === 'walkthrough' }"
            >
              {{ text.exhibition_walkthrough }}
            </button>
            <button
              type="button"
              @click="switchActive('nav', 'map')"
              :class="{ active: active.nav === 'map' }"
            >
              {{ text.exhibition_map }}
            </button>
          </div>
        </div>

        <!-- Selection screen -->
        <div
          id="content-1"
          :class="{ hidden: active.nav != 'selectedArtwork' }"
        >
          <!-- No v-if ! Otherwise the map can't be applied properly -->
          <div
            id="title-available"
            :class="{ active: active.list === 'available' }"
            @click="switchActive('list', 'available')"
          >
            {{ text.exhibition_available_artworks
            }}<span class="counter" :data-animate="animate.available">{{
              artworksAvailable.length
            }}</span>
          </div>
          <div
            id="title-selected"
            :class="{ active: active.list === 'selected' }"
            @click="switchActive('list', 'selected')"
          >
            {{ text.exhibition_my_selection
            }}<span class="counter" :data-animate="animate.selected">{{
              artworksSelected.length
            }}</span>
          </div>
          <input
            v-if="active.list === 'available'"
            id="search"
            type="text"
            v-model="filter.search"
            v-on:keyup="onFilter"
            placeholder="Search"
          />
          <div id="tooltips">
            <div>Set location</div>
            <div>Status</div>
          </div>

          <draggable
            id="artworks-available"
            class="list-group"
            tag="ul"
            group="artwork-group"
            :list="artworksAvailable"
            :animation="200"
            handle=".handle"
          >
            <li
              class="js-artwork js-init-draggable"
              v-for="(item, index) in artworksAvailable"
              :aria-hidden="!item.visible"
              :aria-expanded="item.expanded"
              :data-slide="item.slide"
              :key="item.id"
            >
              <div class="content">
                <Icon name="handle" class="handle" />
                <div class="item-number">{{ index + 1 }}</div>
                <img
                  class="artwork-image"
                  :src="item.artwork_image"
                  draggable="false"
                />
                <div class="artwork-title-usename">
                  <h3 class="artwork-title">{{ item.artwork_title }}</h3>
                  <div class="user-name">
                    {{ item.user_first_name }} {{ item.user_last_name }}
                  </div>
                </div>
                <img
                  class="user-image"
                  :src="item.user_avatar"
                  draggable="false"
                />
                <div
                  class="artwork-description"
                  v-html="item.artwork_description"
                ></div>
                <div class="actions">
                  <span class="likes"
                    ><Icon name="heart" class="inline-svg" />{{
                      item.likes
                    }}</span
                  >
                </div>

                <div
                  class="add-remove"
                  data-animate="add"
                  @click="moveArtwork($event, index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32.468 27.794"
                    class="inline-svg"
                  >
                    <path
                      fill="currentColor"
                      d="M20.157,27.794l-1.819-1.872,9.871-11.013-1.246.26H0V12.676H27.014l1.144.31L18.338,1.87,20.157,0,32.468,13.871Z"
                    />
                  </svg>
                  {{ text.exhibition_add }}
                </div>
              </div>
            </li>
          </draggable>

          <draggable
            id="artworks-selected"
            class="list-group"
            tag="ul"
            group="artwork-group"
            :list="artworksSelected"
            :animation="200"
            handle=".handle"
            @sort="updateExhibitionArtworks()"
            ><!-- Sort triggers at add/remove too -->
            <li
              class="js-artwork js-init-draggable"
              v-for="(item, index) in artworksSelected"
              :aria-hidden="!item.visible"
              :aria-expanded="item.expanded"
              :data-slide="item.slide"
              :key="item.id"
            >
              <div class="content">
                <Icon name="handle" class="handle" />
                <div class="item-number">{{ index + 1 }}</div>
                <img
                  class="artwork-image"
                  :src="item.artwork_image"
                  draggable="false"
                />
                <div class="artwork-title-usename">
                  <h3 class="artwork-title">{{ item.artwork_title }}</h3>
                  <div class="user-name">
                    {{ item.user_first_name }} {{ item.user_last_name }}
                  </div>
                </div>
                <img
                  class="user-image"
                  :src="item.user_avatar"
                  draggable="false"
                />
                <div
                  class="artwork-description"
                  v-html="item.artwork_description"
                ></div>
                <div class="actions">
                  <span class="likes"
                    ><Icon name="heart" class="inline-svg" />{{
                      item.likes
                    }}</span
                  >
                </div>

                <div
                  class="add-remove"
                  data-animate="remove"
                  @click="moveArtwork($event, index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32.468 27.794"
                    class="inline-svg"
                  >
                    <path
                      fill="currentColor"
                      d="M12.312,0l1.819,1.872L4.26,12.885l1.245-.26H32.468v2.493H5.455l-1.145-.31,9.821,11.116-1.819,1.87L0,13.923Z"
                    />
                  </svg>
                  {{ text.exhibition_remove }}
                </div>
              </div>

              <div class="content-2">
                <button
                  type="button"
                  class="btn secondary"
                  @click="openLocationModal(item)"
                >
                  {{ text.exhibition_set_location }}
                </button>
              </div>

              <div class="content-3">
                <div
                  v-if="
                    item.locationtype !== 'indoor' &&
                    item.locationtype !== 'outdoor'
                  "
                >
                  <p>{{ text.exhibition_status_text_none }}</p>
                </div>
                <div v-if="item.locationtype === 'indoor'">
                  <p>{{ text.exhibition_status_text_indoor }}</p>
                  <!-- <p><a :href="`https://arxafrica.net/marker/${item.artwork_marker}.jpg`" target="new" :download="item.artwork_title">{{ text.exhibition_status_download_marker }}</a></p> -->
                </div>
                <div v-if="item.locationtype === 'outdoor'">
                  <p>{{ text.exhibition_status_text_outdoor }}</p>
                </div>
                <Icon name="location" :type="item.locationtype || 'none'" />
              </div>
            </li>
          </draggable>

          <draggable
            id="artworks-selected-dropzone"
            tag="div"
            group="artwork-group"
            :list="artworksSelected"
            @add="updateExhibitionArtworks()"
          >
            <div class="dropzone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35.55"
                height="24.629"
                viewBox="0 0 35.55 24.629"
              >
                <path
                  fill="currentColor"
                  d="M59.839-63.886v17.635a1.6,1.6,0,0,1-1.594,1.594H29.7A1.6,1.6,0,0,1,28.1-46.251v-7.357A1.6,1.6,0,0,1,29.7-55.2a1.6,1.6,0,0,1,1.594,1.594v5.763H56.653l0-14.447H40.781a1.6,1.6,0,0,1-1.594-1.594,1.6,1.6,0,0,1,1.594-1.594H58.245a1.594,1.594,0,0,1,1.594,1.594Z"
                  transform="translate(-24.288 69.286)"
                />
                <path
                  fill="currentColor"
                  d="M32.927-66.049a1.6,1.6,0,0,1-1.594,1.594H29.122v2.212a1.6,1.6,0,0,1-1.594,1.594,1.6,1.6,0,0,1-1.594-1.594v-2.212H23.715a1.6,1.6,0,0,1-1.594-1.594,1.6,1.6,0,0,1,1.594-1.594h2.219v-2.212a1.6,1.6,0,0,1,1.594-1.594,1.6,1.6,0,0,1,1.594,1.594v2.212h2.212A1.6,1.6,0,0,1,32.927-66.049Z"
                  transform="translate(-22.121 71.449)"
                /></svg
              >{{ text.exhibition_dropzone }}
            </div>
          </draggable>
        </div>

        <!-- Horizontal overview -->
        <div id="content-2" :class="{ hidden: active.nav != 'walkthrough' }">
          <swiper
            ref="slider"
            :options="swiperOption"
            :paginationEnabled="false"
            @page-change="closeAllItems"
            ><!-- No v-if ! Otherwise the map can't be applied properly -->
            <swiperSlide
              class="js-artwork"
              v-for="(item, index) in artworksSelected"
              :aria-expanded="item.expanded"
              :key="item.id"
            >
              <div>
                <div class="content">
                  <img
                    class="artwork-image"
                    :src="item.artwork_image"
                    draggable="false"
                  />
                  <div class="artwork-title-usename">
                    <h3 class="artwork-title">{{ item.artwork_title }}</h3>
                    <div class="user-name">
                      {{ item.user_first_name }} {{ item.user_last_name }}
                    </div>
                  </div>
                  <div class="actions">
                    <span class="likes"
                      ><Icon name="heart" class="inline-svg" />{{
                        item.likes
                      }}</span
                    >
                    <span class="separator"></span>
                    <button
                      type="button"
                      class="delete"
                      @click="moveArtwork($event, index)"
                    >
                      <Icon name="trash" class="inline-svg" />
                    </button>
                    <!-- <button type="button" class="ar"><Icon name="ar" class="inline-svg"/></button> -->
                    <button
                      v-if="item.expanded"
                      type="button"
                      class="collapse"
                      @click="collapseArtworkItem(item)"
                    >
                      <Icon name="close" class="inline-svg" />
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  class="togglebutton"
                  @click="toggleDetails($event, item)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.035603"
                    height="16.030159"
                    viewBox="0 0 18.035602 16.030159"
                  >
                    <path
                      fill="currentColor"
                      d="m 7.2905927,0.99215887 a 2,2 0 0 1 3.4550003,0 l 7.018,12.03100013 a 2,2 0 0 1 -1.727,3.007 H 2.0005927 a 2,2 0 0 1 -1.72799995,-3.008 z"
                    />
                  </svg>
                </button>

                <div class="details">
                  <div
                    class="artwork-description"
                    v-html="item.artwork_description"
                  ></div>

                  <div class="map">
                    <h2>{{ text.exhibition_location }}</h2>
                    <div class="map-types">
                      <button
                        type="button"
                        data-map-style="streets"
                        @click="mapBox.setStyle('streets-v11')"
                        :class="{
                          active: mapBox && mapBox.getStyle() === 'streets-v11',
                        }"
                      ></button>
                      <button
                        type="button"
                        data-map-style="satellite"
                        @click="mapBox.setStyle('satellite-streets-v11')"
                        :class="{
                          active:
                            mapBox &&
                            mapBox.getStyle() === 'satellite-streets-v11',
                        }"
                      ></button>
                    </div>
                    <div
                      class="mapbox-map"
                      :id="`mapbox-walkthrough-${index}`"
                    ></div>
                  </div>

                  <div class="status">
                    <h2>{{ text.exhibition_status }}</h2>
                    <Icon name="status" class="status-icon" />
                    <div class="status-text">
                      <p
                        v-if="
                          item.locationtype != 'indoor' &&
                          item.locationtype != 'outdoor'
                        "
                      >
                        {{ text.exhibition_status_text_none }}
                      </p>
                      <p v-else-if="item.locationtype === 'indoor'">
                        {{ text.exhibition_status_text_indoor }}
                      </p>
                      <p v-else-if="item.locationtype === 'outdoor'">
                        {{ text.exhibition_status_text_outdoor }}
                      </p>
                      <button
                        type="button"
                        class="btn secondary mt1"
                        @click="openLocationModal(item)"
                      >
                        {{ text.exhibition_set_location }}
                      </button>
                    </div>
                    <div class="location-icon">
                      <Icon
                        name="location"
                        :type="item.locationtype || 'none'"
                      />
                    </div>
                    <div
                      class="marker"
                      :aria-hidden="item.locationtype !== 'indoor'"
                    >
                      <!-- <img :src="`https://arxafrica.net/pattern/${item.artwork_marker}.svg`" :alt="`Marker - ${item.user_first_name} ${item.user_last_name} - ${item.artwork_title}`" /> -->
                      <!-- <div> -->
                      <!-- <p>{{ text.exhibition_status_download_marker }}</p>
                        <a :href="`https://arxafrica.net/pattern/${item.artwork_marker}.jpg`" :download="`Marker - ${item.user_first_name} ${item.user_last_name} - ${item.artwork_title}`">JPG</a>
                        <a :href="`https://arxafrica.net/pattern/${item.artwork_marker}.svg`" :download="`Marker - ${item.user_first_name} ${item.user_last_name} - ${item.artwork_title}`">SVG</a> -->
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </swiperSlide>
          </swiper>
          <div class="swiper-prev"><Icon name="arrowleft" /></div>
          <div class="swiper-next"><Icon name="arrowright" /></div>
        </div>

        <!-- Map view -->
        <div id="content-3" :class="{ hidden: active.nav != 'map' }">
          <!-- No v-if ! Otherwise the map can't be applied properly -->
          <div class="map-types">
            <button
              type="button"
              data-map-style="streets"
              @click="mapBoxOverview.setStyle('streets-v11')"
              :class="{
                active:
                  mapBoxOverview && mapBoxOverview.getStyle() === 'streets-v11',
              }"
            ></button>
            <button
              type="button"
              data-map-style="satellite"
              @click="mapBoxOverview.setStyle('satellite-streets-v11')"
              :class="{
                active:
                  mapBoxOverview &&
                  mapBoxOverview.getStyle() === 'satellite-streets-v11',
              }"
            ></button>
          </div>
          <div class="mapbox-map" id="mapbox-overview-container">
            <div id="mapbox-overview"></div>
          </div>
        </div>

        <div id="location-modal" :class="{ hidden: modalClosed }">
          <div>
            <header>
              <h2>{{ text.exhibition_set_location }}</h2>
              <button type="button" class="close" @click="modalClosed = true">
                <Icon name="close" class="inline-svg" />
              </button>
            </header>

            <div class="content">
              <div>
                <h2>{{ text.exhibition_location_type }}</h2>
                <div class="location-type">
                  <!-- Indoor -->
                  <label for="indoor" @click="setLocationType('indoor')">
                    <input
                      type="radio"
                      value="indoor"
                      id="indoor"
                      v-model="locationModal.type"
                    />
                    <span class="radio-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.301"
                        height="17.301"
                        viewBox="0 0 17.301 17.301"
                      >
                        <g v-if="locationModal.type === 'indoor'">
                          <path
                            fill="#707070"
                            fill-rule="evenodd"
                            d="M8.65-95.642a4.293,4.293,0,0,1,4.293,4.293A4.293,4.293,0,0,1,8.65-87.057,4.293,4.293,0,0,1,4.358-91.35,4.293,4.293,0,0,1,8.65-95.642Zm0-4.358a8.624,8.624,0,0,1,6.117,2.534A8.622,8.622,0,0,1,17.3-91.35a8.624,8.624,0,0,1-2.534,6.117A8.625,8.625,0,0,1,8.65-82.7a8.624,8.624,0,0,1-6.117-2.534A8.622,8.622,0,0,1,0-91.35a8.624,8.624,0,0,1,2.534-6.117A8.622,8.622,0,0,1,8.65-100Zm5.162,3.489A7.275,7.275,0,0,0,8.65-98.65a7.276,7.276,0,0,0-5.162,2.138A7.275,7.275,0,0,0,1.35-91.35a7.276,7.276,0,0,0,2.138,5.162A7.276,7.276,0,0,0,8.65-84.05a7.276,7.276,0,0,0,5.162-2.138A7.275,7.275,0,0,0,15.95-91.35,7.276,7.276,0,0,0,13.812-96.511Z"
                            transform="translate(0 100)"
                          />
                        </g>
                        <g
                          v-else
                          fill="#ffffff"
                          stroke="#707070"
                          stroke-width="1.5"
                        >
                          <circle
                            cx="8.608"
                            cy="8.608"
                            r="8.608"
                            stroke="none"
                          />
                          <circle cx="8.608" cy="8.608" r="7.858" fill="none" />
                        </g>
                      </svg>
                    </span>
                    <span class="location-icon"
                      ><Icon name="location" type="indoor"
                    /></span>
                    <div
                      class="description"
                      v-html="text.exhibition_location_indoor_description"
                    ></div>
                  </label>

                  <!-- Outdoor -->
                  <label for="outdoor" @click="setLocationType('outdoor')">
                    <input
                      type="radio"
                      value="outdoor"
                      id="outdoor"
                      v-model="locationModal.type"
                    />
                    <span class="radio-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.301"
                        height="17.301"
                        viewBox="0 0 17.301 17.301"
                      >
                        <g v-if="locationModal.type === 'outdoor'">
                          <path
                            fill="#707070"
                            fill-rule="evenodd"
                            d="M8.65-95.642a4.293,4.293,0,0,1,4.293,4.293A4.293,4.293,0,0,1,8.65-87.057,4.293,4.293,0,0,1,4.358-91.35,4.293,4.293,0,0,1,8.65-95.642Zm0-4.358a8.624,8.624,0,0,1,6.117,2.534A8.622,8.622,0,0,1,17.3-91.35a8.624,8.624,0,0,1-2.534,6.117A8.625,8.625,0,0,1,8.65-82.7a8.624,8.624,0,0,1-6.117-2.534A8.622,8.622,0,0,1,0-91.35a8.624,8.624,0,0,1,2.534-6.117A8.622,8.622,0,0,1,8.65-100Zm5.162,3.489A7.275,7.275,0,0,0,8.65-98.65a7.276,7.276,0,0,0-5.162,2.138A7.275,7.275,0,0,0,1.35-91.35a7.276,7.276,0,0,0,2.138,5.162A7.276,7.276,0,0,0,8.65-84.05a7.276,7.276,0,0,0,5.162-2.138A7.275,7.275,0,0,0,15.95-91.35,7.276,7.276,0,0,0,13.812-96.511Z"
                            transform="translate(0 100)"
                          />
                        </g>
                        <g
                          v-else
                          fill="#ffffff"
                          stroke="#707070"
                          stroke-width="1.5"
                        >
                          <circle
                            cx="8.608"
                            cy="8.608"
                            r="8.608"
                            stroke="none"
                          />
                          <circle cx="8.608" cy="8.608" r="7.858" fill="none" />
                        </g>
                      </svg>
                    </span>
                    <span class="location-icon"
                      ><Icon name="location" type="outdoor"
                    /></span>
                    <div
                      class="description"
                      v-html="text.exhibition_location_outdoor_description"
                    ></div>
                  </label>
                </div>
              </div>

              <div class="group">
                <h2>{{ text.exhibition_location_group }}</h2>
                <select
                  v-model="locationModal.groupWith"
                  @change="inheritCoordinates($event)"
                >
                  <option value=""></option>
                  <option
                    v-for="item in locationModal.groupItems"
                    :key="item.label"
                    :value="item.coords"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>

              <div class="sizing">
                <h2>{{ text.exhibition_location_sizing }}</h2>
                <select v-model="locationModal.scaling">
                  <option
                    v-for="item in sizeModes"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>

              <div class="range">
                <h2>{{ text.exhibition_location_range }}</h2>
                <div class="custom-input-number">
                  <button
                    type="button"
                    class="btn"
                    @click="stepDecrease('location-range')"
                  >
                    -
                  </button>
                  <input
                    id="location-range"
                    type="number"
                    :min="locationRangeDefault"
                    max="99999.9"
                    step="1"
                    v-model="locationModal.range"
                  />
                  <button
                    type="button"
                    class="btn"
                    @click="stepIncrease('location-range')"
                  >
                    +
                  </button>
                </div>
              </div>

              <div class="map">
                <h2>{{ text.exhibition_location }}</h2>
                <div class="map-types">
                  <button
                    type="button"
                    data-map-style="streets"
                    @click="mapBoxModal.setStyle('streets-v11')"
                    :class="{
                      active:
                        mapBoxModal && mapBoxModal.getStyle() === 'streets-v11',
                    }"
                  ></button>
                  <button
                    type="button"
                    data-map-style="satellite"
                    @click="mapBoxModal.setStyle('satellite-streets-v11')"
                    :class="{
                      active:
                        mapBoxModal &&
                        mapBoxModal.getStyle() === 'satellite-streets-v11',
                    }"
                  ></button>
                </div>
                <div class="how-to" v-html="text.exhibition_how_to"></div>
                <div class="mapbox-map" id="mapbox-modal"></div>
              </div>
            </div>

            <footer>
              <button type="button" class="btn" @click="modalClosed = true">
                Cancel
              </button>
              <button type="button" class="btn" @click="setLocationData()">
                Submit
              </button>
            </footer>
          </div>
        </div>
      </section>
    </article>

    <div id="hidden-elements">
      <div id="mapbox"></div>
      <!-- Element will be moved via JavaScript -->
    </div>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
import draggable from 'vuedraggable';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import Icon from '@/components/Icon.vue';
import Loader from "@/components/Loader.vue";
import { oRoles, oMetaDataTemplate, fnRunSimultaneously, fnInitDraggables, fnWait, fnSetPageInformations, fnFilterLikes } from '@/modules/globalFunctions.js';
import { MapBox } from '@/modules/MapBox.js';
import $ from 'jquery';

const oTextTemplate = {
  exhibition_select_artwork: '',
  exhibition_walkthrough: '',
  exhibition_map: '',
  exhibition_edit_exhibition: '',
  exhibition_available_artworks: '',
  exhibition_my_selection: '',
  exhibition_add: '',
  exhibition_remove: '',
  exhibition_location_indoor_description: '',
  exhibition_location_outdoor_description: '',
  exhibition_set_location: '',
  exhibition_location: '',
  exhibition_status: '',
  exhibition_status_text_none: '',
  exhibition_status_text_indoor: '',
  exhibition_status_text_outdoor: '',
  exhibition_status_download_marker: '',
  exhibition_dropzone: '',
  exhibition_location_type: '',
  exhibition_location_group: '',
  exhibition_location_range: '',
  exhibition_location_sizing: '',
  exhibition_sizing_auto: '',
  exhibition_sizing_fixed: '',
  exhibition_how_to: ''
};

export default {
  name: 'Exhibition',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    draggable,
    swiper,
    swiperSlide,
    Icon,
    Loader
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_exhibition',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,

      language: 'en',

      exhibition: {},

      // Items
      artworksAvailable: [],
      artworksSelected: [],
      artworkSelectedDropzone: [],

      oLocationResetAddress: {
        locationtype: null,
        latitude: null,
        longitude: null,
        address_saved: {
          locationtype: null,
          latitude: null,
          longitude: null
        }
      },
      oLocationResetDisplay: {
        expanded: false,
        slide: null
      },

      // Count up animation
      animate: {
        available: false,
        selected: false,
        itemInMotion: false
      },

      // Active list
      active: {
        nav: 'selectedArtwork', // 'selectedArtwork' | 'walkthrough' | 'map'
        list: 'available'       // 'available' | 'selected'
      },

      // Filter
      filter: {
        search: "",
        searchKeys: [
          'artwork_title',
          'artwork_description',
          'user_first_name',
          'user_last_name'
        ]
      },

      // Placeholder
      placeholder: {
        image: 'https://arxafrica.net/images/placeholder.png',
        user: 'https://arxafrica.net/images/placeholder_user.svg'
      },

      // Map instances
      mapBox: null,
      mapBoxOverview: null,
      mapBoxModal: null,

      // Slider
      swiperOption: {
        slidesPerView: 1.4,
        spaceBetween: 16,
        centeredSlides: true,
        noSwipingSelector: '.details',
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }
      },

      locationModal: {
        artwork: null,
        type: 'none',
        lat: null,
        lng: null,
        range: null,
        groupWith: '',
        groupItems: [],
        scaling: ''
      },
      locationRangeDefault: 5,
      modalClosed: true,

      sizeModes: [
        { key: 'auto', label: '' },
        { key: 'fixed', label: '' }
      ],

      allLikes: []
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapGetters({ IsExhibitor: 'isExhibitor', Exhibition: 'StateExhibition', Artworks: 'StateArtworks', User: 'StateUser', Token: 'StateToken', Likes: 'StateLikes' }),

    swiper() {
      return this.$refs.slider.$swiper;
    }
  },
  methods: {
    ...mapActions(['GetArtworks', 'GetExhibition', 'UpdateExhibition', 'GetLikes']),


    /**
     * Initialization function
     */
    async init() {
      // Workaround for wrong routing component directly after changing the user
      if (!this.IsExhibitor) {
        location.reload();
        return;
      }

      const _this = this;

      // Set language
      this.language = this.$route.meta.language;

      this.isLoading = true;

      await fnSetPageInformations(this, oTextTemplate);

      const aArtworkParameters = [
        'filter[status][_in]=published,exhibition',
        'filter[user_created][status]=active'
      ];

      this.sizeModes.forEach((oMode, i) => this.sizeModes[i].label = this.text[`exhibition_sizing_${oMode.key}`] || oMode.key || '');

      await fnRunSimultaneously([
        async () => await _this.GetExhibition(_this.$route.params.id),
        async () => await _this.GetLikes(),
        async () => _this.GetArtworks(aArtworkParameters.join('&'), oRoles.artist)
      ], `${this.metaData.page} | Exhibition, Artworks`);

      // Fix missing field errors
      this.exhibition = this.Exhibition || {};
      this.allLikes = this.Likes || [];

      // Default item values
      const oDefaultValues = {
        matches: 0,
        visible: true
      };

      const aAllowedTypes = ['gif', 'jpeg', 'jpg', 'png'];

      const fnExtractUserData = oArtwork => ({
        user_avatar: oArtwork.user_created?.avatar ? `https://arxafrica.net/assets/${oArtwork.user_created?.avatar}/avatar` : _this.placeholder.user,
        user_id: oArtwork.user_created?.id,
        user_first_name: oArtwork.user_created?.first_name,
        user_last_name: oArtwork.user_created?.last_name
      });

      const aVimeoVideos = await Promise.all(
        this.Artworks
          .filter(oArtwork => oArtwork.type === 'video' && oArtwork.vimeo_id)
          .map(oArtwork => this.getVimeoPreview(oArtwork.id, oArtwork.vimeo_id))
      );
      const oVimeoPreviews = {};
      aVimeoVideos
        .filter(oData => oData.link)
        .forEach(oData => oVimeoPreviews[`_${oData.id}`] = oData.link);

      const fnExtractArtworkData = oArtwork => {
        // Filter for preview or image and sort ascending
        const sVimeoPreview = oVimeoPreviews[`_${oArtwork.id}`];
        const aFiles = (oArtwork.directus_files || []).filter(oFile => aAllowedTypes.indexOf(oFile.filename_disk.split('.').pop().toLowerCase()) !== -1);

        return {
          artwork_image: sVimeoPreview
            ? sVimeoPreview
            : aFiles.length
              ? `https://arxafrica.net/assets/${aFiles[0]?.id}/preview`
              : _this.placeholder.image,
          artwork_title: oArtwork.title,
          artwork_description: oArtwork.description,
          artwork_marker: oArtwork?.marker?.marker
        };
      };

      // Get selected artworks from exhibition
      this.artworksSelected = (this.exhibition?.artworks || [])
        .filter(oExhibitionWork => oExhibitionWork.works_id?.user_created?.role === oRoles.artist)
        .map((oExhibitionWork, i) => {
          const oLocation = {
            locationtype: oExhibitionWork.locationtype,
            latitude: oExhibitionWork.latitude,
            longitude: oExhibitionWork.longitude,
            range: oExhibitionWork.range,
            scaling: oExhibitionWork.scaling
          };
          const oArtwork = oExhibitionWork.works_id;

          return {
            ...fnExtractArtworkData(oArtwork),
            ...fnExtractUserData(oArtwork),
            work: oArtwork.id,
            order: i,
            ...oDefaultValues,
            likes: fnFilterLikes(this.allLikes, null, oArtwork.id),
            ...this.oLocationResetDisplay,
            ...{
              ...this.oLocationResetAddress,
              ...oLocation,
              address_saved: oLocation
            }
          };
        });

      const aSelectedWorksIds = this.artworksSelected.map(oArtwork => oArtwork.work);

      // Get available published artworks except already selected ones
      this.artworksAvailable = (this.Artworks || [])
        .filter(oArtwork => oArtwork?.user_created?.role === oRoles.artist)
        // Show only artworks which are not already selected
        .filter(oArtwork => aSelectedWorksIds.indexOf(oArtwork?.id) === -1)
        .map((oArtwork, i) => ({
          ...fnExtractArtworkData(oArtwork),
          ...fnExtractUserData(oArtwork),
          work: oArtwork.id,
          order: i,
          ...oDefaultValues,
          likes: fnFilterLikes(this.allLikes, null, oArtwork.id),
          ...this.oLocationResetDisplay,
          ...this.oLocationResetAddress
        }));

      await fnWait(1);

      // Make elements draggable
      fnInitDraggables();

      // Initialize maps
      this.mapBox = new MapBox('mapbox', {
        geoCoder: true,
        zoom: true,
        captureClick: true,
        currentLocation: true
      });

      this.mapBox.setCaptureClick(true);

      this.mapBoxOverview = new MapBox('mapbox-overview', { zoom: true });

      this.mapBoxModal = new MapBox('mapbox-modal', {
        zoom: true,
        captureClick: true,
        currentLocation: true
      });

      // Click event
      this.mapBoxModal.getMap().on('click', oEvent => {
        if (!oEvent.lngLat) return;

        const { lat: iLat, lng: iLng } = oEvent.lngLat;

        this.locationModal = {
          ...this.locationModal,
          groupWith: '',
          lat: iLat,
          lng: iLng
        };

        this.createNewPositionMarker(iLat, iLng, this.getTypeColor('indoor'));
      });

      this.isLoading = false;
    },


    /**
     * Route to edit exhibition
     */
    edit() {
      this.$router.push(`/${this.$route.meta.language}/exhibition/${this.$route.params.id}/edit`);
    },


    /**
     * Check if media query matches (min-width: 992px)
     */
    checkDesktop() {
      return window.matchMedia('(min-width: 992px)').matches;
    },


    /**
     * @param {any} oItem Selected artwork
     * @returns {boolean} Changes pending
     */
    addressHasChanged(oItem) {
      return oItem.address_saved.locationtype !== oItem.locationtype ||
        oItem.address_saved.longitude != oItem.longitude ||
        oItem.address_saved.latitude != oItem.latitude;
    },


    /**
     * Move artwork from one list to another
     */
    async moveArtwork(oEvent, iItemIndex) {
      // Prevent multiple animations at a time
      if (this.animate.itemInMotion) return;

      const _this = this;
      const $elem = $(oEvent.target);
      const $target = $elem.hasClass('add-remove') ? $elem : $elem.parents('.add-remove');
      const $listElement = $target.parents('.list-group>li');
      const bAdd = $target.attr('data-animate') === 'add';
      const bAllowAnimation = !this.checkDesktop() && $target.attr('data-animate') !== undefined;

      const fnMove = async () => {
        // Move selected item to other array
        _this.mapBox.moveTo($('#hidden-elements')); // Change DOM position of map, otherwise it will disappear
        _this[sTargetArray].push(
          ..._this[sSourceArray]
            .splice(iItemIndex, 1)
            .map(oItem => ({
              ...oItem,
              ...this.oLocationResetDisplay,
              ...this.oLocationResetAddress
            }))
        );

        // Update artworks in exhibition
        await this.updateExhibitionArtworks();
      };

      // Animate (slide) list element
      if (bAllowAnimation) {
        this.animate.itemInMotion = true;
        $listElement.attr('data-slide', bAdd ? 'left' : 'right'); // Set item.slide
        await fnWait(300);
      }

      const sTargetArray = bAdd ? 'artworksSelected' : 'artworksAvailable'; // Array in 'this' context
      const sSourceArray = !bAdd ? 'artworksSelected' : 'artworksAvailable'; // Array in 'this' context
      const sSplashTarget = bAdd ? 'selected' : 'available'; // Attribute of this.animate

      if (bAllowAnimation) {
        // Slide element up
        $listElement.animate(
          { marginTop: $listElement.height() * -1 },
          150,
          async () => {
            $listElement.css('margin-top', '');

            // Move selected item to other array
            fnMove();

            // Number splash animation
            _this.animate[sSplashTarget] = true;
            await fnWait(300);
            _this.animate[sSplashTarget] = false;
            this.animate.itemInMotion = false;
          });
      } else {
        $listElement.css('margin-top', '');

        // Move selected item to other array
        fnMove();
      }
    },


    /**
     * Save artwork data to exhibition
     */
    async updateExhibitionArtworks() {
      // Update artworks in exhibition
      await this.UpdateExhibition({
        artworks: this.artworksSelected.map((oArtwork, i) => ({
          exhibition_id: this.exhibition.id,
          works_id: oArtwork.work,
          sort: i,
          ...oArtwork.address_saved // latitude, longitude, locationtype
        }))
      });
    },


    /**
     * Switch active tab / section
     */
    async switchActive(sKey, sNewValue) {
      if (this.active[sKey] === sNewValue) return;

      this.active[sKey] = sNewValue;

      this.closeAllItems();

      if (sKey === 'nav' && sNewValue === 'map') {
        this.groupLocations(this.mapBoxOverview);

        await fnWait(1);

        this.mapBoxOverview.getMap().resize();
        $(window).trigger('resize');
      }
    },


    async collapseArtworkItem(oItem) {
      if (oItem.expanded) oItem.expanded = false;
      this.mapBox.moveTo($('#hidden-elements'));
    },


    /**
     * Expand / collapse item details.
     * Inserts map element and changes the aria-hidden attribute
     *
     * @param oEvent
     * @param oItem
     */
    toggleDetails(oEvent, oItem) {
      oItem.expanded = !oItem.expanded;

      if (oItem.expanded) {
        const $target = $(oEvent.target);
        const $item = $target.hasClass('js-artwork') ? $target : $target.parents('.js-artwork');
        const sNewContainerId = $item.find('.mapbox-map').attr('id');

        this.closeAllItems();

        // Expand selected item
        oItem.expanded = true;

        if (sNewContainerId) {
          this.mapBox.moveTo($(`#${sNewContainerId}`));
          if (oItem.latitude && oItem.longitude) this.onSubmitCoords(this.mapBox, oItem);
        }
      }
    },


    /**
     * Set expanded state of every available and selected item to false
     */
    closeAllItems() {
      // Collapse all items
      this.artworksAvailable.forEach(oItem => oItem.expanded = false);
      this.artworksSelected.forEach(oItem => oItem.expanded = false);
    },


    /**
     * Search functionality.
     * Searches through every element of array this.filter.searchKeys as a key of the given Object
     */
    searchInObject(sSearch, oObject) {
      const oRegex = new RegExp(sSearch, 'gi');
      let iMatches = 0;
      this.filter.searchKeys.forEach(sKey => iMatches += ((oObject[sKey]?.toString() || '').match(oRegex) || []).length);
      return iMatches;
    },


    /**
     * Search through artwork elements
     */
    async onFilter() {
      // Add delay of 1ms for filter array to be refreshed
      await fnWait(1);

      const bSearchText = !!this.filter.search?.length;
      // const aArtistFilter = this.filter.artists.map(oArtist => oArtist.id);

      // Set visible and matches attributes of each element
      this.artworksAvailable.forEach((oArtwork, i) => {
        const iMatches = bSearchText ? this.searchInObject(this.filter.search, oArtwork) : 0;
        // const bArtistChecked = aArtistFilter.length ? aArtistFilter.indexOf(oArtwork.user_id) !== -1 : true;

        this.artworksAvailable[i].visible = /* bArtistChecked && */(bSearchText ? !!iMatches : true);
        this.artworksAvailable[i].matches = iMatches;
      });

      // Sort elements by search matches or use default sort order
      this.artworksAvailable = this.artworksAvailable.sort((a, b) => this.filter.search ? b.matches - a.matches : a.order - b.order);
    },


    /**
     * Save coordinates for one specific item
     *
     * @param {*} oItem Artwork item
     */
    async onSubmitCoords(oMap, oItem) {
      if (oItem.latitude === null || oItem.longitude === null) return;

      // Save changes
      for (let sKey in oItem.address_saved) oItem.address_saved[sKey] = oItem[sKey];

      // Remove all set locations
      oMap.removeAllMarkers();

      // Add location
      if (oItem.latitude !== null && oItem.longitude !== null) oMap.addGeoLocation(oItem.latitude, oItem.longitude, null, true);

      // Update artworks in exhibition
      await this.updateExhibitionArtworks();
    },


    /**
     * Store the current position in latitude and longitude fields
     *
     * @param oMap MapBox instance
     * @param oItem Artwork item
     * @returns
     */
    async onSelectCurrentPosition(oMap, oItem) {
      const oCoordinates = await oMap.getCurrentPosition();

      if (!oCoordinates) return;

      const { latitude: iLatitude, longitude: iLongitude } = oCoordinates;
      oItem.latitude = iLatitude;
      oItem.longitude = iLongitude;
    },


    /**
     * Group all selected artworks on geolocations and create markers on map
     */
    groupLocations(oMap) {
      const aGroups = [/*
        latitude: number,
        longitude: number,
        ids: number[]
      */];

      // Create groups by latitude and longitude
      this.artworksSelected.forEach((oArtwork, iArtworkIndex) => {
        // Check if element already exists
        let iGroupIndex = undefined;
        for (let i = 0; i < aGroups.length; i++) {
          if (aGroups[i].latitude === oArtwork.latitude && aGroups[i].longitude === oArtwork.longitude) {
            iGroupIndex = i;
            break;
          }
        }

        iGroupIndex !== undefined
          ? aGroups[iGroupIndex].ids.push(iArtworkIndex)
          : aGroups.push({
            latitude: oArtwork.latitude,
            longitude: oArtwork.longitude,
            ids: [iArtworkIndex]
          });
      });

      // Create markergroups
      oMap.removeAllMarkers();

      // console.log(aGroups);

      // Create a marker for each group
      aGroups.forEach(oGroup => {
        const oMarker = oMap.createMarkerSvg();
        oMarker.className = `${oMarker.className} marker-anchor`; // Append a class to already registered classes

        const oContainer = document.createElement('div');
        oContainer.className = 'id-container';

        oGroup.ids.forEach(iId => {
          const oElement = document.createElement('span');
          oElement.className = 'id-element';
          oElement.setAttribute('data-id', iId.toString());
          oElement.innerText = (iId + 1).toString();
          // oElement.addEventListener('click', fnIdElementOnclick);

          oContainer.appendChild(oElement);
        });

        oMarker.appendChild(oContainer);

        oMap.addGeoLocation(oGroup.latitude, oGroup.longitude, oMarker);
      });

      return aGroups;
    },


    async openAr(bDebug = false) {
      // Get random token
      const { data: sToken } = await axios.get('https://arxafrica.net/lib/php/data/getRandomToken.php');

      // Set random token to userprofile
      if (sToken) await axios.patch(`${this.url}/users/me`, { ar_edit_token: sToken }, { headers: { Authorization: `Bearer ${this.Token}` } });

      /**
       * @param {HTMLElement} oElem Target element
       * @param {{name: string, value: string}[]} aAttr Attributes
       * @returns {HTMLElement} Modified target element
       */
      const fnSetAttributes = (oElem, aAttr) => {
        aAttr.forEach(oAttr => oElem.setAttribute(oAttr.name, oAttr.value));
        return oElem;
      };

      const oForm = fnSetAttributes(document.createElement('form'), [
        { name: 'id', value: 'token-form' },
        { name: 'name', value: 'token-form' },
        { name: 'method', value: 'POST' },
        { name: 'action', value: `https://exhibition.arxafrica.net/${this.exhibition.id}` },
        { name: 'style', value: 'display:none' }
      ]);
      console.log(bDebug);

      const oInput = fnSetAttributes(document.createElement('input'), [
        { name: 'type', value: 'hidden' },
        { name: 'name', value: 'token_ar' },
        { name: 'value', value: sToken }
      ]);

      oForm.append(oInput);

      // Create form element
      document.getElementById('token-form')?.remove();
      document.body.append(oForm);

      // Redirect to exhibition
      document.forms['token-form'].submit();
      document.getElementById('token-form')?.remove();
    },

    async openLocationModal(oItem) {
      this.modalClosed = false;

      const aGroups = this.groupLocations(this.mapBoxModal);

      this.locationModal = {
        ...this.locationModal,
        artwork: oItem.work,
        type: oItem?.address_saved?.locationtype || 'none',
        lat: oItem?.latitude || oItem?.address_saved?.latitude || null,
        lng: oItem?.longitude || oItem?.address_saved?.longitude || null,
        range: oItem?.address_saved?.range || this.locationRangeDefault,
        groupWith: '',
        groupItems: aGroups
          .filter(oItem => !!oItem.latitude && !!oItem.longitude)
          .map(oItem => ({
            label: oItem.ids.map(i => i + 1).join(' / '),
            coords: JSON.stringify({ lat: oItem.latitude, lng: oItem.longitude })
          })),
        scaling: oItem?.scaling || oItem?.address_saved?.scaling || 'auto'
      };

      await fnWait(1);

      if (this.locationModal.lat && this.locationModal.lng) {
        this.createNewPositionMarker(this.locationModal.lat, this.locationModal.lng, this.getTypeColor(this.locationModal.type));
        this.mapBoxModal.getMap().flyTo({ center: { lat: this.locationModal.lat, lng: this.locationModal.lng }, essential: true });
      }

      document.querySelector('#location-modal .content').scrollTo(0, 0);

      this.mapBoxModal.getMap().resize();
    },

    async setLocationData() {
      let iSelectedIndex = null;
      for (let i = 0, iMax = this.artworksSelected.length; i < iMax; i++) {
        if (this.artworksSelected[i].work === this.locationModal.artwork) {
          iSelectedIndex = i;
          break;
        }
      }

      if (iSelectedIndex === null) return;

      const oLocation = {
        latitude: this.locationModal.lat,
        longitude: this.locationModal.lng,
        locationtype: this.locationModal.type,
        range: Math.round(this.locationModal.range * 10) / 10,
        scaling: this.locationModal.scaling
      };

      const oItem = {
        ...this.artworksSelected[iSelectedIndex],
        ...oLocation,
        address_saved: {
          ...this.artworksSelected[iSelectedIndex].address_saved,
          ...oLocation
        }
      };

      if (oItem.latitude === null || oItem.longitude === null) return;

      // Save changes
      this.artworksSelected[iSelectedIndex] = oItem;

      // Update artworks in exhibition
      await this.updateExhibitionArtworks();

      this.modalClosed = true;
    },

    inheritCoordinates(oEvent) {
      const sValue = oEvent.target.value;

      if (!sValue) return;

      this.locationModal = {
        ...this.locationModal,
        ...(JSON.parse(sValue) || {})
      };

      this.createNewPositionMarker(this.locationModal.lat, this.locationModal.lng, this.getTypeColor(this.locationModal.type));
      this.mapBoxModal.getMap().flyTo({ center: { lat: this.locationModal.lat, lng: this.locationModal.lng }, essential: true });
    },

    createNewPositionMarker(iLat, iLng, sColor) {
      document.getElementById('map-new-position')?.remove();

      if (iLat === null || iLng === null) return;

      const oMarker = this.mapBoxModal.createMarkerSvg('map-new-position', sColor);
      this.mapBoxModal.addGeoLocation(iLat, iLng, oMarker);
    },

    getTypeColor(sType) {
      return sType === 'indoor' ? '#009ee3' : sType === 'outdoor' ? '#69b500' : '#a8a8a8';
    },

    setLocationType(sType) {
      this.locationModal.type = sType;

      this.createNewPositionMarker(this.locationModal.lat, this.locationModal.lng, this.getTypeColor(this.locationModal.type));
    },

    stepDecrease(sId) {
      const oInput = document.getElementById(sId);
      if (!oInput) return;
      oInput.stepDown();
      this.locationModal.range = parseFloat(oInput.value);
    },

    stepIncrease(sId) {
      const oInput = document.getElementById(sId);
      if (!oInput) return;
      oInput.stepUp();
      this.locationModal.range = parseFloat(oInput.value);
    },

    async getVimeoPreview(sId, sVimeoId, iMinWidth = 0) {
      const oReturn = {
        id: sId,
        link: null
      };
      if (!sVimeoId) return oReturn;

      const oResponse = await axios({
        method: 'get',
        url: `${process.env.VUE_APP_ARTISTS_API}/videos/${sVimeoId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      oReturn.link = (oResponse?.data?.pictures?.sizes || [])
        .filter(oSize => oSize.width > iMinWidth)
        .sort((a, b) => a.width - b.width)
        .shift()?.link;

      return oReturn;
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";
@import "./../scss/_keyframes.scss";
@import "./../scss/views/_exhibition.scss";
</style>

<style lang="scss">
@import "./../../node_modules/swiper/swiper.scss";
@import "./../scss/_mixins.scss";
@import "./../scss/_variables.scss";
@import "./../scss/_mapboxgl.scss";

#exhibitions {
  .mapboxgl-ctrl-geocoder {
    display: none;
  }

  .mapboxgl-ctrl-group button {
    background-color: $color-1;
    transition: all 0.15s ease-in-out;
  }

  .mapboxgl-ctrl button:not(:disabled):hover {
    background-color: darken($color-1, 5%);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E %3C/svg%3E");
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E %3C/svg%3E");
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E %3Cpath id='south' d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E %3C/svg%3E");
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' display='none'/%3E %3C/svg%3E");
  }
}

.swiper-container,
.swiper-wrapper {
  overflow: visible;
}

$marker-id-width: 1.5rem;
$marker-spacing: 0.25rem;
$marker-horizontal-count: 5;

.marker-anchor {
  position: absolute;
}

.marker-custom {
  svg {
    margin-top: -1rem !important;
  }
}

.id-container {
  position: absolute;
  top: -1rem;
  left: calc(100% + 0.25rem);
  display: grid;
  grid-template-columns: repeat($marker-horizontal-count, $marker-id-width);
  column-gap: $marker-spacing;
  row-gap: $marker-spacing;
  flex-wrap: wrap;
  width: #{$marker-id-width * $marker-horizontal-count + $marker-spacing *
    ($marker-horizontal-count - 1)};
}

.id-element {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-4;
  background-color: #000;
  border-radius: 0.25rem;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
  width: $marker-id-width;
  height: 1rem;
}

.border-black {
  border: 0.0625rem solid rgba(0, 0, 0, 0.25);
}
</style>
