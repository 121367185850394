<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="!isLoading">
      <form id="form-profile" class="form-container-grid-2" @submit.prevent="submit">
        <div class="form-element box allow-overflow" style="z-index:1">
          <div class="form-row relative">
            <div class="avatar" v-if="form.avatar">
              <div class="delete-file-button" @click="fileDelete()"><Icon name="delete" class="inline-svg" /></div>
              <img :src="`https://arxafrica.net/assets/${form.avatar}/avatar`" draggable="false">
            </div>
            <div v-else class="avatar upload" @dragover="onImageDragover" @dragleave="onImageDragleave" @drop="onImageDrop">
              <label for="file" v-html="text.profile_choose_file"></label>
              <input type="file" id="file" class="file-input" ref="file" :accept="fileTypes" v-on:change="handleFileUpload()">
            </div>
            <div class="loader-wrapper" v-if="fileData.isUploading">
              <Loader position="relative" :progress="fileData.progress"/>
            </div>
          </div>
          <div class="form-row">
            <label class="form-label" for="first_name">{{ text.profile_first_name }}:</label>
            <input class="form-field" type="text" id ="first_name" name="first_name" v-model="form.first_name">
          </div>
          <div class="form-row">
            <label class="form-label" for="last_name">{{ text.profile_last_name }}:</label>
            <input class="form-field" type="text" id="last_name" name="last_name" v-model="form.last_name">
          </div>
          <div class="form-row">
            <label class="form-label" for="birthday">{{ text.profile_birth_year }}:</label>
            <input class="form-field" type="number" id="birthday" name="birthday" v-model="form.birthday" :min="yearMin" :max="yearMax" @blur="triggerOnchange($event)">
          </div>
          <div class="form-row">
            <label class="form-label" for="country">{{ text.profile_country }}:</label>
            <div id="country-list" class="multiselect-custom-list form-field no-padding">
              <div class="multiselect custom">
                <div class="multiselect__tags" @click="multiselectClickEvent">
                  <div class="multiselect__tags-wrap">
                    <span class="multiselect__tag" draggable="true" data-has-events="false" :data-country-id="selection.id" v-for="selection, index in countriesSelected" :key="index">
                      <span>{{ selection.name }}</span>&nbsp;<i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"></i>
                    </span>
                  </div>
                </div>
              </div>
              <multiselect id="country" class="hide-tags" v-model="countriesSelected" select-label="Add" deselect-label="Remove" track-by="name" label="name" placeholder="" :options="countries" groupValues="values" groupLabel="label" :searchable="false" :allow-empty="true" :multiple="true" :close-on-select="false" @select="onCountrySelectionChange">
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
            </div>
          </div>
          <div class="form-row">
            <label class="form-label" for="description">{{ text.profile_about }}:</label>
            <quill-editor
              id="description"
              class="form-field"
              :class="{ 'chars-exceeded': form.description ? maxTextLength < form.description.length : false }"
              v-model="form.description"
              :options="editorOption"
              :data-chars-left="maxTextLength - Math.max(0, form.description ? form.description.length : 0)"
              :data-chars-exceeded-text="text.maximum_text_size_exceeded"
              ></quill-editor>
          </div>
        </div>
        <div class="form-element box" :style="{ animationDelay: '.25s'}">
          <div class="form-row" v-for="sType in social_links" :key="sType">
            <label class="form-label" :for="sType">{{ text[`profile_${sType}`] }}:</label>
            <div class="form-flex open-external" :class="{ 'show-link': showLink[sType] }">
              <input class="form-field form-flex-main" type="text" :id="sType" :name="sType" @keyup="onInputChange" :class="{ 'label-check-failed': !check[sType] }" v-model.trim="form.social_links[sType]">
              <a v-if="showLink[sType]" :href="form.social_links[sType]" target="_blank" rel="noopener noreferrer"><Icon name="openexternal" /></a>
            </div>
          </div>
          <button class="btn" type="submit">{{ text.profile_save }}</button>
        </div>
      </form>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnRunSimultaneously, fnWait, fnSetPageInformations, fnUploadFile, fnDeleteFiles } from '@/modules/globalFunctions.js';
import { quillEditor } from 'vue-quill-editor';
import Quill from 'quill';
import Multiselect from 'vue-multiselect';
import Loader from "@/components/Loader.vue";
import MessageBox from '@/components/MessageBox.vue';
import Icon from '@/components/Icon.vue';
import $ from 'jquery';

const oTextTemplate = {
  profile_choose_file: '',
  profile_first_name: '',
  profile_last_name: '',
  profile_birth_year: '',
  profile_country: '',
  profile_about: '',
  profile_facebook: '',
  profile_twitter: '',
  profile_instagram: '',
  profile_youtube: '',
  profile_vimeo: '',
  profile_tiktok: '',
  profile_website: '',
  profile_save: '',
  profile_url_pattern_invalid: '',
  profile_url_pattern_invalid_start: '',
  profile_update_success: '',
  profile_image_upload_success: '',
  profile_image_delete_success: '',
  profile_country_label_others: '',
  profile_country_label_afrika: '',
  maximum_text_size_exceeded: '',
  internal_error: ''
};

const Delta = Quill.import('delta');

export default {
  name: 'Profile',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    Multiselect,
    quillEditor,
    Loader,
    MessageBox,
    Icon
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_profile',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      user_id: undefined,
      countries: [],
      countriesSelected: [],
      countryDragDrop: {
        drag: 0,
        dragover: 0
      },
      social_links: [
        'facebook',
        'twitter',
        'instagram',
        'youtube',
        'vimeo',
        'tiktok',
        'website'
      ],
      form: {
        avatar: undefined,
        first_name: '',
        last_name: '',
        birthday: '',
        description: '',
        social_links: {},
      },
      formBefore: {},
      compareFields: [
        { key: 'first_name', json: false },
        { key: 'last_name', json: false },
        { key: 'birthday', json: false },
        { key: 'description', json: false },
        { key: 'social_links', json: true },
        { key: 'countries', json: true }
      ],
      fileData: {
        isUploading: false,
        file: undefined,
        fileType: undefined,
        progress: 0
      },
      message: oMessageBoxDataTemplate,
      check: {},
      showLink: {},
      yearMin: 1900,
      yearMax: 0,
      // quill-editor
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
          ],
          clipboard: {
            matchers: [
              [Node.ELEMENT_NODE, (node, delta) => delta.compose(new Delta().retain(delta.length(), {
                color: false,
                background: false,
                bold: false,
                strike: false,
                underline: false
              }))]
            ]
          }
        },
        placeholder: ''
      },
      fileTypeList: ['jpg', 'jpeg', 'png', 'gif'],
      fileTypes: '',
      // Pattern list, attribute name === this.form.social_links element
      socialPattern: {
        facebook: { label: 'Facebook', pattern: new RegExp(/(?:(?:http|https):\/\/)(?:www.)?facebook.[a-z]{1,3}\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/) },
        twitter: { label: 'Twitter', pattern: new RegExp(/(?:(?:http|https):\/\/)(?:www.)?twitter.[a-z]{1,3}\/[a-z0-9_]*/) },
        instagram: { label: 'Instagram', pattern: new RegExp(/(?:(?:http|https):\/\/)(?:www.)?instagram.[a-z]{1,3}\/[a-z0-9_]*/) },
        youtube: { label: 'YouTube', pattern: new RegExp(/((?:https?:)?\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?/) },
        vimeo: { label: 'Vimeo', pattern: new RegExp(/(?:(?:http|https):\/\/)(?:www.)?(?:player\.)?vimeo\.com\/\w+(?:\/w+)?/) }, // Old: /(?:(?:http|https):\/\/)(?:www.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[a-z0-9]*)\/videos\/|video\/|)(?:\d+)(?:|\/\?)/
        tiktok: { label: 'TikTok', pattern: new RegExp(/(?:(?:http|https):\/\/)(?:www.)?tiktok.[a-zA-Z]{1,3}\/@[a-z0-9._]*\w+[0-9]*(?:(?:[?&][a-z0-9_]*=[a-zA-Z0-9_-]*)*)?/) }, // Old: /(?:(?:http|https):\/\/)(?:www.)?tiktok.[a-z]{1,3}\/@[a-z0-9._]*\/video\/[0-9]*(?:(?:[?&][a-z0-9_]*=[a-z0-9_]*)*)?/
        website: { label: 'Website', pattern: new RegExp(/(?:(?:http|https):\/\/)(?:www.)?.[^\s]*$/) }
      },
      maxTextLength: 5000
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapGetters({ User: 'StateUser', Token: 'StateToken' })
  },
  methods: {
    /**
     * Initialization function
     */
    async init() {
      const _this = this;

      this.isLoading = true;

      this.fileTypes = this.fileTypeList.map(sFileType => `.${sFileType}`).join(', ');

      this.social_links.forEach(sKey => {
        this.check[sKey] = true;
        this.showLink[sKey] = false;
      });

      await fnRunSimultaneously([
        async () => await fnSetPageInformations(_this, oTextTemplate),
        this.getCountries // Get countries list
      ], `${this.metaData.page} | PageInformations, Countries, Profile`);

      await fnRunSimultaneously([
        this.getProfile // Get profile info, countries need to be loaded before
      ], `${this.metaData.page} | Profile`);

      // Save initial form content to determine if content needs to be saved or not
      this.compareFields
        .filter(oData => this.form[oData.key] !== undefined)
        .forEach(oData => this.formBefore[oData.key] = oData.json ? JSON.stringify(this.form[oData.key]) : this.form[oData.key]);
      this.formBefore.countries = JSON.stringify(this.normalizeCountriesObject(this.countriesSelected));

      // Show "test link" button for correct social links
      this.social_links.forEach(sKey => this.showLink[sKey] = this.checkSocialPatternSingle(sKey));

      this.yearMax = new Date().getFullYear();

      this.isLoading = false;
    },


    multiselectClickEvent(oEvent) {
      const oTarget = oEvent.target;
      if ([...oTarget.classList].indexOf('multiselect__tags') === -1) return;

      const oContainer = oTarget.parentElement.parentElement.parentElement;
      const oButton = oContainer.querySelector('.multiselect__select');

      if (document.createEvent) oButton.dispatchEvent(new Event('mousedown'));
      else oButton.fireEvent('onmousedown', oEvent);
    },


    /**
     * Get profile informations from Directus.
     * Data is bound to form elements
     */
    async getProfile() {
      const oResponse = await axios.get(`${this.url}/users/me`, {
        headers: {
          Authorization: `Bearer ${this.Token}`
        }
      });

      if (oResponse?.data?.data) {
        const oData = oResponse.data.data;

        if (oData?.id === undefined) console.error('ID is not defined');

        this.user_id = oData.id;

        const oSocialLinks = {};
        this.social_links.forEach(sKey => oSocialLinks[sKey] = '');

        this.form = {
          ...this.form,
          avatar: oData.avatar || null,
          first_name: oData.first_name,
          last_name: oData.last_name,
          birthday: oData.birthday,
          description: oData.description,
          social_links: {
            ...oSocialLinks,
            ...oData.social_links
          }
        };

        this.countriesSelected = (oData.countries || []).map(oDataCountry => this.countriesList.filter(oCountry => oCountry?.id === oDataCountry?.id).shift());
      }
    },


    /**
     * Get a list of selectable countries
     */
    async getCountries() {
      const oResponse = await axios.get(`${this.url}/items/countries?limit=-1`, {
        headers: {
          'Authorization': `Bearer ${this.Token}`
        }
      });

      this.countriesList = (oResponse?.data?.data || []);

      // Prepare county groups
      const oCountryGroups = {};
      this.countriesList.forEach(oCountry => {
        if (oCountryGroups[oCountry.group]) {
          // Append country to group entity
          oCountryGroups[oCountry.group].values?.push(oCountry);
        } else {
          // Create object entity
          oCountryGroups[oCountry.group] = {
            label: this.text[`profile_country_label_${oCountry.group}`] || '',
            values: [oCountry]
          };
        }
      });

      // Merge country groups into usable array
      this.countries = Object.keys(oCountryGroups).map(sGroupKey => oCountryGroups[sGroupKey]);
    },


    /**
     * Check given social platform URLs on regex pattern.
     * @returns {{messages: string[], success: boolean}}
     */
    checkSocialPattern() {
      const oReturn = {
        messages: [],
        success: true,
        correctLinks: {}
      };

      Object.keys(this.socialPattern).forEach(sKey => oReturn.correctLinks[sKey] = '');

      // Check every social limk
      Object.keys(this.form.social_links).forEach(sKey => {
        // Regex doesn't exist
        if (!this.socialPattern[sKey]) {
          // console.log(`${sKey} is missing and won't get checked!`);
          return;
        }

        const sSocial = this.form.social_links[sKey];
        if (!sSocial.length) return;

        const bCheckSuccessful = this.socialPattern[sKey].pattern.test(sSocial) !== false;

        // Add error message
        if (!bCheckSuccessful) {
          this.check[sKey] = false;

          const aMsg = [`${this.socialPattern[sKey].label}: ${this.text.profile_url_pattern_invalid}`];

          // Check for missing parts
          const oHttpsWww = new RegExp(/^(?:(?:http|https):\/\/)(?:www.)?/);
          const bCorrectStart = oHttpsWww.test(sSocial) !== false;

          if (!bCorrectStart) aMsg.push(`${this.socialPattern[sKey].label}: ${this.text.profile_url_pattern_invalid_start}`);

          oReturn.messages.push(...aMsg);
          oReturn.success = false;
        } else {
          oReturn.correctLinks[sKey] = this.form.social_links[sKey];
        }
      });

      return oReturn;
    },


    checkSocialPatternSingle(sType) {
      const sSocial = this.form.social_links[sType];
      if (!sSocial) return false;

      const oHttpsWww = new RegExp(/^(?:(?:http|https):\/\/)(?:www.)?/);
      const bHttps = oHttpsWww.test(sSocial) !== false;

      return bHttps && this.socialPattern[sType] && this.socialPattern[sType].pattern.test(sSocial);
    },


    /**
     * Reset error state on input change
     *
     * @param oEvent
     * @returns {void}
     */
    onInputChange(oEvent) {
      const oTarget = oEvent.target;
      const sKey = oTarget.getAttribute('name') || '';

      if (!sKey) return;

      // Reset error state on input change
      this.check[sKey] = true;
      this.showLink[sKey] = this.checkSocialPatternSingle(sKey);
    },


    /**
     * Get element index by data attribute data-country-id
     *
     * @param {JQuery} $elem JQuery element
     * @returns {numer} Element ID
     */
    getElementIdByCountryId($elem) {
      const sId = $elem.attr('data-country-id');
      const $items = [...$('#country-list').find('.multiselect__tag')].map(oElem => $(oElem));
      const iItems = $items.length;

      let iId = undefined;
      for (let i = 0; i < iItems; i++) if ($items[i].attr('data-country-id') === sId) {
        iId = i;
        break;
      }
      return iId;
    },


    /**
     * Drag start event.
     * Used for manual country sorting in multiselect
     *
     * @param oEvent
     */
    onCountryDrag(oEvent) {
      const $elem = $(oEvent.target);
      const $target = $elem.attr('draggable') !== undefined ? $elem : $elem.parents('[draggable]');
      this.countryDragDrop.drag = this.getElementIdByCountryId($target);
    },


    /**
     * Drag over event.
     * Used for manual country sorting in multiselect
     *
     * @param oEvent
     */
    onCountryDragOver(oEvent) {
      oEvent.preventDefault();
      const $elem = $(oEvent.target);
      const $target = $elem.attr('draggable') !== undefined ? $elem : $elem.parents('[draggable]');
      this.countryDragDrop.dragover = this.getElementIdByCountryId($target);
    },


    /**
     * Drag end event.
     * Used for manual country sorting in multiselect
     *
     * @param oEvent
     */
    onCountryDrop() {
      // Validate drag event order
      if (this.countryDragDrop.drag === null || this.countryDragDrop.dragover === null) return;

      const aDraggedItem = this.countriesSelected.splice(this.countryDragDrop.drag, 1);
      this.countriesSelected.splice(this.countryDragDrop.dragover, 0, ...aDraggedItem);

      // Reset drag & drop IDs to avoid function being called for each element
      this.countryDragDrop = {
        ...this.countryDragDrop,
        drag: null,
        dragover: null,
      };
    },


    /**
     * Remove a country from multiselect
     * @param oEvent
     */
    onCountryRemove(oEvent) {
      const $target = $(oEvent.target).parents('[draggable]');
      const iId = this.getElementIdByCountryId($target);
      this.countriesSelected.splice(iId, 1);
    },


    /**
     * Apply drag&drop events to newly selected items in multiselect
     */
    async onCountrySelectionChange() {
      const _this = this;

      // Delay event binding. Otherwise elements will not be rendered before event binding
      await fnWait(1);

      // Enable drag & drop sorting
      const $tagWrapper = $('#country-list');
      [...$tagWrapper.find('.multiselect__tag')]
        .map(oTag => $(oTag))
        .filter($tag => !$tag.attr('data-has-events="false"'))
        .forEach($tag => {
          $tag.on('drag', _this.onCountryDrag);
          $tag.on('dragover', _this.onCountryDragOver);
          $tag.on('drop', _this.onCountryDrop);
          $tag.find('.multiselect__tag-icon').on('click', _this.onCountryRemove);
          $tag.removeAttr('data-has-events');
        });
    },


    normalizeCountriesObject(oCountries) {
      return JSON.stringify(oCountries.map(oCountry => ({ id: oCountry?.id, code: oCountry.code })));
    },


    /**
     * Update profile informations
     *
     * @returns {void}
     */
    async submit() {
      const {
        messages: aCheckError,
        correctLinks: oCorrectSocialData
      } = this.checkSocialPattern();

      // Trim empty HTML lines
      if (this.form.description) this.form.description = this.form.description.replace(/(^(?:<p><br><\/p>)*|(?:<p><br><\/p>)*$)/img, '');

      const oData = {
        ...this.form,
        social_links: oCorrectSocialData || this.form.social_links || {},
        countries: this.normalizeCountriesObject(this.countriesSelected)
      };

      delete oData.avatar;
      const aCompareKeys = this.compareFields.map(oData => oData.key);
      Object.keys(oData).forEach(sKey => {
        const iCompareIndex = aCompareKeys.indexOf(sKey);
        if (iCompareIndex === -1) return;

        const sContentNew = this.compareFields[iCompareIndex].json ? JSON.stringify(oData[sKey]) : oData[sKey];
        const sContentOld = this.formBefore[sKey];
        if (sContentNew === sContentOld) delete oData[sKey];
      });

      // Check content max length
      [
        { key: 'description', label: this.text.profile_about }
      ]
        .filter(oField => oData[oField.key] !== undefined)
        .forEach(oField => {
          if (oData[oField.key].length <= this.maxTextLength) return;

          aCheckError.push(`${oField.label}: ${this.text.maximum_text_size_exceeded}`);
          delete oData[oField.key];
        });

      try {
        if (Object.keys(oData).length) await axios({
          method: 'patch',
          headers: {
            'Authorization': `Bearer ${this.Token}`,
            'Content-Type': 'application/json'
          },
          url: `${this.url}/users/${this.user_id}`,
          data: oData
        });

        if (!aCheckError.length) this.$refs.messageBox.showMessage('success', this.text.profile_update_success, 1000);
      } catch (error) {
        this.$refs.messageBox.showMessage('error', this.text.internal_error);
      }

      if (aCheckError.length) {
        const sErrorMessage = aCheckError
          .map(sMsg => `- ${sMsg}`)
          .join('</p><p>');

        this.$refs.messageBox.showMessage('error', sErrorMessage);
      }
    },


    /**
     * Validate file and trigger file upload
     */
    async handleFileUpload() {
      const _this = this;
      const aAllowedTypes = ['gif', 'jpeg', 'jpg', 'png'];
      const oFile = this.$refs.file.files[0];
      const sFileExtension = oFile?.name?.split('.').pop();

      /**
       * @returns {string|undefined} Avatar ID, delivered by this.fileUpload()
       */
      const fnUploader = () => {
        if (_this.fileData.fileType !== 'image') return;

        const formData = new FormData();
        formData.append('tags', _this.fileData.fileType);
        formData.append('file', _this.fileData.file);

        return _this.fileUpload(formData);
      };

      // Check file type
      if (aAllowedTypes.indexOf(sFileExtension.toLowerCase()) !== -1) {
        this.fileData = {
          ...this.fileData,
          file: oFile,
          fileType: 'image'
        };

        const sAvatar = await fnUploader();
        await this.updateImage(sAvatar, true);

        this.$refs.messageBox.showMessage('success', this.text.profile_image_upload_success, 1000);
      }
    },


    /**
     * Upload an image
     *
     * @param {FormData} oFormData Form data containing tags, file
     * @returns {string} Avatar ID
     */
    async fileUpload(oFormData) {
      this.fileData.isUploading = true;
      this.fileData.progress = 0;

      const oResponse = await fnUploadFile(oFormData, this.url, this.Token, oEvent => this.fileData.progress = Math.round((oEvent.loaded * 100) / oEvent.total));

      this.fileData.isUploading = false;
      if (oResponse?.status === 200) this.form.avatar = oResponse.data?.data?.id || '';

      return this.form.avatar;
    },


    /**
     * Delete user avatar
     */
    async fileDelete() {
      const sImageName = this.form.avatar;
      let bSkipDelete = false;
      let oResponse = undefined;

      // Delete file if image name is filled
      !sImageName
        ? bSkipDelete = true
        : oResponse = await fnDeleteFiles([sImageName], this.url, this.Token);

      if (oResponse?.status === 200 || bSkipDelete) {

        // Clear displayed image
        this.form.avatar = null;

        // Remove image path from user profile
        await this.updateImage(null);
      }
    },


    /**
     * Save image changes
     *
     * @param {string|null} sAvatar Image name
     * @param {boolean} bSuppressMessage Hide message output
     */
    async updateImage(sAvatar, bSuppressMessage = false) {
      try {
        await axios({
          method: 'patch',
          headers: {
            'Authorization': `Bearer ${this.Token}`,
            'Content-Type': 'application/json'
          },
          url: `${this.url}/users/${this.user_id}`,
          data: { avatar: sAvatar }
        });

        if (!bSuppressMessage) this.$refs.messageBox.showMessage('success', this.text.profile_image_delete_success, 1000);
      } catch (error) {
        if (!bSuppressMessage) this.$refs.messageBox.showMessage('error', this.text.internal_error);
      }
    },


    /**
     * Style changes while dragging a file over the drop zone
     *
     * @param oEvent
     */
    onImageDragover(oEvent) {
      oEvent.preventDefault();
      const oTarget = oEvent.currentTarget;
      if (!oTarget.classList.contains('drag-over')) oTarget.classList.add('drag-over');
    },


    /**
     * Style changes while dragging a file over the drop zone
     *
     * @param oEvent
     */
    onImageDragleave(oEvent) {
      oEvent.currentTarget.classList.remove('drag-over');
    },


    /**
     * Trigger file upload when a file is dropped into the drop zone
     *
     * @param oEvent
     */
    onImageDrop(oEvent) {
      oEvent.preventDefault();

      this.$refs.file.files = oEvent.dataTransfer.files;
      this.handleFileUpload();

      oEvent.currentTarget.classList.remove('drag-over');
    },


    triggerOnchange(oEvent) {
      const $target = $(oEvent.target);
      const sMin = $target.attr('min');
      const sMax = $target.attr('max');
      const iMin = parseFloat(sMin || 0);
      const iMax = parseFloat(sMax || 0);
      const iValue = $target.val();

      if (sMin !== undefined && iValue < iMin) $target.val(iMin);
      else if (sMax !== undefined && iValue > iMax) $target.val(iMax);
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";

.form-row {
  position: relative;
}

.login-box {
  width: 50%;
  margin: 0 auto;

  .register-link {
    float: right;
  }
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.avatar {
  display: block;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  background: transparent;
  width: 50%;
  margin: 0 auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    background: linear-gradient(
      -10deg,
      rgba(233, 77, 24, 0.92) 0%,
      rgba(21, 59, 76, 0.92) 100%
    );
  }

  &.upload {
    border: 0.125rem dashed #fff;
    padding-top: 50%;

    label {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      text-align: center;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  &.drag-over {
    &::before {
      opacity: 1;
    }
  }

  img {
    min-height: 7.5rem;
  }
}

model-viewer {
  --poster-color: transparent;
}

.preview-model {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.preview-image {
  display: block;
  width: 100%;
  height: auto;
}

.open-external {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.show-link {
    input {
      padding-right: 2.5rem;
    }

    a {
      position: absolute;
      right: 0.5rem;
      z-index: 1;

      svg {
        height: auto;
        width: 1.5rem;
        filter: drop-shadow(0 0 0.25rem transparentize(#000, 0.5));
      }
    }
  }
}
</style>

<style lang="scss">
@import "./../../node_modules/quill/dist/quill.core.css";
@import "./../../node_modules/quill/dist/quill.snow.css";
@import "./../../node_modules/quill/dist/quill.bubble.css";
@import "./../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "./../scss/_form.scss";
@import "./../scss/_box.scss";
@import "./../scss/_quill.scss";
@import "./../scss/_multiselect.scss";
</style>
