<template>
  <main>
    <header class="menu-spacer"></header>

    <article v-if="profileIncomplete && !IsSupporter">
      <section class="form-container box box-large profile-completion-container">
          <div v-html="text.profile_missing_informations"></div>
          <div>
            <router-link :to="`/${this.$route.meta.language}/profile`" class="btn">{{ text.profile_missing_informations_btn }}</router-link>
          </div>
      </section>
    </article>

    <article v-if="!isLoading" id="posts">
      <div v-if="IsSupporter" class="box dashboard-card filters">
        <form v-if="Posts" @submit.prevent="filterPosts">
          <div class="form-row">
            <label class="form-label" for="user">Show user</label>
            <select class="form-field" id="user" v-model="filter.user" @change="filterPosts">
              <option value=""></option>
              <option v-for="user in userList" :value="user.id" :key="user.id">{{ user.name }}</option>
            </select>
          </div>
          <div class="form-row">
            <label class="form-label" for="search">Search</label>
            <input class="form-field" type="text" id="search" name="search" v-model="filter.search" placeholder="Search" @keyup="filterPosts">
          </div>
          <div class="form-row form-row-checkbox">
            <input class="form-checkbox" id="modelmissing" type="checkbox" name="modelmissing" v-model="filter.modelmissing" @change="filterPosts">
            <label class="form-label form-checkbox-label" for="modelmissing">Show missing models only</label>
          </div>

          <div class="form-double-col-flex">
            <button class="btn" type="submit" @click="filterPosts">Search</button>
            <button class="btn" type="reset" @click="resetFilter">Reset</button>
          </div>
        </form>
      </div>
      <div v-else class="box dashboard-card new-entry">
        <form v-if="Posts" @submit.prevent="submit">
          <label class="form-label" for="title">{{ text.posts_new_artwork }}</label>
          <input class="form-field" type="text" id="title" name="title" v-model="form.title" :placeholder="text.posts_artwork_title">
          <button class="btn" type="submit" :disabled="!form.title">{{ text.posts_submit_artwork }}</button>
        </form>
        <div v-else v-html="text.posts_no_posts_error"></div>
      </div>
      <draggable class="list-group" tag="ul" group="artwork-group"  @change="reorder" :list="postsReordered" :animation="200" handle=".handle">
        <li class="js-init-draggable dashboard-card box" v-for="post, index in postsReordered" :key="post.id" @click="edit(post)" :style="{ animationDelay: `${(index + 1) * animationDuration}s`}">
          <VideoThumb v-if="post.vimeo_id" :id="post.vimeo_id"/>
          <div class="preview" v-if="post.directus_files && post.directus_files.length > 0">
            <div v-for="file in post.directus_files" :key="file.id">
              <img class="preview-image" v-if="file.tags == 'image' || file.tags == 'preview'" :src="`https://api.arxafrica.net/assets/${file.id}?key=thumbnail`" draggable="false">
            </div>
          </div>
          <div v-if="(!post.directus_files || post.directus_files.length == 0) && !post.vimeo_id" class="preview"></div>
          <div v-if="post.status == 'draft'" class="private"><Icon name="private" /></div>
          <div v-if="!IsSupporter" class="handle"><Icon name="handle" /></div>
          <div v-if="IsSupporter && (!hasGlb(post.directus_files) || !hasUsdz(post.directus_files))" class="models-incomplete"><Icon name="missingmodel" /></div>
          <div v-if="IsSupporter && post.user_created" class="title">{{ post.title }}<br/><br/>{{ post.user_created.first_name }} {{ post.user_created.first_name }}</div>
          <div v-else class="title">{{ post.title }}</div>
          <div class="likes"><Icon name="heart" class="inline-svg" />{{ post.likes }}</div>
        </li>
      </draggable>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { oMetaDataTemplate, fnRunSimultaneously, fnInitDraggables, fnWait, fnSetPageInformations, fnGetUrlParams, fnFilterLikes } from '@/modules/globalFunctions.js';
import draggable from 'vuedraggable';
import VideoThumb from '@/components/VimeoThumb.vue';
import Icon from '@/components/Icon.vue';
import Loader from "@/components/Loader.vue";

const oTextTemplate = {
  posts_new_artwork: '',
  posts_artwork_title: '',
  posts_submit_artwork: '',
  posts_no_posts_error: '',
  profile_missing_informations: '',
  profile_missing_informations_btn: ''
};

export default {
  name: 'Posts',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    draggable,
    VideoThumb,
    Icon,
    Loader
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_posts',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      form: {
        title: ''
      },
      animationDuration: 0.125,
      postsReordered: [],
      profileIncomplete: false,
      userList: [],
      filter: {
        user: '',
        search: '',
        modelmissing: false
      },

      allLikes: []
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapGetters({ IsExhibitor: 'isExhibitor', IsSupporter: 'isSupporter', Posts: 'StatePosts', User: 'StateUser', Token: 'StateToken', Likes: 'StateLikes' }),
  },
  methods: {
    ...mapActions(['CreatePost', 'GetPosts', 'UpdatePost', 'SetPost', 'GetLikes']),

    /**
     * Initialization function
     */
    async init() {
      // Workaround for wrong routing component directly after changing the user
      if (this.IsExhibitor) {
        location.reload();
        return;
      }

      this.isLoading = true;

      await fnSetPageInformations(this, oTextTemplate);

      await fnRunSimultaneously([
        // Get Posts
        async () => await this.GetPosts(),
        async () => await this.GetLikes(),
        async () => this.profileIncomplete = await this.getProfileCompletion()
      ], `${this.metaData.page} | Posts`);
      this.allLikes = this.Likes || [];
      this.postsReordered = (this.Posts || []).map(oArtwork => {
        oArtwork.likes = fnFilterLikes(this.allLikes, null, oArtwork.id);
        return oArtwork;
      });

      // Create filter
      if (this.IsSupporter) {
        const oUsers = {};
        this.postsReordered
          .filter(oPost => oPost.user_created?.id && (oPost.user_created?.first_name || oPost.user_created?.last_name))
          .forEach(oPost => {
            if (oUsers[oPost.user_created.id]) return;
            const sName = [oPost.user_created?.first_name, oPost.user_created?.last_name].join(' ');
            oUsers[oPost.user_created.id] = sName;
          });

        const aUserList = Object.keys(oUsers).map(sId => ({
          id: sId,
          name: oUsers[sId]
        }));

        this.userList = aUserList;

        // Use URL parameters
        const oUrlParams = fnGetUrlParams();
        let bUseFilter = false;
        Object.keys(this.filter).forEach(sKey => {
          if (!oUrlParams[sKey]) return;
          this.filter[sKey] = oUrlParams[sKey];
          bUseFilter = true;
        });

        // Apply URL filter
        if (bUseFilter) await this.filterPosts();
      }

      this.isLoading = false;

      fnWait(2000).then(() => fnInitDraggables());
    },

    hasGlb(aFiles) {
      return !!aFiles.some(oFile => ['glb', 'gltf'].indexOf(oFile?.filename_disk && oFile?.filename_disk?.split('.').pop()) !== -1);
    },

    hasUsdz(aFiles) {
      return !!aFiles.some(oFile => ['usdz'].indexOf(oFile?.filename_disk && oFile?.filename_disk?.split('.').pop()) !== -1);
    },

    /**
     * Route to edit post
     *
     * @param {any} oPost Post data
     */
    edit(oPost) {
      this.SetPost(oPost);
      this.$router.push(`/${this.$route.meta.language}/artwork/${oPost.id}`); // go back
    },

    /**
     * Save new sort order directly after reordering items
     */
    reorder() {
      if (this.IsSupporter) return;

      // Write current sort order to directus
      Promise.all(
        this.postsReordered.map((oPost, i) => new Promise(resolve => {
          this.SetPost(oPost);
          this.UpdatePost({ sort_manual: i })
            .then(() => resolve());
        }))
      );
    },

    /**
     * Create a new post
     *
     * @returns {void}
     */
    async submit() {
      try {
        await this.CreatePost(this.form);

        if (!this.Posts.length) return;

        const oPost = this.Posts[0];
        this.SetPost(oPost);
        this.$router.push(`/${this.$route.meta.language}/artwork/${oPost.id}`);
      } catch (error) {
        throw "Sorry you can't make a post now!"
      }
    },

    /**
     * Get profile informations from Directus
     */
    async getProfileCompletion() {
      let bIncomplete = true;

      const oResponse = await axios.get(`${this.url}/users/me`, {
        headers: {
          Authorization: `Bearer ${this.Token}`
        }
      });

      if (oResponse?.data?.data) {
        const oData = oResponse.data.data;

        bIncomplete = !oData.first_name?.length
          || !oData.last_name?.length
          || !oData.birthday?.length
          || !oData.description?.length
          || !oData.countries?.length;
      }

      return bIncomplete;
    },

    async filterPosts() {
      if (!this.IsSupporter) return;

      this.postsReordered = (this.Posts || []).filter(oPost =>
        (oPost.user_created?.id && (oPost.user_created?.first_name || oPost.user_created?.last_name)) &&
        (!this.filter.user || (oPost.user_created.id === this.filter.user)) &&
        (!this.filter.search || ([oPost.user_created.first_name, oPost.user_created.last_name, oPost.title].join(' ').toLowerCase().indexOf(this.filter.search.toLowerCase()) !== -1)) &&
        (!this.filter.modelmissing || (!this.hasGlb(oPost.directus_files) || !this.hasUsdz(oPost.directus_files)))
      );
    },

    async resetFilter() {
      if (!this.IsSupporter) return;
      this.postsReordered = (this.Posts || []);
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";

$posts-gap: 2rem;
$new-entry-gap: 0.5rem;

.list-group {
  $gap: 2rem;
  $col: 1;

  display: grid;
  gap: $gap;
  grid-template-columns: repeat(
    $col,
    calc((100% - #{$gap} * (#{$col} - 1)) / #{$col})
  );
  grid-auto-rows: clamp(10rem, 18vw, 15rem);
  padding: 0;
  list-style: none;

  @include breakpoint(32rem) {
    $col: 2;
    grid-template-columns: repeat(
      $col,
      calc((100% - #{$gap} * (#{$col} - 1)) / #{$col})
    );
  }

  @include breakpoint(49rem) {
    $col: 3;
    grid-template-columns: repeat(
      $col,
      calc((100% - #{$gap} * (#{$col} - 1)) / #{$col})
    );
  }

  & > li {
    position: relative;
    padding: 0;
    cursor: pointer;

    .preview {
      display: block;
      border-radius: 0.25rem;
      overflow: hidden;
      padding-top: 60%;
      position: relative;

      img {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
      }
    }
  }
}

#posts {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  gap: $posts-gap;
  max-width: $content-max-width;
  padding: Min(10%, 1rem);
  margin: auto;
}

.new-entry,
.filters {
  width: clamp(20rem, calc((100% - 4rem) / 3), 40rem);
  max-width: 100%;
  margin: auto;

  & > form {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    gap: $new-entry-gap;
  }
}

.filters {
  .form-row {
    margin-bottom: 0.5rem;
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 0.125rem #000000);
}

.handle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: move;
  background: #ffffff;
  color: #000000;
  border-radius: 50%;

  svg {
    width: 75%;
    height: auto;
  }
}

.private {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  color: #ffffff;
  filter: drop-shadow(0 0 0.125rem #000000);

  svg {
    width: 100%;
    height: auto;
  }
}

.profile-completion-container {
  flex-direction: column;
  align-items: center;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.models-incomplete {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border: darkred;
  color: darkred;
  background-color: red;
  border-radius: 50%;
  filter: drop-shadow(0 0 0.125rem #000000);

  svg {
    width: 75%;
    height: auto;
  }
}

.likes {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 0 0.25rem;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  filter: drop-shadow(0 0 0.125rem #000000);

  svg {
    margin-right: 0.25rem;
  }
}
</style>
