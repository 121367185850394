<template>
  <footer id="footer">
    <div class="container">
      <div class="content">
        <div class="legal">
          <a :href="`https://arxafrica.net/${this.$route.meta.language}/faq`">{{ texts.faq }}</a>
          <router-link :to="`/${this.$route.meta.language}/login`">{{ texts.login }}</router-link>
          <a :href="`https://arxafrica.net/${this.$route.meta.language}/press`">{{ texts.press }}</a>
          <a :href="`https://arxafrica.net/${this.$route.meta.language}/impressum`">{{ texts.impressum }}</a>
          <a :href="`https://arxafrica.net/${this.$route.meta.language}/termsandconditions`">{{ texts.terms_and_conditions }}</a>
          <a :href="`https://arxafrica.net/${this.$route.meta.language}/dataprotection`">{{ texts.dataprotection }}</a>
        </div>
        <a class="logo" href="https://arxafrica.net/en">
          <img class="lazy" data-src="https://arxafrica.net/images/arxafrica_logo.svg" :alt="texts.arx_logo_alt_text" draggable="false" />
          <noscript>
            <img src="https://arxafrica.net/images/arxafrica_logo.svg" :alt="texts.arx_logo_alt_text" draggable="false" />
          </noscript>
        </a>
      </div>

      <div class="content">
        <div class="social">
          <a href="https://twitter.com/arxafrica">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.005 70">
              <path fill="currentColor" d="M77.376,20.409q0-.345,0-.688c0-.772,0-1.522-.049-2.284a35.842,35.842,0,0,0,7.678-7.586V8.757A35.167,35.167,0,0,1,76,11.072,17.735,17.735,0,0,0,83.77,1.294l0-.014A35.344,35.344,0,0,1,72.54,5.57,17.692,17.692,0,0,0,41.961,17.655v.011a17.956,17.956,0,0,0,.457,4.027A50.169,50.169,0,0,1,5.992,3.225a17.7,17.7,0,0,0,5.467,23.6,17.607,17.607,0,0,1-8.007-2.207v.222A17.7,17.7,0,0,0,17.638,42.165a17.67,17.67,0,0,1-7.982.3A17.707,17.707,0,0,0,26.167,54.742,35.405,35.405,0,0,1,4.221,62.3,37.431,37.431,0,0,1,0,62.059,50,50,0,0,0,26.61,70H27.5A49.931,49.931,0,0,0,77.376,20.409Z"/>
            </svg>
            Twitter
          </a>
          <a href="https://www.instagram.com/arxafrica/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M256,49.47c67.27,0,75.23,0.26,101.8,1.47c24.56,1.12,37.9,5.22,46.78,8.67 c11.76,4.57,20.15,10.03,28.97,18.84c8.82,8.82,14.28,17.21,18.84,28.97c3.45,8.88,7.55,22.22,8.67,46.78 c1.21,26.56,1.47,34.53,1.47,101.8s-0.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78c-4.57,11.76-10.03,20.15-18.84,28.97 c-8.82,8.82-17.21,14.28-28.97,18.84c-8.88,3.45-22.22,7.55-46.78,8.67c-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-0.26-101.8-1.47 c-24.56-1.12-37.9-5.22-46.78-8.67c-11.76-4.57-20.15-10.03-28.97-18.84c-8.82-8.82-14.28-17.21-18.84-28.97 c-3.45-8.88-7.55-22.22-8.67-46.78c-1.21-26.56-1.47-34.53-1.47-101.8s0.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78 c4.57-11.76,10.03-20.15,18.84-28.97c8.82-8.82,17.21-14.28,28.97-18.84c8.88-3.45,22.22-7.55,46.78-8.67 C180.77,49.73,188.73,49.47,256,49.47 M256,4.08c-68.42,0-77,0.29-103.87,1.52c-26.81,1.22-45.13,5.48-61.15,11.71 c-16.57,6.44-30.62,15.05-44.62,29.06C32.36,60.37,23.74,74.42,17.3,90.98c-6.23,16.02-10.49,34.34-11.71,61.15 C4.37,179,4.08,187.58,4.08,256c0,68.42,0.29,77,1.52,103.87c1.22,26.81,5.48,45.13,11.71,61.15 c6.44,16.57,15.05,30.62,29.06,44.62c14.01,14.01,28.05,22.62,44.62,29.06c16.02,6.23,34.34,10.49,61.15,11.71 c26.87,1.23,35.45,1.52,103.87,1.52s77-0.29,103.87-1.52c26.81-1.22,45.13-5.48,61.15-11.71c16.57-6.44,30.62-15.05,44.62-29.06 c14.01-14.01,22.62-28.05,29.06-44.62c6.23-16.02,10.49-34.34,11.71-61.15c1.23-26.87,1.52-35.45,1.52-103.87s-0.29-77-1.52-103.87 c-1.22-26.81-5.48-45.13-11.71-61.15c-6.44-16.57-15.05-30.62-29.06-44.62c-14.01-14.01-28.05-22.62-44.62-29.06 c-16.02-6.23-34.34-10.49-61.15-11.71C333,4.37,324.42,4.08,256,4.08L256,4.08z"/>
              <path fill="currentColor" d="M256,126.64c-71.45,0-129.36,57.92-129.36,129.36S184.55,385.36,256,385.36S385.36,327.45,385.36,256 S327.45,126.64,256,126.64z M256,339.97c-46.38,0-83.97-37.6-83.97-83.97s37.6-83.97,83.97-83.97c46.38,0,83.97,37.6,83.97,83.97 S302.38,339.97,256,339.97z"/>
              <circle fill="currentColor" cx="390.48" cy="121.52" r="30.23"/>
            </svg>
            Instagram
          </a>
          <a href="https://www.facebook.com/ARXAFRICA/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327 327">
              <path fill="currentColor" d="M283,0H44.1C19.7,0,0,19.7,0,44.1V283C0,307.3,19.7,327,44.1,327h117.8l0.2-116.9h-30.4 c-3.9,0-7.1-3.2-7.2-7.1l-0.1-37.7c0-4,3.2-7.2,7.2-7.2h30.3v-36.4c0-42.2,25.8-65.2,63.5-65.2h30.9c4,0,7.2,3.2,7.2,7.2v31.8 c0,4-3.2,7.2-7.2,7.2l-19,0c-20.5,0-24.5,9.7-24.5,24v31.5h45c4.3,0,7.6,3.7,7.1,8l-4.5,37.7c-0.4,3.6-3.5,6.3-7.1,6.3h-40.4 L212.9,327H283c24.3,0,44.1-19.7,44.1-44.1V44.1C327,19.7,307.3,0,283,0L283,0z M283,0"/>
            </svg>
            Facebook
          </a>
        </div>
      </div>
    </div>
    <div id="sponsors">
      <div class="container vertical">
        <p>{{ texts.supported_by }}</p>

        <div>
          <a href="https://manx.de/" aria-label="manx">
            <img class="lazy" data-src="https://arxafrica.net/images/Logo_aa-300x162.png" alt="Auswärtiges Amt" draggable="false" />
            <noscript>
              <img src="https://arxafrica.net/images/Logo_aa-300x162.png" alt="Auswärtiges Amt" draggable="false" />
            </noscript>
          </a>
          <div>
            <img class="lazy" data-src="https://arxafrica.net/images/Logo_manx-300x45.png" alt="Manxdesign GmbH" draggable="false" />
            <noscript>
              <img src="https://arxafrica.net/images/Logo_manx-300x45.png" alt="Manxdesign GmbH" draggable="false" />
            </noscript>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import App from '@/App.vue';

export default {
  extends: App,
  name: 'Footer',
  props: {
    texts: {
      type: Object,
      required: true
    }
  }
}
</script>
