import axios from 'axios';

const aFieldsExhibition = [
  '*',
  'artworks.*',
  'artworks.works_id.*',
  'artworks.works_id.directus_files.*',
  'artworks.works_id.serie.*',
  'artworks.works_id.user_created.*',
  'artworks.works_id.marker.marker',
  'artworks_preview.works_id'
];

const state = {
  user: null,
  token: null,
  refreshToken: null,
  expiresAt: null,
  role: null,
  post: null,
  posts: null,
  artworks: null,
  exhibition: null,
  exhibitions: null,
  likes: null
};

const url = process.env.VUE_APP_API_URL;
//const url = 'http://localhost:8055';

//
const getters = {
  isAuthenticated: state => !!state.user,
  isExhibitor: state => state.role === 'eaae4e04-dd0d-4254-a9ef-e833f947941c',
  isSupporter: state => state.role === '04d9c933-25f1-4adf-ac3c-5ffef9b64f2c',
  StatePost: state => state.post,
  StatePosts: state => state.posts,
  StateArtworks: state => state.artworks,
  StateExhibition: state => state.exhibition,
  StateExhibitions: state => state.exhibitions,
  StateUser: state => state.user,
  StateToken: state => state.token,
  StateRefreshToken: state => state.refreshToken,
  StateExpiresAt: state => state.expiresAt,
  StateLikes: state => state.likes
};

//
const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} form
   */
  async Register({ dispatch }, form) {
    await axios.post('register', form);

    const UserForm = new FormData();
    UserForm.append('email', form.email);
    UserForm.append('password', form.password);

    await dispatch('LogIn', UserForm);
  },


  /**
   *
   * @param {*} param0
   * @param {*} user
   */
  async LogIn({ commit }, user) {
    const jsonData = {};
    for (const [key, value] of user) jsonData[key] = value;
    const bTerms = JSON.parse(jsonData.terms || 'false');

    const oReturn = {
      needsRevalidation: false,
      suspended: false,
      registrationFinished: true,
      userExists: true
    };

    // Check users terms agreement date and suspension state
    const oResponse = await axios.post(`${process.env.VUE_APP_ARTISTS_API}/users/status`, { email: jsonData.email });
    const {
      needsRevalidation: bTermsAgreementRequired,
      userSuspended: bUserIsSuspended,
      registrationFinished: bRegistrationFinished,
      user_exists: bUserExists
    } = oResponse?.data || oReturn;

    oReturn.needsRevalidation = bTermsAgreementRequired && !bTerms;
    oReturn.suspended = bUserIsSuspended;
    oReturn.registrationFinished = bRegistrationFinished;
    oReturn.userExists = bUserExists;

    if (oReturn.needsRevalidation || oReturn.suspended || !oReturn.registrationFinished || !oReturn.userExists) return oReturn;

    // Perform login
    const oResponseLogin = await axios.post(`${url}/auth/login`, jsonData);

    const access_token = oResponseLogin?.data?.data?.access_token;
    const refresh_token = oResponseLogin?.data?.data?.refresh_token;
    const expires = oResponseLogin?.data?.data?.expires;

    await commit('setToken', {
      access_token,
      refresh_token,
      expires
    });

    // Update terms date if needed
    if (bTerms) {
      const oDate = new Date();
      const aDate = [
        oDate.getFullYear(),
        `0${oDate.getMonth() + 1}`.substr(-2),
        `0${oDate.getDate() + 1}`.substr(-2)
      ];

      await axios.patch(`${url}/users/me`, { terms_agreement: aDate.join('-') }, { headers: { Authorization: `Bearer ${this.getters.StateToken}` } });
    }

    // Token needs to be set before requesting user informations
    const oResponseMe = await axios.get(`${url}/users/me`, { headers: { Authorization: `Bearer ${this.getters.StateToken}` } });
    commit('setRole', oResponseMe?.data?.data?.role);

    await commit('setUser', user.get('email'));

    return oReturn;
  },


  async RefreshToken({ commit }) {
    const oResponseRefreshToken = await axios.post(`${url}/auth/refresh`, {
      refresh_token: this.getters.StateRefreshToken
    });

    const access_token = oResponseRefreshToken?.data?.data?.access_token;
    const refresh_token = oResponseRefreshToken?.data?.data?.refresh_token;
    const expires = oResponseRefreshToken?.data?.data?.expires;

    await commit('setToken', {
      access_token,
      refresh_token,
      expires
    });
  },


  /**
   *
   * @param {*} param0
   */
  async LogOut({ commit }) {
    commit('logout', null);
  },


  /**
   *
   * @param {*} param0
   * @param {*} post
   * @returns
   */
  async CreatePost({ dispatch }, post) {
    await axios.post(`${url}/items/works`, post, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    return await dispatch('GetPosts');
  },


  /**
   *
   * @param {*} param0
   * @param {*} id
   */
  async GetPost({ commit }, id) {
    const oResponse = await axios.get(`${url}/items/works/${id}?fields=*,directus_files.*,serie.*,marker.marker`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    // console.log(oResponse?.data?.data);

    commit('setPost', oResponse?.data?.data);
  },


  /**
   *
   * @param {*} param0
   * @param {*} post
   * @returns
   */
  async UpdatePost({ dispatch }, post) {
    await axios.patch(`${url}/items/works/${this.getters.StatePost.id}`, post, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    return await dispatch('GetPosts');
  },


  /**
   *
   * @param {*} param0
   * @param {*} id
   * @returns
   */
  async DeletePost({ dispatch }, id) {
    await axios.delete(`${url}/items/works/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    return await dispatch('GetPosts');
  },


  /**
   *
   * @param {*} param0
   * @param {*} post
   */
  SetPost({ commit }, post) {
    commit('setPost', post)
  },


  /**
   *
   * @param {*} param0
   */
  async GetPosts({ commit }) {
    const oResponse = await axios.get(`${url}/items/works?fields=*,directus_files.*,serie.*,marker.marker,user_created.id,user_created.first_name,user_created.last_name&sort=sort_manual,-date_created&limit=-1`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    // console.log(oResponse?.data?.data);

    commit('setPosts', oResponse?.data?.data);
  },


  /**
   *
   * @param {*} param0
   * @param {string|undefined} sParams Additional parameters
   */
  async GetArtworks({ commit }, sParams = undefined, sUserRole = undefined) {
    const sParameters = sParams ? `&${sParams}` : '';
    const oResponse = await axios.get(`${url}/items/works?fields=*,directus_files.*,serie.*,user_created.*,marker.marker&sort=sort_manual,-date_created${sParameters}&limit=-1`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    const aData = sUserRole
      ? oResponse?.data?.data.filter(oData => oData.user_created.role === sUserRole)
      : oResponse?.data?.data;

    // console.log(aData);

    commit('setArtworks', aData);
  },


  // -----------------------------------


  /**
   * Create a new exhibition
   * @param {*} param0
   * @param {*} exhibition
   * @returns
   */
  async CreateExhibition({ dispatch }, exhibition) {
    await axios.post(`${url}/items/exhibition`, exhibition, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    return await dispatch('GetExhibitions');
  },


  /**
   * Fetch an exhibition
   *
   * @param {*} param0
   * @param {*} id
   */
  async GetExhibition({ commit }, id) {
    const oResponse = await axios.get(`${url}/items/exhibition/${id}?fields=${aFieldsExhibition.join(',')}`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    // console.log(oResponse?.data?.data);

    commit('setExhibition', oResponse?.data?.data);
  },


  /**
   * Fetch exhibition with the ID stored in this.getters.StateExhibition.id
   *
   * @param {*} param0
   */
  async GetExhibitionCurrent({ commit }) {
    const oResponse = await axios.get(`${url}/items/exhibition/${this.getters.StateExhibition.id}?fields=*,artworks.*,artworks.works_id.*,artworks.works_id.directus_files.*,artworks.works_id.serie.*,artworks.works_id.user_created.*`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    // console.log(oResponse?.data?.data);

    commit('setExhibition', oResponse?.data?.data);
  },


  /**
   * Update exhibition entry
   *
   * @param {*} param0
   * @param {*} exhibition
   * @returns
   */
  async UpdateExhibition({ dispatch }, exhibition) {
    await axios.patch(`${url}/items/exhibition/${this.getters.StateExhibition.id}`, exhibition, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    return await dispatch('GetExhibitionCurrent');
  },


  /**
   * Remove an exhibition and fetch all exhibitions
   *
   * @param {*} param0
   * @param {*} id
   * @returns
   */
  async DeleteExhibition({ dispatch }, id) {
    await axios.delete(`${url}/items/exhibition/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    return await dispatch('GetExhibitions');
  },


  /**
   * Set current exhibition
   *
   * @param {*} param0
   * @param {*} exhibition
   */
  SetExhibition({ commit }, exhibition) {
    commit('setExhibition', exhibition)
  },


  /**
   * Get all exhibitions
   *
   * @param {*} param0
   */
  async GetExhibitions({ commit }) {
    const oResponse = await axios.get(`${url}/items/exhibition?fields=${aFieldsExhibition.join(',')},translations.*&sort=-date_created&limit=-1`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    // console.log(oResponse?.data?.data);

    commit('setExhibitions', oResponse?.data?.data);
  },

  /**
   * Get all likes
   *
   * @param {*} param0
   */
  async GetLikes({ commit }) {
    const oResponse = await axios.get(`${url}/items/likes?fields=exhibition,artwork,likes&filter[likes][_gt]=0&limit=-1`, {
      headers: {
        Authorization: `Bearer ${this.getters.StateToken}`
      }
    });

    // console.log(oResponse?.data?.data);

    commit('setLikes', oResponse?.data?.data);
  }
};

//
const mutations = {
  setUser(state, email) {
    state.user = email;
  },

  setToken(state, { access_token, refresh_token, expires }) {
    state.token = access_token;
    state.refreshToken = refresh_token;
    state.expiresAt = new Date().getTime() + expires;
  },

  setRole(state, role) {
    state.role = role;
  },

  setPost(state, post) {
    state.post = post;
  },

  setPosts(state, posts) {
    state.posts = posts;
  },

  setArtworks(state, artworks) {
    state.artworks = artworks;
  },

  setExhibition(state, exhibition) {
    state.exhibition = exhibition;
  },

  setExhibitions(state, exhibitions) {
    state.exhibitions = exhibitions;
  },

  logout(state, user) {
    state.user = user;

    // Clear all stored data
    for (const sKey in state) state[sKey] = null;
  },

  setLikes(state, likes) {
    state.likes = likes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
