import $ from 'jquery';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export class MapBox {
  sId = null;
  bUseGeoCoder = false;
  bUseZoom = false;
  bUseCaptureClick = false;
  bUseCurrentLocation = false;
  sMapStyle = null;

  oMap = null;
  oGeoLoc = {
    // Selection via geocoder
    address: {
      address: '',
      latitude: 0,
      longitude: 0
    },
    // Selection via coordinates
    latlng: {
      latitude: 0,
      longitude: 0
    },
    captureClick: false,
    tracking: false,
    locator: null,  // Current position
  };
  oGeoCoder = null;
  bCurrentPositionSelectionInProgress = false;


  /**
   * @param {string} sId
   * @param {{
   *  geoCoder: boolean,
   *  zoom: boolean,
   *  captureClick: boolean,
   *  currentLocation: boolean,
   *  sMapStyle: 'satellite'|'streets'
   * }} oParams
   * @returns
   */
  constructor(sId, oParams = {}) {
    const _this = this;

    this.sId = sId;
    this.bUseGeoCoder = oParams.geoCoder || false;
    this.bUseZoom = oParams.zoom || false;
    this.bUseCaptureClick = oParams.captureClick || false;
    this.bUseCurrentLocation = oParams.currentLocation || false;
    this.sMapStyle = oParams.sMapStyle || 'streets-v11';

    mapboxgl.accessToken = 'pk.eyJ1IjoiYXJ4YWZyaWNhIiwiYSI6ImNrcHplNHlmODAxeTMycW11b2Z3d2d5dWkifQ.xivk69q2ejEREe6PFG-PPw';

    this.oMap = new mapboxgl.Map({
      container: sId,
      style: `mapbox://styles/mapbox/${this.sMapStyle}`,
      // style: 'mapbox://styles/mapbox/streets-v11',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      // style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [10.0183, 51.1335],
      zoom: 5
    });

    if (this.bUseGeoCoder) {
      const fnCoordinatesGeocoder = function (sQuery) {
        const aMatches = sQuery.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
        if (!aMatches) return null;

        const iCoord1 = Number(aMatches[1]);
        const iCoord2 = Number(aMatches[2]);
        const aGeoCodes = [];

        const oGeoLocation = {
          longitude: 0,
          latitude: 0
        };

        const fnSetGeoCode = function (iLatitude, iLongitude) {
          try {
            aGeoCodes.push({
              center: { lat: iLatitude, lng: iLongitude },
              geometry: {
                type: 'Point',
                coordinates: { lat: iLatitude, lng: iLongitude }
              },
              place_name: 'Lat: ' + iLongitude + ' Lng: ' + iLatitude,
              place_type: ['coordinate'],
              properties: {},
              type: 'Feature'
            });
            oGeoLocation.latitude = iLatitude;
            oGeoLocation.longitude = iLongitude;
          } catch (oError) {
            console.log(oError);
          }
        };

        if (Math.abs(iCoord1) > 90) fnSetGeoCode(iCoord1, iCoord2);
        if (Math.abs(iCoord2) > 90) fnSetGeoCode(iCoord2, iCoord1);

        if (!aGeoCodes.length) {
          fnSetGeoCode(iCoord1, iCoord2);
          fnSetGeoCode(iCoord2, iCoord1);
        }

        // Write lat/lng into input fields
        this.oGeoLoc.address = {
          address: sQuery,
          ...oGeoLocation
        };

        return aGeoCodes;
      };

      // Add geo search to map
      this.oGeoCoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: fnCoordinatesGeocoder,
        zoom: 4,
        placeholder: 'Search',
        mapboxgl: mapboxgl,
        reverseGeocode: true
      });

      this.oMap.addControl(this.oGeoCoder);

      this.oMap.on('load', () => {
        // Bind event on location search
        _this.oGeoCoder.on('result', oEvent => {
          const [iLongitude, iLatitude] = oEvent?.result?.center || [null, null];

          _this.oGeoLoc.address.address = oEvent?.result?.text || '';
          _this.oGeoLoc.address.latitude = iLatitude;
          _this.oGeoLoc.address.longitude = iLongitude;
        });
      });
    }

    if (this.bUseZoom) {
      // Add zoom controls to map
      this.oMap.addControl(new mapboxgl.NavigationControl());
    }

    if (this.bUseCaptureClick) {
      this.oMap.on('click', oEvent => {
        if (!_this.oGeoLoc.captureClick) return;

        const { lng: iLongitude, lat: iLatitude } = oEvent?.lngLat || {};

        _this.oGeoLoc.latlng.latitude = iLatitude || null;
        _this.oGeoLoc.latlng.longitude = iLongitude || null;

        console.log(oEvent?.lngLat);
      });
    }

    if (this.bUseCurrentLocation) {
      // Add geolocate control to the map.
      const oGeoLocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      });

      oGeoLocate.on('error', oError => console.log(oError));
      oGeoLocate.on('trackuserlocationstart', () => _this.oGeoLoc.tracking = true);
      oGeoLocate.on('trackuserlocationend', () => _this.oGeoLoc.tracking = false);

      this.oGeoLoc.locator = oGeoLocate;

      this.oMap.addControl(oGeoLocate);
    }

    this.oMap.on('load', () => {
      // Trigger resize event
      // Needs to be delayed to affect the map element
      setTimeout(() => {
        _this.oMap.resize();
        $(window).trigger('resize');
      }, 1);
    });

    return this;
  }

  getLatLng() {
    return this.oGeoLoc.latlng;
  }


  /**
   * Set lng/lat capturing on map click
   *
   * @param {boolean} bState
   */
  setCaptureClick(bState) {
    this.oGeoLoc.captureClick = bState;
  }


  /**
   * Set new style to map
   *
   * @param {'satellite'|'streets'} sStyleName
   */
  setStyle(sStyleName) {
    if (!this.sMapStyle !== sStyleName) this.sMapStyle = sStyleName;

    let sStyle = '';
    switch (sStyleName) {
      case 'satellite':
      case 'satellite-streets-v11':
        sStyle = 'satellite-streets-v11';
        break;
      case 'streets':
      case 'streets-v11':
      default:
        sStyle = 'streets-v11';
        break;
    }

    this.oMap.setStyle(`mapbox://styles/mapbox/${sStyle}`);
  }


  /**
   * Returns the current style name
   *
   * @returns {string} Style name
   */
  getStyle() {
    return this.sMapStyle;
  }


  /**
   * @returns {HTMLElement} Marker node
   */
  createMarkerSvg(sId = null, sColor = null) {
    const oDiv = document.createElement('div');
    oDiv.className = 'marker marker-custom';
    if (sId) oDiv.setAttribute('id', sId);

    const oSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    oSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    oSvg.setAttribute('width', '22.207');
    oSvg.setAttribute('height', '32.299');
    oSvg.setAttribute('viewBox', '0 0 22.207 32.299');

    const oPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    oPath.setAttribute('fill', sColor || '#e94d18');
    oPath.setAttribute('transform', 'translate(-27.082 83.332)');
    oPath.setAttribute('d', 'M38.185-83.332a11.071,11.071,0,0,0-11.1,11.1c0,6.156,11.1,21.2,11.1,21.2s11.1-15.04,11.1-21.2A11.071,11.071,0,0,0,38.185-83.332Zm0,16.149a5,5,0,0,1-5.047-5.047,5,5,0,0,1,5.047-5.047,5,5,0,0,1,5.047,5.047A5,5,0,0,1,38.185-67.183Z');
    oSvg.appendChild(oPath);

    oDiv.appendChild(oSvg);

    return oDiv;
  }


  /**
   * Add marker on map
   *
   * @param {number} iLatitude Latitude
   * @param {number} iLongitude Longitude
   * @param {HTMLElement} oCustomMarker Custom DOM node
   * @param {boolean} bFocusNewLocation Set focus on newly set location
   */
  addGeoLocation(iLatitude, iLongitude, oCustomMarker = null, bFocusNewLocation = false) {
    // add marker to map
    new mapboxgl.Marker(oCustomMarker || this.createMarkerSvg())
      .setLngLat({ lat: iLatitude, lng: iLongitude })
      .addTo(this.oMap);

    if (bFocusNewLocation) {
      // this.oMap.setView([iLatitude, iLongitude], 13);
    }
  }


  /**
   * Returns an instance of the map
   *
   * @returns mapbox instance
   */
  getMap() {
    return this.oMap;
  }


  /**
   * Cut and paste map to another DOM node
   * @param {JQuery} $target JQuery element
   */
  moveTo($target) {
    const _this = this;
    $(`#${this.sId}`).detach().appendTo($target);

    // Trigger resize event
    // Needs to be delayed to affect the map element
    setTimeout(() => {
      _this.oMap.resize();
      $(window).trigger('resize');
    }, 1);
  }


  /**
   * Removes all set markers
   */
  removeAllMarkers() {
    $(`#${this.sId} .mapboxgl-canvas-container .mapboxgl-marker`).remove();
  }


  /**
   * Search sSearch with geolocator (mapbox)
   *
   * @param {string} sSearch Search string
   * @returns {void}
   */
  findAddress(sSearch) {
    const _this = this;

    if (!this.bUseGeoCoder || !this.oGeoCoder) {
      console.error('GeoCoder is turned off!');
      return;
    }

    if (!sSearch.trim()) sSearch = ' ';

    // Trigger search
    this.oGeoCoder.query(sSearch, (err, data) => {
      // The geocoder can return an area, like a city, or a
      // point, like an address. Here we handle both cases,
      // by fitting the map bounds to an area or zooming to a point.
      if (data.lbounds) {
        _this.oGeoLoc.map.fitBounds(data.lbounds);
      } else if (data.latlng) {
        const [iLatitude, iLongitude] = data.latlng;

        _this.oGeoLoc.address.latitude = iLatitude;
        _this.oGeoLoc.address.longitude = iLongitude;

        _this.oMap.setView({ lat: iLatitude, lng: iLongitude }, 13);
      }
    });
  }


  /**
   *
   * @param {'address'|'latlng'} sType
   * @returns {{
   *  address?: string,
   *  latitude: number,
   *  longitude: number
   * }}
   */
  getGeolocation(sType) {
    return this.oGeoLoc[sType];
  }


  /**
   * Get current position via navigator API
   *
   * @returns {Promise<{latitude:number,longitude:number}>|null} Coordinates
   */
  async getCurrentPosition() {
    if (this.bCurrentPositionSelectionInProgress) return null;

    const bIsTracking = this.oGeoLoc.tracking;

    // geolocate needs to be triggered
    if (!bIsTracking) this.oGeoLoc.locator.trigger('geolocate')

    // Shut off geolocation
    // Only one tracking function can run at a time!
    this.oGeoLoc.locator.trigger('geolocate');

    // Get current position
    this.bCurrentPositionSelectionInProgress = true;
    const oLocation = await new Promise(resolve => {
      const fnSuccess = oPosition => {
        console.log(oPosition.coords.latitude, oPosition.coords.longitude);

        resolve({
          latitude: oPosition.coords.latitude,
          longitude: oPosition.coords.longitude
        });
      };
      const fnError = oError => {
        console.log(oError);
        resolve();
      };
      const oOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(fnSuccess, fnError, oOptions);
    });
    this.bCurrentPositionSelectionInProgress = false;

    // Reset tracking to previous state
    if (bIsTracking && bIsTracking !== this.oGeoLoc.tracking) this.oGeoLoc.locator.trigger('geolocate');

    return oLocation;
  }
}
