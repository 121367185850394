<template>
  <div class="loader-wrapper">
    <div class="loader loading" :class="class_position">
      <svg xmlns="http://www.w3.org/2000/svg" width="48.843" height="48.843" viewBox="0 0 48.843 48.843">
        <g transform="translate(-3865.622 -440.579)">
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3909.560 462.548)" fill="#fff" opacity="0.30"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3865.622 462.548)" fill="#fff" opacity="0.80"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3887.591 484.517)" fill="#fff" opacity="0.60"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3887.591 440.579)" fill="#fff" opacity="1.00"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3903.125 478.082)" fill="#fff" opacity="0.40"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3872.056 447.013)" fill="#fff" opacity="0.90"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3872.056 478.082)" fill="#fff" opacity="0.70"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3903.125 447.013)" fill="#fff" opacity="0.20"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3907.887 454.140)" fill="#fff" opacity="0.25"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3867.294 470.955)" fill="#fff" opacity="0.75"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3895.998 482.844)" fill="#fff" opacity="0.55"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3879.184 442.251)" fill="#fff" opacity="0.95"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3907.887 470.955)" fill="#fff" opacity="0.35"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3867.294 454.140)" fill="#fff" opacity="0.85"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3879.184 482.844)" fill="#fff" opacity="0.65"/>
          <circle cx="2.452" cy="2.452" r="2.452" transform="translate(3895.998 442.251)" fill="#fff" opacity="0.15"/>
        </g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="18.445" height="16.14" viewBox="0 0 18.445 16.14">
        <path d="M216.146,456.834h-5.574l2.767-4.611h5.574ZM205.77,451.07l2.788-4.643,2.783,4.643-2.783,4.643Zm13.143-1.153h-5.574l-2.767-4.611h5.574Zm2.668-.035-3.459-5.764A2.307,2.307,0,0,0,216.146,443h-6.917a2.307,2.307,0,0,0-1.977,1.118l-3.459,5.764a2.306,2.306,0,0,0,0,2.372l3.459,5.764a2.3,2.3,0,0,0,1.977,1.121h6.917a2.307,2.307,0,0,0,1.977-1.118l3.459-5.764a2.306,2.306,0,0,0,0-2.372Z" transform="translate(-203.465 -443)" fill="#e94d18"/>
      </svg>
    </div>
    <div class="progressbar" v-if="pc_progress !== null">
      <div :style="{ width: pc_progress + '%' }"></div>
    </div>
    <div class="task" v-if="task !== null">{{ task }}</div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    position: {
      type: String,
      required: true
    },
    progress: {
      type: Number,
      required: false
    },
    currentTask: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      class_position: this.position,
      pc_progress: this.progress || null,
      task: this.currentTask || null
    };
  },
  watch: {
    progress() {
      this.pc_progress = this.progress;
    },
    currentTask() {
      this.task = this.currentTask;
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_variables.scss";
@import "./../scss/_mixins.scss";
@import "./../scss/_keyframes.scss";

$loader-gap: 1rem;
.loader-wrapper {
  position: absolute;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  gap: $loader-gap;
  justify-items: center;
  align-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  $loader-size: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: $loader-size;
  height: $loader-size;
  border-radius: 50%;
  background-color: #111111;
  z-index: 9;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &.relative {
    position: relative;
  }

  & > svg {
    max-width: 100%;
    height: auto;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      position: absolute;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &:nth-of-type(1) {
      $svg-size: 2.442rem;

      width: $svg-size;
      animation-duration: 1s;
      animation-name: rotate-360-cw;
    }

    &:nth-of-type(2) {
      $svg-size: 0.922rem;

      color: #e94d18;
      width: $svg-size;
      animation-duration: 1.25s;
      animation-name: rotate-360-ccw;
    }
  }

  &:not(.loading) {
    top: 100%;

    &:not(.animate) > svg {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  &[aria-hidden="true"] {
    animation-name: fade-out-flex;
  }

  &[aria-hidden="false"] {
    animation-name: fade-in-flex;
  }
}

.progressbar {
  background-color: black;
  border-radius: 0.75rem;
  padding: 0.25rem;
  width: 90%;
  z-index: 4;

  &> div {
    background-color: $color-2;
    width: 0%;
    height: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.5s linear;
  }
}

.task {
  width: 90%;
  text-align: center;
  z-index: 4;
}
</style>
