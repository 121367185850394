import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueMatomo from 'vue-matomo';

axios.interceptors.response.use(undefined, oError => {
  if (!oError) return;

  const originalRequest = oError.config;
  if (oError.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    store.dispatch('LogOut');
    return router.push('/login');
  }
});

Vue.config.productionTip = false;

/**
 * VueMeta API Reference:
 * https://vue-meta.nuxtjs.org/api/
 */
Vue.use(VueMeta, {
  keyName: 'head'
});

if (location.host !== 'localhost:8080') Vue.use(VueMatomo, {
  host: 'https://matomo.arxafrica.net/',
  siteId: 2,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
