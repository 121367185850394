<template>
  <header>
    <div id="header-container">
      <a id="header-back-to-project" :href="`https://arxafrica.net/${this.$route.meta.language}/`">
        <p>{{ texts.arxafrica_project_site }}</p>
      </a>

      <button id="menu-button" class="menu-button" aria-label="Menü" aria-expanded="false">
        <svg width="100%" height="100%" viewBox="0 0 100 100">
          <path class="line" stroke="currentColor" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
          <path class="line" stroke="currentColor" d="M 20,50 H 80"></path>
          <path class="line" stroke="currentColor" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
        </svg>
      </button>

      <div id="menu-content-overlay" data-redirect-click="menu-button">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav id="menu-content">
        <ul>
          <li>
            <div id="menu-language" class="custom-dropdown">
              <button id="menu-language-btn" type="button" aria-labelledby="menu-language-btn" aria-haspopup="listbox" aria-expanded="false">EN English</button>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path></svg>
              <ul id="menu-language-list" tabindex="-1" role="listbox" aria-labelledby="" class="hidden">
                <li :data-route="`/en/${path}${urlParams}`" id="menu-language-en" role="option" data-label="EN English">EN English</li>
                <li :data-route="`/de/${path}${urlParams}`" id="menu-language-de" role="option" data-label="DE Deutsch">DE Deutsch</li>
                <li :data-route="`/fr/${path}${urlParams}`" id="menu-language-fr" role="option" data-label="FR Français">FR Français</li>
              </ul>
            </div>
          </li>
          <li role="separator" class="separator-small"></li>

          <li v-if="isLoggedIn" :class="{ active: path == '' }"><router-link :to="`/${this.$route.meta.language}/`" class="big">{{ texts.artists_dashboard }}</router-link></li>
          <li v-if="isLoggedIn && !IsSupporter" :class="{ active: path == 'profile' }"><router-link :to="`/${this.$route.meta.language}/profile`" class="big">{{ texts.artists_profile }}</router-link></li>
          <li v-if="isLoggedIn" role="separator" class="separator-small no-desktop"></li>
          <li v-if="isLoggedIn"><a @click="logout" class="big">{{ texts.artists_logout }}</a></li>

          <li v-if="!isLoggedIn" :class="{ active: path == 'register' }"><router-link :to="`/${this.$route.meta.language}/register`" class="big">{{ texts.artists_register }}</router-link></li>
          <li v-if="!isLoggedIn" :class="{ active: path == 'login' }"><router-link :to="`/${this.$route.meta.language}/login`" class="big">{{ texts.login }}</router-link></li>

          <li role="separator" class="separator-auto no-desktop"></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.$route.meta.language}/faq`" class="small">{{ texts.faq }}</a></li>
          <li v-if="!isLoggedIn" class="no-desktop"><router-link :to="`/${this.$route.meta.language}/login`" class="small">{{ texts.login }}</router-link></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.$route.meta.language}/press`" class="small">{{ texts.press }}</a></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.$route.meta.language}/impressum`" class="small">{{ texts.impressum }}</a></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.$route.meta.language}/termsandconditions`" class="small">{{ texts.terms_and_conditions }}</a></li>
          <li class="no-desktop"><a :href="`https://arxafrica.net/${this.$route.meta.language}/dataprotection`" class="small">{{ texts.dataprotection }}</a></li>
        </ul>
      </nav>

      <a id="menu-logo" :href="`https://arxafrica.net/${this.$route.meta.language}/`">
        <img class="lazy" data-src="https://arxafrica.net/images/arxafrica_logo.svg" :alt="texts.arx_logo_alt_text" draggable="false" />
        <noscript>
          <img src="https://arxafrica.net/images/arxafrica_logo.svg" :alt="texts.arx_logo_alt_text" draggable="false" />
        </noscript>
      </a>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { fnToggleHeaderBackground } from '@/modules/globalFunctions.js';
import $ from 'jquery';

export default {
  name: 'Header',
  props: {
    texts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      path: '',
      currentRoute: '',
      urlParams: ''
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    ...mapGetters({ IsSupporter: 'isSupporter' })
  },
  mounted: function () {
    this.getCurrentRoute();

    this.urlParams = location.search;


    // TODO JS Lib
    /**
     * Set current item as dropdown label
     */
    [...$('#menu-language-list>[role="option"]')].forEach(oElem => {
      const $elem = $(oElem);
      if ($elem.attr('data-route') === this.currentRoute) $elem
        .parents('.custom-dropdown')
        .find('[aria-haspopup="listbox"]')
        .html($elem.attr('data-label') || '');
    });


    // TODO JS Lib
    /**
     * Toggle dropdown
     */
    $('.custom-dropdown button[aria-haspopup="listbox"]').on('click', oEvent => {
      const $target = $(oEvent.target);
      const $elem = $target.attr('aria-haspopup') !== 'listbox' ? $target.parents('[aria-haspopup="listbox"]') : $target;
      const bExpanded = JSON.parse($elem.attr('aria-expanded') || 'false');
      $elem.attr('aria-expanded', JSON.stringify(!bExpanded));
      $elem.parent().children('[role="listbox"]').toggleClass('hidden');
    });


    // TODO JS Lib
    /**
     * Set new value of dropdown and change app route
     */
    $('.custom-dropdown [role="option"]').on('click', oEvent => {
      const $target = $(oEvent.target);
      const $elem = $target.attr('role') !== 'option' ? $target.parents('[role="option"]') : $target;
      $elem
        .parents('.custom-dropdown')
        .find('[aria-haspopup="listbox"]')
        .html($elem.attr('data-label') || '');

      const sRoute = $elem.attr('data-route');
      if (sRoute && sRoute !== this.currentRoute) this.$router.push($elem.attr('data-route'));

      // Close dropdown
      $('.custom-dropdown button[aria-haspopup="listbox"]').trigger('click');
    });


    // TODO JS Lib
    /**
     * Close dropdowns on losing focus
     */
    $('#body *:not(.custom-dropdown)').on('click', oEvent => {
      if ($(oEvent.target).parents('.custom-dropdown').length) return;
      const $dropDown = $('.custom-dropdown');
      $dropDown.find('[aria-haspopup="listbox"]').attr('aria-expanded', 'false');
      $dropDown.find('[role="listbox"]').addClass('hidden');
    });


    // TODO JS Lib
    /**
     * Toggle header background visibility
     */
    window.addEventListener('scroll', fnToggleHeaderBackground);
    window.addEventListener('resize', fnToggleHeaderBackground);
    window.addEventListener('orientationchange', fnToggleHeaderBackground);
    fnToggleHeaderBackground();


    // TODO JS Lib
    /**
     * Toggle menu
     */
    $('.menu-button').on('click', oEvent => {
      const $target = $(oEvent.target);
      const $elem = $target.hasClass('menu-button') ? $target : $target.parents('.menu-button');
      const bIsOpened = !$elem.hasClass('opened');
      $elem.toggleClass('opened');
      $elem.attr('aria-expanded', bIsOpened.toString());
      $('body')[bIsOpened ? 'addClass' : 'removeClass']('block-scroll-s');
    });


    // TODO JS Lib
    /**
     * Redirect an action to another DOM node
     */
    $('[data-redirect-click]').on('click', oEvent => {
      var _a;
      const [sTargetId, sEvent] = ((_a = $(oEvent.target).attr('data-redirect-click')) === null || _a === void 0 ? void 0 : _a.split(':')) || [];
      if (sTargetId)
        $(`#${sTargetId}`).trigger(sEvent || oEvent.type);
    });
  },
  created() {
    this.path = this.$route.path.replaceAll(`/${this.$route.meta.language}/`, '');
  },
  methods: {
    /**
     * Perfom user logout
     */
    async logout() {
      localStorage.setItem('logout', '');   // This will show the success message
      await this.$store.dispatch('LogOut'); // Logout process
      this.$router.push(`/${this.$route.meta.language}/login`);
    },


    /**
     * Get the current router path
     */
    getCurrentRoute() {
      this.currentRoute = this.$route.path;
    }
  },
  watch: {
    $route(to) {
      this.currentRoute = to.path;
      this.path = to.path.replaceAll(`/${this.$route.meta.language}/`, '');
    }
  }
}
</script>
