<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="!isLoading">
      <form id="container" class="form-container grid-container" @submit.prevent="submit">
        <div class="form-element box">
          <div class="form-row">
            <label class="form-label">Preview image:</label>
            <p>This image will be used on the landing page of arxafrica.net.</p>
          </div>

          <div class="preview" v-if="Exhibition.preview_image">
            <div class="delete-file-button" @click="deleteImage('preview_image')"><Icon name="delete" class="inline-svg" /></div>
            <div>
              <div class="preview-image">
                <img :src="`https://arxafrica.net/assets/${Exhibition.preview_image}/preview`" draggable="false">
              </div>
            </div>
          </div>

          <div v-else>
            <p>
              <span v-html="text.exhibitionedit_text_file_formats"></span>
              <span v-for="fileType in fileTypeList" :key="fileType" class="upload-format">{{ fileType }}</span>
            </p>

            <div class="preview upload" @dragover="onImageDragover" @dragleave="onImageDragleave" @drop="onImageDrop($event, 'preview_image')">
              <label for="file" v-html="text.exhibitionedit_choose_file"></label>
              <input type="file" id="file" class="file-input" ref="preview_image" :accept="fileTypes" v-on:change="handleFileUpload($event, 'preview_image')"/>
            </div>
          </div>

          <Loader v-if="fileData.preview_image.isUploading" position="relative" :progress="fileData.preview_image.progress"/>
        </div>

        <div class="form-element box" :style="{ animationDelay: '0.25s' }">
          <div class="form-row">
            <label class="form-label">Banner image:</label>
            <p>This image will be used in the header section of the detail page.</p>
          </div>

          <div class="preview" v-if="Exhibition.banner_image">
            <div class="delete-file-button" @click="deleteImage('banner_image')"><Icon name="delete" class="inline-svg" /></div>
            <div>
              <div class="preview-image">
                <img :src="`https://arxafrica.net/assets/${Exhibition.banner_image}/preview`" draggable="false">
              </div>
            </div>
          </div>

          <div v-else>
            <p>
              <span v-html="text.exhibitionedit_text_file_formats"></span>
              <span class="upload-format">png</span>
            </p>

            <div class="preview upload" @dragover="onImageDragover" @dragleave="onImageDragleave" @drop="onImageDrop($event, 'banner_image', ['png'])">
              <label for="file" v-html="text.exhibitionedit_choose_file"></label>
              <input type="file" id="file" class="file-input" ref="banner_image" accept=".png" v-on:change="handleFileUpload($event, 'banner_image', ['png'])"/>
            </div>
          </div>

          <Loader v-if="fileData.banner_image.isUploading" position="relative" :progress="fileData.banner_image.progress"/>
        </div>
        <div class="form-element box" :style="{ animationDelay: '0.5s' }">
          <div class="form-row">
            <label class="form-label" for="status">{{ text.exhibitionedit_status }}:</label>
            <select class="form-field" id="status" v-model="form.status">
              <option v-for="item in status" :key="item.key" :value="item.key">{{ item.label }}</option>
            </select>
          </div>
          <div class="form-row">
            <label class="form-label" for="name">{{ text.exhibitionedit_name }}:</label>
            <input class="form-field" type="text" id="name" name="name" v-model="form.name" />
          </div>
          <div class="form-row">
            <label class="form-label" for="description">{{ text.exhibitionedit_description }}:</label>
            <quill-editor
              id="description"
              class="form-field"
              :class="{ 'chars-exceeded': form.description ? maxTextLength < form.description.length : false }"
              v-model="form.description"
              :options="editorOption"
              :data-chars-left="maxTextLength - Math.max(0, form.description ? form.description.length : 0)"
              :data-chars-exceeded-text="text.maximum_text_size_exceeded"
              ></quill-editor>
          </div>
          <div class="form-row">
            <label class="form-label" for="start_date">{{ text.exhibitionedit_start_date }}:</label>
            <input class="form-field" type="date" id="start_date" name="start_date" v-model="form.start_date" />
          </div>
          <div class="form-row">
            <label class="form-label" for="end_date">{{ text.exhibitionedit_end_date }}:</label>
            <input class="form-field" type="date" id="end_date" name="end_date" v-model="form.end_date" />
          </div>
          <div class="form-row">
            <label class="form-label" for="city">{{ text.exhibitionedit_city }}:</label>
            <input class="form-field" type="text" id="city" name="city" v-model="form.city" />
          </div>
          <div class="form-row">
            <label class="form-label" for="location">{{ text.exhibitionedit_location }}:</label>
            <input class="form-field" type="text" id="location" name="location" v-model="form.location" />
          </div>
        </div>

        <div class="form-element box" :style="{ animationDelay: '0.75s' }">
          <div class="form-row">
            <label class="form-label" for="text1">Text top:</label>
            <quill-editor class="form-field style-exhibition-detail" id="text1" v-model="form.text1" :options="editorOption"></quill-editor>
          </div>

          <div class="form-row">
            <label class="form-label" for="text2">Text bottom:</label>
            <quill-editor class="form-field style-exhibition-detail" id="text2" v-model="form.text2" :options="editorOption"></quill-editor>
          </div>

          <div class="form-row">
            <label class="form-label" for="text2">Preview images: {{ artworks_selected_ids.length ? `(${artworks_selected_ids.length} selected)` : '' }}</label>
            <div v-if="artworks_selectable.length" class="artwork-preview-list">
              <label v-for="artwork in artworks_selectable"
                     :key="artwork.id" class="artwork-preview"
                     :aria-hidden="!getPreviewImage(artwork.works_id)"
                     :data-selected="artworks_selected_ids.indexOf(artwork.works_id.id) !== -1">
                <img :src="getPreviewImage(artwork.works_id)">
                <input type="checkbox"
                       class="custom"
                       :value="artwork.works_id.id"
                       v-model="artworks_selected_ids"
                       :checked="artworks_selected_ids.indexOf(artwork.works_id.id) !== -1">
                <div class="custom-checkbox"></div>
              </label>
            </div>
            <div v-else>
              <p>There are no artworks set for this exhibition yet.</p>
            </div>
          </div>
        </div>

        <div class="form-element box" :style="{ animationDelay: '1s', 'max-width': 'unset'}">
          <div class="form-double-col-flex">
            <button class="btn" type="submit">{{ text.exhibitionedit_submit }}</button>
            <button class="btn btn-delete" type="button" v-if="Exhibition.id" @click="askForDeleteExhibition()">{{ text.exhibitionedit_delete }}</button>
          </div>
        </div>
      </form>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import '@google/model-viewer';
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnRunSimultaneously, fnSetPageInformations, fnUploadFile, fnDeleteFiles } from '@/modules/globalFunctions.js';
import { quillEditor } from 'vue-quill-editor';
import Quill from 'quill';
import Loader from '@/components/Loader.vue';
import MessageBox from '@/components/MessageBox.vue';
import Icon from '@/components/Icon.vue';

const oTextTemplate = {
  exhibitionedit_text_file_formats: '',
  exhibitionedit_choose_file: '',
  exhibitionedit_update_success: '',
  exhibitionedit_rly_delete_exhibition: '',
  exhibitionedit_status_published: '',
  exhibitionedit_status_draft: '',
  exhibitionedit_status_archived: '',
  exhibitionedit_status: '',
  exhibitionedit_name: '',
  exhibitionedit_description: '',
  exhibitionedit_start_date: '',
  exhibitionedit_end_date: '',
  exhibitionedit_city: '',
  exhibitionedit_location: '',
  exhibitionedit_submit: '',
  exhibitionedit_delete: '',
  exhibitionedit_image_upload_success: '',
  exhibitionedit_cant_delete_post: '',
  exhibition_import_filetype_impossible: '',
  maximum_text_size_exceeded: '',
  internal_error: ''
};

const Delta = Quill.import('delta');

export default {
  name: 'ExhibitionEdit',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    quillEditor,
    Loader,
    MessageBox,
    Icon
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_exhibition_edit',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      form: {
        status: '',
        start_date: '',
        end_date: '',
        name: '',
        description: '',
        preview_image: '',
        banner_image: '',
        city: '',
        location: '',
        text1: '',
        text2: '',
        artworks: [],
        artworks_preview: []
      },
      formBefore: {},
      compareFields: [
        { key: 'status', json: false },
        { key: 'start_date', json: false },
        { key: 'end_date', json: false },
        { key: 'name', json: false },
        { key: 'description', json: false },
        { key: 'preview_image', json: false },
        { key: 'banner_image', json: false },
        { key: 'city', json: false },
        { key: 'location', json: false },
        { key: 'text1', json: false },
        { key: 'text2', json: false },
        { key: 'artworks', json: false },
        { key: 'artworks_preview', json: true }
      ],
      artworks_selectable: [],
      artworks_selected_ids: [],
      fileData: {
        preview_image: {
          isUploading: false,
          file: undefined,
          fileType: undefined,
          fileExtension: undefined,
          progress: 0
        },
        banner_image: {
          isUploading: false,
          file: undefined,
          fileType: undefined,
          fileExtension: undefined,
          progress: 0
        }
      },
      message: oMessageBoxDataTemplate,
      status: [
        { key: 'published', label: 'published' },
        { key: 'draft', label: 'draft' },
        { key: 'archived', label: 'archived' }
      ],
      fileTypeList: ['jpg', 'jpeg', 'png'],
      fileTypes: '',
      // quill-editor
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': [2, 3, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
          ],
          clipboard: {
            matchers: [
              [Node.ELEMENT_NODE, (node, delta) => delta.compose(new Delta().retain(delta.length(), {
                color: false,
                background: false,
                bold: false,
                strike: false,
                underline: false
              }))]
            ]
          }
        },
        placeholder: ''
      },
      editorOptionNoToolbar: {
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [
              [Node.ELEMENT_NODE, (node, delta) => delta.compose(new Delta().retain(delta.length(), {
                color: false,
                background: false,
                bold: false,
                strike: false,
                underline: false
              }))]
            ]
          }
        },
        placeholder: ''
      },
      maxTextLength: 5000
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapGetters({ IsExhibitor: 'isExhibitor', Exhibition: 'StateExhibition', User: 'StateUser', Token: 'StateToken' }),
  },
  methods: {
    ...mapActions(['GetExhibition', 'UpdateExhibition', 'DeleteExhibition']),


    /**
     * Initialization function
     */
    async init() {
      // Workaround for wrong routing component directly after changing the user
      if (!this.IsExhibitor) {
        location.reload();
        return;
      }

      this.isLoading = true;

      this.fileTypes = this.fileTypeList.map(sFileType => `.${sFileType}`).join(', ');

      await this.setPageInformations();

      await fnRunSimultaneously([
        // Get post informations
        this.fetchExhibition
      ], `${this.metaData.page} | Exhibition, Video, Serie`);

      // Fix older textarea texts containing "\n"
      const aSplitN = (this.form.description + '').split('\n'); // Remove references to avoid interferences
      if (aSplitN.length > 1) this.form.description = aSplitN
        .map(sLine => {
          const sContent = sLine.trim();
          return sContent.length ? `<p>${sContent}</p>` : '<p><br></p>';
        })
        .join('');

      // Save initial form content to determine if content needs to be saved or not
      this.compareFields
        .filter(oData => this.form[oData.key] !== undefined)
        .forEach(oData => this.formBefore[oData.key] = oData.json ? JSON.stringify(this.form[oData.key]) : this.form[oData.key]);
      this.formBefore.artworks_preview = JSON.stringify(this.form.artworks_preview);

      this.isLoading = false;
    },


    async setPageInformations() {
      await fnSetPageInformations(this, oTextTemplate);

      // Set status texts
      this.status = this.status.map(oStatus => ({
        ...oStatus,
        label: this.text[`exhibitionedit_status_${oStatus.key}`]
      }));
    },


    /**
     * Load requested post
     *
     * @returns {void}
     */
    async fetchExhibition() {
      await this.GetExhibition(this.$route.params.id);

      if (!this.Exhibition) return;

      // Fill corresponding form data
      for (let sKey in this.Exhibition) this.form[sKey] = this.Exhibition[sKey];

      this.artworks_selectable = this.form.artworks || [];
      const aIdsAvailable = this.artworks_selectable.map(oArtwork => oArtwork.works_id.id);
      this.artworks_selected_ids = this.form.artworks_preview
        .map(oArtwork => oArtwork.works_id)
        .filter(iId => aIdsAvailable.indexOf(iId) !== -1);
    },


    /**
     * Update current post
     */
    async submit() {
      const aCheckError = [];
      const aFillFields = [
        'artworks_preview',
        'city',
        'description',
        'end_date',
        'location',
        'name',
        'start_date',
        'status',
        'text1',
        'text2'
      ];
      const oData = {};

      // Trim empty HTML lines
      if (this.form.description) this.form.description = this.form.description.replace(/(^(?:<p><br><\/p>)*|(?:<p><br><\/p>)*$)/img, '');
      if (this.form.text1) this.form.text1 = this.form.text1.replace(/(^(?:<p><br><\/p>)*|(?:<p><br><\/p>)*$)/img, '');
      if (this.form.text2) this.form.text2 = this.form.text2.replace(/(^(?:<p><br><\/p>)*|(?:<p><br><\/p>)*$)/img, '');

      Object.keys(this.form)
        .filter(sKey => this.form[sKey] !== undefined)
        .forEach(sKey => aFillFields.indexOf(sKey) === -1 ? null : oData[sKey] = this.form[sKey]);
      oData.artworks_preview = this.artworks_selected_ids?.map(iId => ({ works_id: iId })); // Needed for comparison

      const aCompareKeys = this.compareFields.map(oData => oData.key);
      Object.keys(oData).forEach(sKey => {
        const iCompareIndex = aCompareKeys.indexOf(sKey);
        if (iCompareIndex === -1) return;

        const sContentNew = this.compareFields[iCompareIndex].json ? JSON.stringify(oData[sKey]) : oData[sKey];
        const sContentOld = this.formBefore[sKey];
        if (sContentNew === sContentOld) delete oData[sKey];
      });

      // Check content max length
      [
        { key: 'description', label: this.text.exhibitionedit_description }
      ]
        .filter(oField => oData[oField.key] !== undefined)
        .forEach(oField => {
          if (oData[oField.key].length <= this.maxTextLength) return;

          aCheckError.push(`${oField.label}: ${this.text.maximum_text_size_exceeded}`);
          delete oData[oField.key];
        });

      // Prepare selected artworks
      if (oData.artworks_preview) oData.artworks_preview = this.artworks_selected_ids.map(iId => ({
        exhibition_id: this.Exhibition.id,
        works_id: iId
      }));

      try {
        if (Object.keys(oData).length) await this.UpdateExhibition(oData);

        if (!aCheckError.length) this.$refs.messageBox.showMessage('success', this.text.exhibitionedit_update_success, 1000);
      } catch (error) {
        this.$refs.messageBox.showMessage('error', this.text.internal_error);
      }

      if (aCheckError.length) {
        const sErrorMessage = aCheckError
          .map(sMsg => `- ${sMsg}`)
          .join('</p><p>');

        this.$refs.messageBox.showMessage('error', sErrorMessage);
      }
    },


    /**
     * Return the image URL of a preview file
     *
     * @returns {string} Absolute image URL
     */
    getPreviewImage(oArtwork) {
      const aPreview = (oArtwork?.directus_files || [])
        .filter(oFile => oFile.tags === 'preview')
        .map(oFile => oFile.id);

      if (aPreview.length) return `https://arxafrica.net/assets/${aPreview[0]}/thumbnail`;
    },


    /**
     * Request user confirmation before deleting an exhibition.
     * Will multiple buttons inside a message popup
     */
    askForDeleteExhibition() {
      this.$refs.messageBox.showMessage('info', this.text.exhibitionedit_rly_delete_exhibition, 0, [], [{
        label: 'Yes',
        class: 'btn btn-delete',
        fn: _this => {
          _this.deleteExhibition();
        }
      }, {
        label: 'No',
        class: 'btn',
        fn: () => { }
      }], this);
    },


    /**
     * Delete a post
     */
    async deleteExhibition() {
      try {
        await this.DeleteExhibition(this.Exhibition.id);
        this.$router.push(`/${this.$route.meta.language}/exhibitions`); // go back
      } catch (error) {
        throw this.text.exhibitionedit_cant_delete_post;
      }
    },


    /**
     * Return the image URL of a preview file
     *
     * @returns {string} Absolute image URL
     */
    getPoster() {
      const aPreview = (this.Exhibition.directus_files || [])
        .filter(oFile => oFile.tags === 'preview')
        .map(oFile => oFile.id);

      if (aPreview.length) return `https://arxafrica.net/assets/${aPreview[0]}/thumbnail`;
    },


    /**
     * Upload a file (image)
     */
    async handleFileUpload(_$event, sImageKey, aAllowedTypes = ['jpeg', 'jpg', 'png']) {
      const _this = this;
      const oAllowedTypes = {
        image: aAllowedTypes
      };
      const oFile = this.$refs[sImageKey].files[0];
      const sFileExtension = oFile?.name?.split('.').pop();

      /**
       *
       * @returns {string|false} File ID, delivered by this.imageUpload()
       */
      const fnUploader = async () => {
        if (!_this.fileData[sImageKey].fileType) return;

        _this.fileData[sImageKey].isUploading = true;
        _this.fileData[sImageKey].progress = 0;

        const oFormData = new FormData();
        oFormData.append('tags', _this.fileData[sImageKey].fileType);
        oFormData.append('file', _this.fileData[sImageKey].file);

        return await _this.imageUpload(oFormData, sImageKey);
      }

      // Check file type
      let sFileType = undefined;
      for (let sAllowedFileType in oAllowedTypes) {
        if (oAllowedTypes[sAllowedFileType].indexOf(sFileExtension.toLowerCase()) !== -1) {
          sFileType = sAllowedFileType;
          break;
        }
      }

      // Upload
      if (sFileType) {
        this.fileData[sImageKey] = {
          ...this.fileData[sImageKey],
          file: oFile,
          fileType: sFileType,
          fileExtension: sFileExtension
        };

        const bUploadSuccessful = await fnUploader() !== false;

        bUploadSuccessful
          ? this.$refs.messageBox.showMessage('success', this.text.exhibitionedit_image_upload_success, 1000)
          : this.$refs.messageBox.showMessage('error', this.text.internal_error);
      } else {
        // Replaces a single placeholder __EXTENSION__ with the extension name of the current file
        this.$refs.messageBox.showMessage('error', this.text.exhibition_import_filetype_impossible?.replace('__EXTENSION__', sFileExtension));
      }
    },


    /**
     * Upload a file
     *
     * @param {FormData} oFormData Form data containing title,tags,work,file
     * @returns {string|false} Avatar ID
     */
    async imageUpload(oFormData, sImageKey) {
      const oResponse = await fnUploadFile(oFormData, this.url, this.Token, oEvent => this.fileData[sImageKey].progress = Math.round((oEvent.loaded * 100) / oEvent.total));

      this.fileData[sImageKey].isUploading = false;
      if (oResponse?.status === 200) {
        this.Exhibition[sImageKey] = oResponse.data?.data?.id;

        const oData = {};
        oData[sImageKey] = this.Exhibition[sImageKey];

        await axios({
          method: 'patch',
          headers: {
            'Authorization': `Bearer ${this.Token}`,
            'Content-Type': 'application/json'
          },
          url: `${this.url}/items/exhibition/${this.Exhibition.id}`,
          data: oData
        });
      }

      return this.Exhibition[sImageKey];
    },


    /**
     * Delete an image
     */
    async deleteImage(sImageKey) {
      let oResponse = undefined;
      if (this.Exhibition[sImageKey]) oResponse = await fnDeleteFiles([this.Exhibition[sImageKey]], this.url, this.Token);
      if (oResponse?.status === 200) this.Exhibition[sImageKey] = null;
    },


    /**
     * Style changes while dragging a file over the drop zone
     *
     * @param oEvent
     */
    onImageDragover(oEvent) {
      oEvent.preventDefault();
      const oTarget = oEvent.currentTarget;
      if (!oTarget.classList.contains('drag-over')) oTarget.classList.add('drag-over');
    },


    /**
     * Style changes while dragging a file over the drop zone
     *
     * @param oEvent
     */
    onImageDragleave(oEvent) {
      oEvent.currentTarget.classList.remove('drag-over');
    },


    /**
     * Trigger file upload when a file is dropped into the drop zone
     *
     * @param oEvent
     */
    onImageDrop(oEvent, sImageKey) {
      oEvent.preventDefault();

      this.$refs[sImageKey].files = oEvent.dataTransfer.files;
      this.handleFileUpload(oEvent, sImageKey);

      oEvent.currentTarget.classList.remove('drag-over');
    }
  }
}
</script>

<style lang="scss">
.style-exhibition-detail .ql-snow .ql-editor {
  h2 {
    font: normal normal 300 3rem/3.5rem "Barlow";
    letter-spacing: 0px;
    text-transform: uppercase;
    margin: 0 0 2rem 0;
  }

  h3 {
    font: normal normal 700 1.25rem/1.5rem "Barlow";
    letter-spacing: 0px;
    margin: 2.5rem 0 0.5rem 0;
  }

  p {
    font: normal normal 400 1rem/1.5rem "Barlow";
    letter-spacing: 0px;
  }
}
</style>

<style scoped lang="scss">
@import "./../scss/_form.scss";

.form-row {
  position: relative;
}

.form-groups {
  border-radius: 0.5rem;
  padding: 1rem;
  background: linear-gradient(
    190deg,
    rgba(233, 77, 24, 0.92) 0%,
    rgba(21, 59, 76, 0.92) 100%
  );
}

.select-group-button {
  float: right;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#size-input-container {
  flex: 1;
  margin-right: 2rem;
}

.form-field[type="date"] {
  &::placeholder {
    color: $color-4;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-4;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-4;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.upload-format:not(:first-of-type) {
  margin-left: 0.25rem;
}

.artwork-preview-list {
  $cols: 2;
  $gap: 1rem;

  display: grid;
  grid-template-columns: repeat(
    $cols,
    calc((100% - #{($cols - 1) * $gap}) / #{$cols})
  );
  column-gap: $gap;
  row-gap: $gap;
  max-height: 25rem;
  overflow-y: auto;
  padding: 0 #{$gap / 2};

  .artwork-preview {
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    display: block;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );

    &[data-selected="true"] {
      $lighten: 100%;

      background: lighten(rgba(0, 0, 0, 0.3), $lighten);
      background: linear-gradient(
        90deg,
        lighten(rgba(0, 0, 0, 0.4), $lighten) 0%,
        lighten(rgba(0, 0, 0, 0.3), $lighten) 100%
      );
    }

    &[aria-hidden="true"] {
      display: none;
    }

    input[type="checkbox"].custom ~ .custom-checkbox {
      position: absolute;
      bottom: 0.75rem;
      left: 0.75rem;
    }
  }
}

#container {
  @include breakpoint(medium) {
    grid-template-areas:
      "c1 c2"
      "c3 c4"
      "c5 c5";

    @for $i from 1 through 5 {
      & > *:nth-child(#{$i}) {
        grid-area: c#{$i};
      }
    }
  }

  .preview-image {
    margin-top: 0.5rem;
  }
}
</style>
