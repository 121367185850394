<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="!isLoading" class="form-container">
      <div class="form-element box box-large">
        <span v-html="text.accept_text"></span>

        <form @submit.prevent="submit">
          <div class="form-row">
            <ul class="password-conditions">
              <li :class="{ is_valid: contains_min_characters }">{{ password_min_length }} {{ text.accept_contains_characters }}</li>
              <li :class="{ is_valid: contains_number }">{{ text.accept_contains_number }}</li>
              <li :class="{ is_valid: contains_uppercase }">{{ text.accept_contains_uppercase }}</li>
              <li :class="{ is_valid: contains_special_character }" class="condition-help">
                <span>{{ text.accept_contains_special_chars }}</span>
                <button type="button" class="btn" :class="{ secondary: special_char_list_visible }" @click="toggleHelp('special_char_list_visible')"><Icon name="help" /></button>
                <div v-if="special_char_list_visible" class="condition-help-content">{{ special_char_list }}</div>
              </li>
            </ul>
            <label class="form-label" for="password">{{ text.accept_password }}:</label>
            <input class="form-field" id="password" type="password" @input="checkPassword" v-model="password" autocomplete="off">
          </div>
          <div class="form-row error-text">
            <label class="form-label" for="password_repeat">{{ text.accept_password_repeat }}:</label>
            <input class="form-field" :class="{ invalid: (password || '').length && password !== password_repeat }" id="password_repeat" type="password" @input="checkPassword" v-model="password_repeat" autocomplete="off">
            <div class="error-text-text" v-if="password !== password_repeat && (password_repeat || '').length">{{ text.accept_passwords_unequal }}</div>
          </div>
          <button :disabled="!((password || '').length && password === password_repeat)" class="btn" type="submit">{{ text.accept_set_password }}</button>
        </form>
      </div>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnSetPageInformations } from '@/modules/globalFunctions.js';
import MessageBox from '@/components/MessageBox.vue';
import Loader from "@/components/Loader.vue";
import Icon from '@/components/Icon.vue';

const oTextTemplate = {
  accept_text: '',
  accept_password: '',
  accept_password_repeat: '',
  accept_passwords_unequal: '',
  accept_contains_characters: '',
  accept_contains_number: '',
  accept_contains_uppercase: '',
  accept_contains_special_chars: '',
  accept_set_password: '',
  accept_validation_error: ''
};

export default {
  name: 'Accept',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    MessageBox,
    Loader,
    Icon
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_accept',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      message: oMessageBoxDataTemplate,
      token: '',
      password: null,
      password_repeat: null,
      password_length: 0,
      password_min_length: 8,
      contains_min_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password_1: false,
      valid_password: false,
      special_char_list: `!@#$%^&*()_+ -={};':"|,<>?`,
      special_char_list_visible: false
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  methods: {
    /**
     * Initialization function
     */
    async init() {
      this.isLoading = true;

      await fnSetPageInformations(this, oTextTemplate);

      this.isLoading = false;
    },


    /**
     * Check password security
     */
    checkPassword() {
      this.contains_min_characters = this.password.length >= this.password_min_length;

      [
        this.contains_number,
        this.contains_uppercase,
        this.contains_special_character
      ] = [
        new RegExp(/\d/),
        new RegExp(/[A-Z]/),
        new RegExp(/[!@#$%^&*()_+ \-={};':"|,<>?]/)
      ].map(oRegExp => oRegExp.test(this.password));

      this.valid_password_1 = this.contains_min_characters &&
        this.contains_special_character &&
        this.contains_uppercase &&
        this.contains_number;
      this.valid_password = this.valid_password_1 && this.password === this.password_repeat;
    },


    /**
     * Submit password
     */
    async submit() {
      if (!this.valid_password) {
        const aMsg = [];

        if (!this.contains_min_characters) aMsg.push(`${this.password_min_length} ${this.text.accept_contains_characters}`);
        if (!this.contains_number) aMsg.push(this.text.accept_contains_number);
        if (!this.contains_uppercase) aMsg.push(this.text.accept_contains_uppercase);
        if (!this.contains_special_character) aMsg.push(`${this.text.accept_contains_special_chars}<br/>&emsp;( ${this.special_char_list} )`);

        if (aMsg.length) this.$refs.messageBox.showMessage('error', '<p>' + this.text.accept_validation_error + '</p><p>' + aMsg.map(sMsg => `- ${sMsg}`).join('</p><p>') + '</p>');

        return;
      }

      if (this.$route?.query?.token) this.token = this.$route.query.token;

      try {
        const oResponse = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          url: `${process.env.VUE_APP_ARTISTS_API}/users/invite/accept`,
          data: {
            token: this.token,
            password: this.password
          }
        });

        if (oResponse?.status === 200 && oResponse?.data === '') this.$router.push(`/${this.$route.meta.language}/login`);
        else this.$refs.messageBox.showMessage('error', `An unecpected error (${oResponse?.status}) occured`);
      } catch (error) {
        this.$refs.messageBox.showMessage('error', error);
      }
    },


    toggleHelp(sAttr) {
      if (this[sAttr] === undefined) return;

      this[sAttr] = !this[sAttr];
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";

.password-conditions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5rem;
  font-size: 80%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #000;
  margin: 1rem 0 2rem 0;

  @include breakpoint(medium) {
    flex-direction: row;
    padding: 0 0 0.25rem 0;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 0.5rem 0;
    list-style: none;

    @include breakpoint(medium) {
      width: 25%;
      padding: 0.5rem 1rem;
    }

    &::after {
      position: absolute;
      content: "";
      width: 0%;
      height: 0.125rem;
      background: $color-6;
      left: 0;
      bottom: -0.25rem;
      display: block;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      @include breakpoint(medium) {
        bottom: 0;
      }
    }

    &.is_valid {
      color: rgba(136, 152, 170, 0.8);

      &::after {
        width: 100%;
      }

      &.condition-help {
        .btn {
          opacity: 0.5;
        }
      }
    }
  }
}

.error-text {
  $error-text-height: 1.5rem;

  position: relative;

  .invalid {
    box-shadow: inset 0 0 0.125rem 0.125rem $color-5;
    padding-bottom: $error-text-height;
  }

  .error-text-text {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $error-text-height;
    font-size: 0.75rem;
    color: $color-5;
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  }
}

.condition-help {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .btn {
    width: 1rem;
    height: 1rem;
    padding: 0.125rem;
    min-height: unset;

    svg {
      height: 100%;
      width: auto;
    }
  }

  @include breakpoint(medium) {
    position: relative;

    .btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .condition-help-content {
    font-family: Consolas, monaco, monospace;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
  }
}
</style>
