<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="IsSupporter">
      <section class="box box-large admin-panel">
        <h2>Admin Panel</h2>
        <div class="form-container">
          <div v-if="Post.type && Post.type !== 'none'">
            <table>
              <tr>
                <td>Type:&ensp;</td>
                <td>{{ Post.type }}</td>
              </tr>
              <tr v-if="Post.type === 'image' || Post.type === 'model'">
                <td>Has GLB:&ensp;</td>
                <td>{{ JSON.stringify(hasGlb()) }}</td>
              </tr>
              <tr v-if="Post.type === 'image' || Post.type === 'model'">
                <td>Has USDZ:&ensp;</td>
                <td>{{ JSON.stringify(hasUsdz()) }}</td>
              </tr>
            </table>
            <button v-if="(Post.type === 'image' || Post.type === 'model') && image3dLoaded && !this.Post.block_file_control" type="button" id="btn-missing-models" class="btn secondary" @click="createMissingModelsManual()" :disabled="missingModelsButtonDisabled">{{ text.artwork_model_recreate }}</button>
          </div>

          <div>
            <router-link :to="`/${this.$route.meta.language}/${Post ? `?user=${Post.user_created}` : ''}`" class="btn">Show all artworks of this artist</router-link><br/>
            <a v-if="Post.type === 'image' && getOriginalImageUrl()" :href="getOriginalImageUrl()" class="btn" target="_blank">Show original image</a>
            <p v-if="form.vimeo_id">Vimeo ID: {{ form.vimeo_id }}</p>
          </div>
        </div>
      </section>
    </article>

    <article v-if="!isLoading">
      <form class="form-container-grid-2" @submit.prevent="submit">
        <div id="file-preview" class="form-element box">
          <div id="image3d" :class="{ hide: !image3dLoaded || this.Post.type != 'image' || fileData.isUploading || !(Post.directus_files && Post.directus_files.length > 0) || (hasGlb() && hasUsdz()) }"></div>
          <div id="image3d2" class="hide"></div>

          <div class="preview" v-if="Post.vimeo_id">
            <div class="delete-file-button" v-if="Post.vimeo_id && !this.Post.block_file_control" @click="deleteVideo()"><Icon name="delete" class="inline-svg" /></div>
            <div class="preview-video" :class="{ 'not-available' : !isVideoAvailable}">
              <!-- <iframe v-if="isVideoAvailable" :src="`https://player.vimeo.com/video/${Post.vimeo_id}`" width="640" height="360" frameborder="0" allowfullscreen></iframe> -->
              <video v-if="isVideoAvailable && vimeoVideoUrl" controls :src="vimeoVideoUrl"></video>
              <div v-if="!isVideoAvailable" class="video-info" v-html="text.artwork_in_process"></div>
            </div>
          </div>

          <div class="preview" v-else-if="Post.directus_files && Post.directus_files.length > 0">
            <div class="delete-file-button" v-if="!fileData.isUploading && !this.Post.block_file_control" @click="fileDelete()"><Icon name="delete" class="inline-svg" /></div>
            <div class="preview-model" v-if="Post.type == 'image' && hasGlb() && hasUsdz()">
              <model-viewer
                :src="getCorrectModel(Post.directus_files, 'glb')"
                :ios-src="getCorrectModel(Post.directus_files, 'usdz')"
                :poster="getPoster()"
                alt=""
                ar
                ar-modes="scene-viewer quick-look"
                autoplay
                environment-image="neutral"
                shadow-intensity="1"
                auto-rotate
                camera-controls="1"></model-viewer>
            </div>

            <div class="preview-image" v-else-if="Post.type == 'image'">
              <img :src="`https://api.arxafrica.net/assets/${file.filename_disk}?preview`" draggable="false" :class="{ hide: image3dLoaded || fileData.isUploading }" v-for="file in getPreviewImageArray()" :key="file.id">
            </div>

            <div class="preview-model" v-if="Post.type == 'model'">
              <model-viewer
                id="model"
                :src="getCorrectModel(Post.directus_files, 'glb')"
                :ios-src="getCorrectModel(Post.directus_files, 'usdz')"
                :poster="getPoster()"
                alt=""
                ar
                ar-modes="scene-viewer quick-look"
                autoplay
                environment-image="neutral"
                shadow-intensity="1"
                auto-rotate
                camera-controls="1"></model-viewer>
              <button v-if="!fileData.isUploading && !this.Post.block_file_control" class="snapshot-button" @click="snapshotModel()"><Icon name="snapshot" class="inline-svg" /></button>
            </div>

            <button v-if="(Post.type === 'image' || Post.type === 'model') && image3dLoaded && !this.Post.block_file_control" type="button" id="btn-recreate-models" class="btn" @click="createMissingModelsManual()" :disabled="missingModelsButtonDisabled">{{ text.artwork_model_recreate }}</button>
          </div>

          <div v-else-if="!fileData.isUploading && !this.Post.block_file_control">
            <p>
              <span v-html="text.artwork_text_file_formats"></span>
              <span v-for="fileType in fileTypeList" :key="fileType" class="upload-format">{{ fileType }}</span>
            </p>

            <div class="preview upload" @dragover="onImageDragover" @dragleave="onImageDragleave" @drop="onImageDrop">
              <label for="file" v-html="text.artwork_choose_file"></label>
              <input type="file" id="file" class="file-input" ref="file" :accept="fileTypes" v-on:change="handleFileUpload()"/>
            </div>
          </div>

          <Loader v-if="isUploading || fileData.isUploading" position="relative" :progress="fileData.progress" :currentTask="loaderCurrentTask"/>
        </div>
        <!-- <div class="box">
          <div class="form-row">
            <label class="form-label" for="serie">Serie:</label>

            <b>{{selectedSerie.title}}</b>

            <ul class="form-groups">
              <li class="form-group" v-for="option in series" :key="option.id">
                <b>{{option.title}}</b> ({{option.works.length}} works)
                <button :disabled="form.serie == option.id" class="select-group-button" @click.prevent="selectSerie(option.id)">Select</button>
              </li>
            </ul>

            <input class="form-field" type="text" id="new-serie" name="new-serie" v-model="newSerieTitle" />
            <button @click.prevent="createSerie()">Create serie</button>

          </div>
        </div> -->
        <div class="form-element box" :style="{ animationDelay: '.25s'}">
          <div class="form-row">
            <label class="form-label" for="title">{{ text.artwork_title }}:</label>
            <input class="form-field" type="text" id="title" name="title" v-model="form.title" />
          </div>

          <div class="form-row">
            <label class="form-label" for="status">{{ text.artwork_status }}:</label>
            <select class="form-field" id="status" v-model="form.status">
              <option value="draft">{{ text.artwork_status_private }}</option>
              <option value="exhibition">{{ text.artwork_status_exhibition }}</option>
              <option value="published">{{ text.artwork_status_public }}</option>
            </select>
            <div class="description" v-if="form.status === 'draft'" v-html="text.artwork_statusdesc_private"></div>
            <div class="description" v-else-if="form.status === 'exhibition'" v-html="text.artwork_statusdesc_exhibition"></div>
            <div class="description" v-else-if="form.status === 'published'" v-html="text.artwork_statusdesc_public"></div>
          </div>
          <div class="form-row">
            <label class="form-label" for="description">{{ text.artwork_description }}:</label>
            <quill-editor
              id="description"
              class="form-field"
              :class="{ 'chars-exceeded': form.description ? maxTextLength < form.description.length : false }"
              v-model="form.description"
              :options="editorOption"
              :data-chars-left="maxTextLength - Math.max(0, form.description ? form.description.length : 0)"
              :data-chars-exceeded-text="text.maximum_text_size_exceeded"
              ></quill-editor>
          </div>

          <div class="form-row">
            <label class="form-label" for="category">{{ text.artwork_category }}:</label>
            <select class="form-field" id="category" v-model="form.category">
              <option v-for="category in categories" :key="category.key" :value="category.key">{{ category.label }}</option>
            </select>
          </div>

          <div class="form-double-col-flex">
            <div class="form-row" id="size-input-container">
              <label class="form-label" for="size">{{ text.artwork_size }}:</label>
              <input class="form-field" type="number" min="0.01" step="0.01" id="size" name="size" v-model="form.size" @blur="triggerOnchange($event)" @change="sizeChanged()"/>
            </div>
            <div class="form-row">
              <label class="form-label" for="size_unit">{{ text.artwork_size_unit }}:</label>
              <select class="form-field" id="size_unit" v-model="form.size_unit" @change="sizeChanged()">
                <option v-for="unit in units" :value="unit.value" :key="unit.value">{{ unit.label }}</option>
              </select>
            </div>
            <p v-if="sizeWasChanged">{{ text.artwork_size_onchange }}</p>
          </div>

          <div class="form-row">
            <label class="form-label" for="placing">{{ text.artwork_placing }}:</label>
            <select class="form-field" id="placing" v-model="form.placement">
              <option value="floor">{{ text.artwork_placing_floor }}</option>
              <option value="wall">{{ text.artwork_placing_wall }}</option>
            </select>
          </div>

          <div class="form-double-col-flex">
            <button class="btn" type="submit">{{ text.artwork_save }}</button>
            <button class="btn btn-delete" type="button" v-if="Post.id && !this.Post.block_file_control" @click="askForDeletePost()">{{ text.artwork_delete }}</button>
          </div>
        </div>
      </form>
    </article>

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import '@google/model-viewer';
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnRunSimultaneously, fnSetPageInformations, fnDeleteFiles, fnWait, fnUnitToMeter } from '@/modules/globalFunctions.js';
import { quillEditor } from 'vue-quill-editor';
import Quill from 'quill';
import Loader from '@/components/Loader.vue';
import MessageBox from '@/components/MessageBox.vue';
import Icon from '@/components/Icon.vue';
import $ from 'jquery';
import { oModelViewer as ModelViewer1, oModelViewer2 as ModelViewer2 } from '@/modules/ModelViewer.js';

const oModelViewer = ModelViewer1;
const oModelViewer2 = ModelViewer2;

const oTextTemplate = {
  artwork_title: '',
  artwork_status: '',
  artwork_status_private: '',
  artwork_status_public: '',
  artwork_status_exhibition: '',
  artwork_statusdesc_private: '',
  artwork_statusdesc_public: '',
  artwork_statusdesc_exhibition: '',
  artwork_description: '',
  artwork_save: '',
  artwork_delete: '',
  artwork_in_process: '',
  artwork_text_file_formats: '',
  artwork_choose_file: '',
  artwork_update_success: '',
  artwork_rly_delete_artwork: '',
  artwork_cant_delete_post: '',
  artwork_image_upload_success: '',
  artwork_import_filetype_impossible: '',
  artwork_size: '',
  artwork_size_unit: '',
  artwork_placing: '',
  artwork_placing_floor: '',
  artwork_placing_wall: '',
  artwork_progress_upload_file: '',
  artwork_progress_generate_model: '',
  artwork_progress_remove_file: '',
  artwork_category: '',
  artwork_cat_painting: '',
  artwork_cat_printmaking: '',
  artwork_cat_sculpture: '',
  artwork_cat_photo: '',
  artwork_cat_video: '',
  artwork_cat_animation: '',
  artwork_cat_digitalart: '',
  artwork_delete_not_possible: '',
  artwork_model_recreate: '',
  artwork_size_onchange: '',
  maximum_text_size_exceeded: '',
  internal_error: ''
};

const Delta = Quill.import('delta');

export default {
  name: 'Artwork',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    quillEditor,
    Loader,
    MessageBox,
    Icon
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      metaData: {
        page: 'artists_artwork',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      isVideoAvailable: false,
      series: {},
      selectedSerie: {
        title: ''
      },
      newSerieTitle: '',
      form: {
        title: '',
        description: '',
        vimeo_id: '',
        status: '',
        serie: null,
        size: 0,
        size_unit: '',
        placement: '',
        category: ''
      },
      formBefore: {},
      compareFields: [
        { key: 'title', json: false },
        { key: 'description', json: false },
        { key: 'status', json: false },
        { key: 'serie', json: false },
        { key: 'size', json: false },
        { key: 'size_unit', json: false },
        { key: 'placement', json: false },
        { key: 'category', json: false },
        { key: 'video_id', json: false }
      ],
      fileData: {
        isUploading: false,
        file: undefined,
        fileType: undefined,
        fileExtension: undefined,
        progress: 0
      },
      message: oMessageBoxDataTemplate,
      units: [
        { value: 'mm', label: 'Millimeter' },
        { value: 'cm', label: '' }, // init()
        { value: 'm', label: 'Meter' },
        { value: 'in', label: '' }, // init()
        { value: 'ft', label: 'Foot' },
        { value: 'yd', label: 'Yard' }
      ],
      // quill-editor
      editorOption: {
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [
              [Node.ELEMENT_NODE, (node, delta) => delta.compose(new Delta().retain(delta.length(), {
                color: false,
                background: false,
                bold: false,
                strike: false,
                underline: false
              }))]
            ]
          }
        },
        placeholder: ''
      },
      fileTypeList: ['jpg', 'jpeg', 'png', 'gif', 'mov', 'mp4', 'avi', 'wmv', 'gltf', 'glb'],
      fileTypes: '',
      image3dLoaded: false,
      modelViewerInitiated: false,
      isUploading: false,
      missingModelsButtonDisabled: false,
      oLoop: {
        container: null,
        containerSize: null,
        canvas: null
      },
      loaderCurrentTask: '',
      combinedProcess: 0,
      processes3d: {},
      categories: [
        { key: 'painting', label: '' },
        { key: 'printmaking', label: '' },
        { key: 'sculpture', label: '' },
        { key: 'photo', label: '' },
        { key: 'video', label: '' },
        { key: 'animation', label: '' },
        { key: 'digitalart', label: '' }
      ],
      maxTextLength: 5000,
      sizeWasChanged: false,
      vimeoVideoUrl: null
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      // Reload necessary for 3D view
      location.reload();
    }
  },
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapGetters({ IsSupporter: 'isSupporter', IsExhibitor: 'isExhibitor', Post: 'StatePost', Token: 'StateToken' }),
  },
  methods: {
    ...mapActions(['GetPost', 'UpdatePost', 'DeletePost']),


    /**
     * Initialization function
     */
    async init() {
      // Workaround for wrong routing component directly after changing the user
      if (this.IsExhibitor) {
        location.reload();
        return;
      }

      this.isLoading = true;

      await fnSetPageInformations(this, oTextTemplate);

      const sLangIsDe = this.$route.meta.language === 'de';
      this.units.forEach(oUnit => {
        if (oUnit.value === 'cm') oUnit.label = sLangIsDe ? 'Zentimeter' : 'Centimeter';
        else if (oUnit.value === 'in') oUnit.label = sLangIsDe ? 'Zoll' : 'Inch';
      });

      this.fileTypes = this.fileTypeList.map(sFileType => `.${sFileType}`).join(', ');
      this.categories.forEach((oCategory, i) => this.categories[i].label = this.text[`artwork_cat_${oCategory.key}`] || oCategory.key || '');

      await fnRunSimultaneously([
        // Get post informations
        this.fetchPost,

        // Get video data
        this.getVideo,

        // Get series data
        this.getSeries
      ], `${this.metaData.page} | Post, Video, Serie`);

      // Save initial form content to determine if content needs to be saved or not
      this.compareFields
        .filter(oData => this.form[oData.key] !== undefined)
        .forEach(oData => this.formBefore[oData.key] = oData.json ? JSON.stringify(this.form[oData.key]) : this.form[oData.key]);

      // Fix older textarea texts containing "\n"
      const aSplitN = (this.form.description + '').split('\n'); // Remove references to avoid interferences
      if (aSplitN.length > 1) this.form.description = aSplitN
        .map(sLine => {
          const sContent = sLine.trim();
          return sContent.length ? `<p>${sContent}</p>` : '<p><br></p>';
        })
        .join('');

      this.isLoading = false;

      await this.initModelViewer(); // Depencencies in DOM, run only after DOM was rendered completely -> after getPost and after isLoading

      if (this.Post.type === 'image') {
        this.isUploading = true;

        const sFileId = this.getPostImage();
        const iSize = this.form.size_unit && this.form.size ? fnUnitToMeter(this.form.size_unit, this.form.size) || null : null;
        await Promise.all([
          oModelViewer.loadImage3d(`https://api.arxafrica.net/assets/${sFileId}?key=compressed`, 'wall', false, iSize),
          oModelViewer2.loadImage3d(`https://api.arxafrica.net/assets/${sFileId}?key=compressed`, 'wall', true, iSize)
        ]);

        oModelViewer.animate();
        oModelViewer2.animate();

        this.image3dLoaded = true;
        this.isUploading = false;
      }
    },


    sizeChanged() {
      this.sizeWasChanged = true;
    },


    getOriginalImageUrl() {
      const aImages = (this.Post?.directus_files || []).filter((oFile) => oFile?.filename_disk && ["jpg", "jpeg", "png", "gif"].indexOf(oFile.filename_disk.split(".").pop()) !== -1 && oFile.tags.indexOf("preview") === -1);
      return aImages.length ? `https://api.arxafrica.net/assets/${aImages[0].filename_disk}` : '';
    },


    getPreviewImageArray() {
      const aImages = this.Post.directus_files.filter(oFile => oFile.tags && oFile.tags.indexOf('image') > -1);
      return aImages.length > 0 ? [aImages[0]] : [];
    },


    hasGlb() {
      return !!this.Post?.directus_files?.some(oFile => ['glb', 'gltf'].indexOf(oFile?.filename_disk && oFile?.filename_disk?.split('.').pop()) !== -1);
    },


    hasUsdz() {
      return !!this.Post?.directus_files?.some(oFile => ['usdz'].indexOf(oFile?.filename_disk && oFile?.filename_disk?.split('.').pop()) !== -1);
    },

    /**
     * @param {'auto' | 'glb' | 'usdz'} sType
     */
    getCorrectModel(aFiles, sType = 'auto') {
      const aGlb = [];
      const aUsdz = [];

      aFiles.forEach(oFile => {
        const sFileType = oFile.filename_disk?.toLowerCase().split('.').pop();

        switch (sFileType) {
          case 'glb':
          case 'gltf':
            aGlb.push(oFile);
            break;

          case 'usdz':
            aUsdz.push(oFile);
            break;

          default:
            break;
        }
      });

      const oResGlb = aGlb.length ? aGlb[0] : null;
      const oResUsdz = aUsdz.length ? aUsdz[0] : null;
      const oResAuto = aGlb.length ? aGlb[0] : aUsdz.length ? aUsdz[0] : null;

      const oFile = sType === 'glb' ? oResGlb : sType === 'usdz' ? oResUsdz : oResAuto;

      return oFile ? `https://api.arxafrica.net/assets/${oFile.filename_disk}` : '';
    },

    async createMissingModelsManual() {
      if (this.Post.block_file_control) return;

      let aFileIds = [];
      if (this.Post.type === 'image') {
        // Delete usdz and glb file
        aFileIds = this.Post.directus_files
          .filter(oFile => ['glb', 'gltf', 'usdz'].indexOf(oFile?.filename_disk && oFile?.filename_disk?.split('.').pop()) !== -1)
          .map(oFile => oFile.id);
      } else if (this.Post.type === 'model') {
        // Delete usdz file
        aFileIds = this.Post.directus_files
          .filter(oFile => ['usdz'].indexOf(oFile?.filename_disk && oFile?.filename_disk?.split('.').pop()) !== -1)
          .map(oFile => oFile.id);
      }

      if (aFileIds?.length) {
        await this.fileDelete(aFileIds, false);
        await this.fetchPost(false);
      }

      await this.createMissingModels();
      await fnWait(500);
    },


    /**
     * Create missing model files only if they do not exist yet.
     * Artwork type has to be 'image' to proceed.
     * Reloads the page when the uploads are done.
     */
    async createMissingModels() {
      const bHasGlb = this.hasGlb();
      const bHasUsdz = this.hasUsdz();
      const aMissingFiles = [
        { type: 'glb', existing: bHasGlb },
        { type: 'usdz', existing: bHasUsdz }
      ]
        .filter(oData => !oData.existing)
        .map(oData => oData.type);

      if (!aMissingFiles.length) return;

      // Set busy
      this.missingModelsButtonDisabled = true;
      this.loaderCurrentTask = this.text.artwork_progress_generate_model;
      this.fileData.progress = 1;

      const aPromise = [];
      if (this.Post.type === 'image') aPromise.push(this.uploadImageModels(aMissingFiles));
      else if (this.Post.type === 'model' && bHasGlb && !bHasUsdz) aPromise.push(this.uploadUsdzModel());

      await Promise.all(aPromise);

      if (aPromise.length) await this.fetchPost(false);

      // Unset busy
      this.loaderCurrentTask = '';
      this.missingModelsButtonDisabled = false;
    },


    async uploadImageModels(aFileTypes = ['glb', 'usdz']) {
      if (!aFileTypes.length) return;
      this.isUploading = true;

      await this.fetchPost(false);
      await fnWait(50);

      const sFileId = this.Post.directus_files.filter(oFile => oFile.tags && oFile.tags.indexOf('image') !== -1).shift()?.filename_disk;

      oModelViewer.clearScene();
      oModelViewer.addLights();
      oModelViewer2.clearScene();
      oModelViewer2.addLights();

      const iSize = this.form.size_unit && this.form.size ? fnUnitToMeter(this.form.size_unit, this.form.size) || null : null;
      await Promise.all([
        oModelViewer.loadImage3d(`https://api.arxafrica.net/assets/${sFileId}?key=compressed`, 'wall', false, iSize),
        oModelViewer2.loadImage3d(`https://api.arxafrica.net/assets/${sFileId}?key=compressed`, 'wall', true, iSize)
      ]);

      oModelViewer.animate();
      oModelViewer2.animate();

      this.image3dLoaded = true;

      const oFiles = {};
      const [oFiles1, oFiles2] = await Promise.all([
        oModelViewer.createFiles(),
        oModelViewer2.createFiles()
      ]);
      if (oFiles1.glb) oFiles.glb = oFiles1.glb;
      if (oFiles2.usdz) oFiles.usdz = oFiles2.usdz;

      const aExistingTypes = this.Post.directus_files.map(oFile => oFile.filename_disk.split('.').pop());
      const aProcessUpload = aFileTypes.filter(sFileType => aExistingTypes.indexOf(sFileType) === -1);

      this.processes3d = {};

      const aPromises = !oFiles ? [] : Object.keys(oFiles)
        .filter(sFileType => oFiles[sFileType] !== null && aProcessUpload.indexOf(sFileType) !== -1)
        .map(sFileType => {
          const oFormData = new FormData();
          oFormData.append('title', `${this.form.title}`);
          oFormData.append('tags', 'model');
          oFormData.append('work', this.Post.id);
          oFormData.append('file', oFiles[sFileType], `${this.Post.id}.${sFileType}`);

          // console.log(`Image: Attempt to upload file: ${this.Post.id}.${sFileType}`);

          this.processes3d[sFileType] = 0;

          return axios.post(`${this.url}/files`, oFormData, {
            headers: {
              'Authorization': `Bearer ${this.Token}`,
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: oEvent => {
              this.processes3d[sFileType] = Math.round((oEvent.loaded * 100) / oEvent.total);
              const aParts = Object.keys(this.processes3d);
              const iParts = aParts.length;
              this.fileData.progress = aParts.map(sKey => this.processes3d[sKey]).reduce((a, b) => a + b / iParts, 0);
              // console.log(this.fileData.progress);
            }
          });
        });

      await Promise.all(aPromises);

      this.isUploading = false;
    },


    async uploadUsdzModel() {
      this.isUploading = true;

      const oFiles = await oModelViewer.createFiles();

      const aFileTypes = ['usdz'];

      const aExistingTypes = this.Post.directus_files.map(oFile => oFile.filename_disk.split('.').pop());
      const aProcessUpload = aFileTypes.filter(sFileType => aExistingTypes.indexOf(sFileType) === -1);

      this.processes3d = {};

      const aPromises = !oFiles ? [] : Object.keys(oFiles)
        .filter(sFileType => oFiles[sFileType] !== null && aProcessUpload.indexOf(sFileType) !== -1)
        .map(sFileType => {
          const oFormData = new FormData();
          oFormData.append('title', `${this.form.title}`);
          oFormData.append('tags', 'model');
          oFormData.append('work', this.Post.id);
          oFormData.append('file', oFiles[sFileType], `${this.Post.id}.${sFileType}`);

          // console.log(`Model: Attempt to upload file: ${this.Post.id}.${sFileType}`);

          this.processes3d[sFileType] = 0;

          return axios.post(`${this.url}/files`, oFormData, {
            headers: {
              'Authorization': `Bearer ${this.Token}`,
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: oEvent => {
              this.processes3d[sFileType] = Math.round((oEvent.loaded * 100) / oEvent.total);
              const aParts = Object.keys(this.processes3d);
              const iParts = aParts.length;
              this.fileData.progress = aParts.map(sKey => this.processes3d[sKey]).reduce((a, b) => a + b / iParts, 0);
              // console.log(this.fileData.progress);
            }
          });
        });

      await Promise.all(aPromises);

      this.isUploading = false;
    },


    async initModelViewer() {
      this.image3dLoaded = false;

      const iTimeout = 1500;
      const iLoopSpeed = 200;

      let oContainer = null;
      let oContainer2 = null;
      let bSizeValid = false;

      const fnTimeout = new Promise(resolve => fnWait(iTimeout).then(() => resolve()));

      // Search for container
      const fnSearchContainer = new Promise(resolve => {
        clearInterval(this.oLoop.container);
        // let iCount = 0;
        this.oLoop.container = setInterval(() => {
          // iCount++;

          oContainer = document.querySelector('#image3d');
          oContainer2 = document.querySelector('#image3d2');

          if (oContainer !== null) {
            bSizeValid = true;
            clearInterval(this.oLoop.container);
            // console.log(`Container found after ${iCount} iterations`);
            resolve();
          }
        }, iLoopSpeed);
      });

      // Detect container size
      const fnDetectContainerSize = new Promise(resolve => {
        clearInterval(this.oLoop.containerSize);
        // let iCount = 0;
        this.oLoop.containerSize = setInterval(() => {
          // iCount++;
          const { width: iWidth, height: iHeight } = oContainer?.getBoundingClientRect() || {};
          if (iWidth && iHeight) {
            clearInterval(this.oLoop.containerSize);
            // console.log(`Container size detected after ${iCount} iterations`);
            resolve();
          }
        }, iLoopSpeed);
      });

      // Search for canvas
      const fnSearchCanvas = new Promise(resolve => {
        // let iCount = 0;
        this.oLoop.canvas = setInterval(() => {
          // iCount++;
          if (document.querySelectorAll('#image3d canvas').length > 0) {
            clearInterval(this.oLoop.canvas);
            // console.log(`Canvas found after ${iCount} iterations`);
            resolve();
          }
        }, iLoopSpeed);
      });

      await Promise.any([
        Promise.all(
          fnSearchContainer,
          fnDetectContainerSize
        ),
        fnTimeout
      ]);
      clearInterval(this.oLoop.container);
      clearInterval(this.oLoop.containerSize);

      if (!oContainer || !bSizeValid) {
        // console.log('Timeout exceeded');
        return;
      }

      if (!this.modelViewerInitiated) {
        oModelViewer.init(oContainer);
        oModelViewer2.init(oContainer2);
        this.modelViewerInitiated = true;
        // console.log('ModelViewer initialized');
      }

      oModelViewer.clearScene();
      oModelViewer.addLights();
      oModelViewer2.clearScene();
      oModelViewer2.addLights();

      const aCanvas = document.querySelectorAll('#image3d canvas, #image3d2 canvas');
      aCanvas.forEach(oElem => {
        oElem.style.opacity = '0';
        oElem.style.transition = 'opacity 0.3s ease-in-out';
      });

      await Promise.any([
        fnSearchCanvas,
        fnTimeout
      ]);
      clearInterval(this.oLoop.canvas);

      aCanvas.forEach(oElem => oElem.style.opacity = '1');

      this.image3dLoaded = true;
    },


    /**
     * Load requested post
     *
     * @returns {void}
     */
    async fetchPost(bOverride = true) {
      await this.GetPost(this.$route.params.id);

      if (!this.Post) return;

      this.inExhibition = this.GetCorrespondingExhibitions

      // Fill corresponding form data
      if (bOverride) for (let sKey in this.Post) {
        switch (sKey) {
          case 'directus_files':
          case 'marker':
            // Skip attributes
            break;

          case 'serie':
            // Fill with id
            this.form[sKey] = this.Post[sKey]?.id
            break;

          default:
            this.form[sKey] = this.Post[sKey];
            break;
        }
      }
    },


    getPostImage() {
      return this.Post?.directus_files
        .filter(oFile => oFile.tags && oFile.tags.indexOf('image') !== -1)
        .map(oFile => oFile.id)
        .shift();
    },


    /**
     * Update current post
     */
    async submit() {
      // Trim empty HTML lines
      if (this.form.description) this.form.description = this.form.description.replace(/(^(?:<p><br><\/p>)*|(?:<p><br><\/p>)*$)/img, '');

      // remove directus_files from oData otherwise the connection to posts is gone
      const oData = this.form;
      const aCheckError = [];

      // Remove non editable data
      [
        'id',
        'date_created',
        'date_updated',
        'directus_files',
        'marker',
        'sort',
        'sort_manual',
        'translations',
        'type',
        'user_created',
        'user_updated',
        'vimeo_id',
        'block_file_control'
      ].forEach(sKey => oData[sKey] !== undefined ? delete oData[sKey] : null);

      const aCompareKeys = this.compareFields.map(oData => oData.key);
      Object.keys(oData).forEach(sKey => {
        const iCompareIndex = aCompareKeys.indexOf(sKey);
        if (iCompareIndex === -1) return;

        const sContentNew = this.compareFields[iCompareIndex].json ? JSON.stringify(oData[sKey]) : oData[sKey];
        const sContentOld = this.formBefore[sKey];
        if (sContentNew === sContentOld) delete oData[sKey];
      });

      // Check content max length
      [
        { key: 'description', label: this.text.artwork_description }
      ]
        .filter(oField => oData[oField.key] !== undefined)
        .forEach(oField => {
          if (oData[oField.key].length <= this.maxTextLength) return;

          aCheckError.push(`${oField.label}: ${this.text.maximum_text_size_exceeded}`);
          delete oData[oField.key];
        });

      try {
        if (Object.keys(oData).length) await this.UpdatePost(oData);

        if (!aCheckError.length) this.$refs.messageBox.showMessage('success', this.text.artwork_update_success, 1000);
      } catch (error) {
        this.$refs.messageBox.showMessage('error', this.text.internal_error);
      }

      if (aCheckError.length) {
        const sErrorMessage = aCheckError
          .map(sMsg => `- ${sMsg}`)
          .join('</p><p>');

        this.$refs.messageBox.showMessage('error', sErrorMessage);
      }
    },


    /**
     *
     * @param id
     */
    async selectSerie(id) {
      this.form.serie = id;
    },


    // /**
    //  *
    //  * @param id
    //  */
    // async editSerie(id) {
    //   console.log(id);
    // },


    /**
     *
     */
    async createSerie() {
      // console.log(this.newSerieTitle);
      try {

        const oSerie = {
          title: this.newSerieTitle,
          works: [this.Post.id]
        }

        // console.log(oSerie);

        const oResponse = await axios.post(`${this.url}/items/series`, oSerie, {
          headers: {
            'Authorization': `Bearer ${this.Token}`
          }
        });

        if (oResponse?.data?.data) {
          this.getSeries();
          this.form.serie = oResponse?.data?.data?.id;
          this.newSerieTitle = '';
        }

        // console.log(oResponse);

      } catch (error) {
        this.$refs.messageBox.showMessage('error', this.text.internal_error);
      }
    },


    /**
     * Request user confirmation before deleting an artwork.
     * Will multiple buttons inside a message popup
     */
    askForDeletePost() {
      const oLabel = {
        y: { en: 'Yes', de: 'Ja', fr: 'Oui' },
        n: { en: 'No', de: 'Nein', fr: 'Non' }
      };
      const sLanguage = this.$route.meta.language;

      this.$refs.messageBox.showMessage('info', this.text.artwork_rly_delete_artwork, 0, [], [{
        label: oLabel.y[sLanguage] || 'Yes',
        class: 'btn btn-delete',
        fn: _this => _this.deletePost()
      }, {
        label: oLabel.n[sLanguage] || 'No',
        class: 'btn',
        fn: () => { }
      }], this);
    },


    /**
     * Delete a post
     */
    async deletePost() {
      if (await this.isBlockedByExhibition() || this.Post.block_file_control) {
        this.$refs.messageBox.showMessage('error', this.text.artwork_delete_not_possible);
        return;
      }

      try {
        await this.DeletePost(this.Post.id);
        this.$router.push(`/${this.$route.meta.language}/`); // go back
      } catch (error) {
        throw this.text.artwork_cant_delete_post;
      }
    },


    /**
     * Return the image URL of a preview file
     *
     * @returns {string} Absolute image URL
     */
    getPoster() {
      const aPreview = (this.Post.directus_files || [])
        .filter(oFile => oFile.tags === 'preview')
        .map(oFile => oFile.id);

      if (aPreview.length) return `https://arxafrica.net/assets/${aPreview[0]}/thumbnail`;
    },


    /**
     * Get vimeo video
     *
     * @returns {void}
     */
    async getVideo() {
      if (!this.Post.vimeo_id) return;

      try {
        const oResponse = await axios({
          method: 'get',
          url: `${process.env.VUE_APP_ARTISTS_API}/videos/${this.Post.vimeo_id}`,
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // console.log('Vimeo',oResponse)

        if (oResponse?.data?.is_playable) {
          this.isVideoAvailable = true;
          const oVimeoVideo = oResponse.data.download.filter(oFile => oFile.quality === 'sd' && oFile.link).shift();
          if (oVimeoVideo) this.vimeoVideoUrl = oVimeoVideo.link;
        }
      } catch (error) {
        throw 'Error';
      }
    },


    /**
     *
     */
    async getSeries() {
      const oResponse = await axios.get(`${this.url}/items/series?limit=-1`, {
        headers: {
          'Authorization': `Bearer ${this.Token}`
        }
      });

      if (oResponse?.data?.data) {
        this.series = oResponse?.data?.data;
        const selectedSerie = this.series.find(serie => serie.id === this.form.serie);

        if (selectedSerie) this.selectedSerie = selectedSerie;
      }

      // console.log(oResponse?.data?.data);
    },


    /**
     * Create and upload a snapshot of a 3d model
     */
    async snapshotModel() {
      if (this.Post.block_file_control) return;

      try {
        const oSnapshot = await document.getElementById('model').toBlob({ mimeType: 'image/png', idealAspect: false });

        const oFormData = new FormData();
        oFormData.append('title', `${this.form.title} preview`);
        oFormData.append('tags', 'preview');
        oFormData.append('work', this.Post.id);
        oFormData.append('file', oSnapshot, `${this.Post.id}-preview.png`);
        this.fileUpload(oFormData);

        // delete previously uploaded images
        const aPreviews = this.Post.directus_files
          .filter(oFile => oFile.tags === 'preview')
          .map(oFile => oFile.id);
        if (aPreviews?.length) this.fileDelete(aPreviews, false);

      } catch (error) {
        throw 'Snapshot not possible.';
      }
    },


    /**
     * Upload a file (image/model/video)
     */
    async handleFileUpload() {
      if (this.Post.block_file_control) return;

      this.loaderCurrentTask = this.text.artwork_progress_upload_file;

      const _this = this;
      const oAllowedTypes = {
        image: ['gif', 'jpeg', 'jpg', 'png'],
        model: ['gltf', 'glb'],
        video: ['mp4', 'avi', 'wmv', 'mov'],
      };
      const oFile = this.$refs.file.files[0];
      const sFileExtension = oFile?.name?.split('.').pop();

      /**
       *
       * @returns {string|false} File ID, delivered by this.fileUpload()
       */
      const fnUploader = async () => {
        if (!_this.fileData.fileType) return;

        _this.fileData.progress = 0;

        if (_this.fileData.fileType == 'video') {
          // upload to vimeo
          await _this.vimeoInit();
          return;
        } else {
          const oFormData = new FormData();
          oFormData.append('tags', _this.fileData.fileType);
          oFormData.append('work', _this.Post.id);

          if (_this.fileData.fileExtension == 'glb') {
            const oGlb = new Blob([_this.fileData.file], { type: 'model/gltf-binary' });
            oFormData.append('file', oGlb, _this.fileData.file.name);
          } else if (_this.fileData.fileExtension == 'gltf') {
            const oGltf = new Blob([_this.fileData.file], { type: 'model/gltf+json' });
            oFormData.append('file', oGltf, _this.fileData.file.name);
          } else {
            oFormData.append('file', _this.fileData.file);
          }

          const aFiles = _this.fileUpload(oFormData);
          return aFiles;
        }
      };

      // Check file type
      let sFileType = undefined;
      for (let sAllowedFileType in oAllowedTypes) {
        if (oAllowedTypes[sAllowedFileType].indexOf(sFileExtension.toLowerCase()) !== -1) {
          sFileType = sAllowedFileType;
          break;
        }
      }

      // Upload
      if (sFileType) {
        this.fileData = {
          ...this.fileData,
          file: oFile,
          fileType: sFileType,
          fileExtension: sFileExtension
        };

        this.fileData.isUploading = true;
        const bWriteFileType = await this.updateFileType(sFileType || 'none');

        const bUploadSuccessful = fnUploader() !== false;

        await this.fetchPost();

        if (!bUploadSuccessful) {
          // Reset file type
          await this.updateFileType(null);
        } else if (this.Post.type === 'image') {
          // Upload 3D files for image
          await this.createMissingModels();
        } else if (this.Post.type === 'model') {
          // Upload usdz file
          await this.createMissingModels();
        }
        this.fileData.isUploading = false;

        bUploadSuccessful && bWriteFileType
          ? this.$refs.messageBox.showMessage('success', this.text.artwork_image_upload_success, 1000)
          : this.$refs.messageBox.showMessage('error', this.text.internal_error);
      } else {
        // Replaces a single placeholder __EXTENSION__ with the extension name of the current file
        this.$refs.messageBox.showMessage('error', this.text.artwork_import_filetype_impossible?.replace('__EXTENSION__', sFileExtension));
      }
      this.loaderCurrentTask = '';
    },


    /**
     * Updates the filetype of the artwork entry
     *
     * @param { 'image' | 'model' | 'video' | 'none' | null } sFileType Filetype
     */
    async updateFileType(sFileType) {
      if (this.Post.block_file_control) return;

      try {
        // console.log('updateFileType ' + sFileType.toString());
        await this.UpdatePost({ type: sFileType || 'none' });
        this.Post.type = sFileType || 'none';
        if (sFileType === 'image') this.form.placement = 'wall';
        return true;
      } catch (oError) {
        return false;
      }
    },


    /**
     * Upload a file
     *
     * @param {FormData} oFormData Form data containing title,tags,work,file
     * @returns {any[]|false} Avatar ID
     */
    async fileUpload(oFormData) {
      if (this.Post.block_file_control) return;

      this.fileData.progress = 0.01;

      const oResponse = await axios.post(`${this.url}/files`, oFormData, {
        headers: {
          'Authorization': `Bearer ${this.Token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: oEvent => this.fileData.progress = Math.round((oEvent.loaded * 100) / oEvent.total)
      });

      // Upload was successful
      if (oResponse?.status === 200 && oResponse.data?.data) {
        const oFileData = oResponse.data?.data;

        this.Post.directus_files.push(oFileData);

        if (oFileData.tags && oFileData.tags.indexOf('image') !== -1) {
          // Convert image to 3D models and upload models
          oModelViewer.clearScene();
          oModelViewer.addLights();
          oModelViewer2.clearScene();
          oModelViewer2.addLights();

          const iSize = this.form.size_unit && this.form.size ? fnUnitToMeter(this.form.size_unit, this.form.size) || null : null;
          await Promise.all([
            oModelViewer.loadImage3d(`https://api.arxafrica.net/assets/${oFileData.id}?key=compressed`, 'wall', false, iSize),
            oModelViewer2.loadImage3d(`https://api.arxafrica.net/assets/${oFileData.id}?key=compressed`, 'wall', true, iSize)
          ]);

          oModelViewer.animate();
          oModelViewer2.animate();

          this.image3dLoaded = true;
          // await this.createMissingModels();
        } else if (oFileData.tags && oFileData.tags.indexOf('model') !== -1) {
          // Convert image to 3D models and upload models
          oModelViewer.clearScene();
          oModelViewer.addLights();
          await oModelViewer.load3d(`https://api.arxafrica.net/assets/${oFileData.filename_disk}`);
          // await this.createMissingModels();
        }
      }

      await this.fetchPost(false);
      return this.Post.directus_files;
    },


    /**
     * Delete a file
     *
     * @param {number[]} aFileIds File IDs
     */
    async fileDelete(aFileIds = undefined, bResetFileType = true) {
      if (await this.isBlockedByExhibition() || this.Post.block_file_control) {
        this.$refs.messageBox.showMessage('error', this.text.artwork_delete_not_possible);
        return;
      }

      this.fileData.isUploading = true;
      this.fileData.progress = null;
      this.loaderCurrentTask = this.text.artwork_progress_remove_file;

      // Remove all files from artwork
      await this.fetchPost(false);
      const bChangeType = aFileIds === undefined || bResetFileType;
      if (!aFileIds) aFileIds = this.Post.directus_files.map(oFile => oFile.id);

      // Delete all files and reset filetype at the same time
      const aPromise = [];
      if (aFileIds?.length) aPromise.push(fnDeleteFiles(aFileIds, this.url, this.Token));
      if (bChangeType) aPromise.push(new Promise(resolve => this.updateFileType('none').then(() => resolve()))); // Update file type in artwork entry
      await Promise.all(aPromise);

      await this.fetchPost(false);
      this.loaderCurrentTask = '';
      this.fileData.isUploading = false;
    },


    /**
     * Style changes while dragging a file over the drop zone
     *
     * @param oEvent
     */
    onImageDragover(oEvent) {
      oEvent.preventDefault();
      const oTarget = oEvent.currentTarget;
      if (!oTarget.classList.contains('drag-over')) oTarget.classList.add('drag-over');
    },


    /**
     * Style changes while dragging a file over the drop zone
     *
     * @param oEvent
     */
    onImageDragleave(oEvent) {
      oEvent.currentTarget.classList.remove('drag-over');
    },


    /**
     * Trigger file upload when a file is dropped into the drop zone
     *
     * @param oEvent
     */
    onImageDrop(oEvent) {
      oEvent.preventDefault();

      this.$refs.file.files = oEvent.dataTransfer.files;
      this.handleFileUpload();

      oEvent.currentTarget.classList.remove('drag-over');
    },


    /**
     * Upload video to vimeo
     */
    async vimeoInit() {
      // Request body
      const oBody = {
        upload: {
          approach: 'tus',
          size: this.fileData.file.size
        },
        name: this.form.title,
        description: this.form.description,
        privacy: {
          view: 'disable'
        }
      };

      try {
        const oResponse = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_ARTISTS_API}/me/videos/`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: oBody
        });

        const sVimeoId = oResponse?.data?.uri?.replace(/^\/|\/$/g, '').split('/').pop();

        // Remove leading & trailing slashes
        if (oResponse?.data?.uri) this.UpdatePost({ vimeo_id: sVimeoId });

        // Start and monitor upload
        const _this = this;
        await Promise.all([
          _this.vimeoUpload(oResponse.data.upload.upload_link, _this.fileData.file, 0),
          async () => _this.vimeoMonitor(oResponse.data.upload.upload_link)
        ]);

        if (sVimeoId) this.form.vimeo_id = sVimeoId;
      } catch (error) {
        // console.log(error);
      }
    },


    /**
     * Stepwise upload to vimeo
     *
     * @param {string} sLink Upload link
     * @param {any} oFile Video file
     * @param {number} iOffset Upload offset
     */
    async vimeoUpload(sLink, oFile, iOffset = 0) {
      if (this.Post.block_file_control) return;
      try {
        const oResponse = await axios({
          method: 'patch',
          url: sLink,
          data: oFile,
          headers: {
            'Tus-Resumable': '1.0.0',
            'Upload-Offset': iOffset,
            'Content-Type': 'application/offset+octet-stream',
            'Accept': 'application/vnd.vimeo.*+json;version=3.4'
          }
        });

        if (oResponse?.headers['upload-offset'] < oFile.size) {
          // Continue upload stepwise
          this.vimeoUpload(sLink, oFile, oResponse.headers['upload-offset']);
        } else {
          // Show video
          this.Post.vimeo_id = this.form.vimeo_id;
          this.getVideo();
        }
      } catch (error) {
        throw "Sorry you can't upload this video.";
      }
    },


    /**
     * Watch vimeo upload
     *
     * @param sLink Upload link
     */
    vimeoMonitor(sLink) {
      axios.head(sLink, {
        headers: {
          'Tus-Resumable': '1.0.0',
          'Accept': 'application/vnd.vimeo.*+json;version=3.4',
        }
      }).then(oResponse => {
        if (oResponse?.headers['upload-offset'] >= oResponse?.headers['upload-length']) return;

        this.vimeoMonitor(sLink);

        // Increase progress bar
        const iTotal = (oResponse?.headers['upload-length'] / this.fileData.file.size) * 100
        for (let i = 0; i <= iTotal; i++) this.fileData.progress = i;
      });
    },


    /**
     * Remove video from vimeo
     */
    async deleteVideo() {
      if (await this.isBlockedByExhibition() || this.Post.block_file_control) {
        this.$refs.messageBox.showMessage('error', this.text.artwork_delete_not_possible);
        return;
      }

      const oResponse = await axios.post(`${process.env.VUE_APP_ARTISTS_API}/videos/${this.Post.vimeo_id}`);
      if (oResponse?.data === '') {
        this.form.vimeo_id = null;
        this.Post.vimeo_id = null;
        this.vimeoVideoUrl = null;
        this.UpdatePost({ vimeo_id: null });
        await this.updateFileType('none');
      }
    },


    triggerOnchange(oEvent) {
      const $target = $(oEvent.target);
      const sMin = $target.attr('min');
      const sMax = $target.attr('max');
      const iMin = parseFloat(sMin || 0);
      const iMax = parseFloat(sMax || 0);
      const iValue = $target.val();

      if (sMin !== undefined && iValue < iMin) $target.val(iMin);
      else if (sMax !== undefined && iValue > iMax) $target.val(iMax);
    },


    async isBlockedByExhibition() {
      const { data: aExhibitions } = await axios.post('https://arxafrica.net/lib/php/data/getArtworkExhibitions.php', {
        artwork: this.Post.id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const iCurrentTime = new Date().getTime();
      const iAlmostOneDay = 86400000 - 1;

      return aExhibitions.some(oEvent => oEvent.start_date && oEvent.end_date && (new Date(oEvent.start_date).getTime() > iCurrentTime || new Date(oEvent.end_date) + iAlmostOneDay > iCurrentTime));
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";

.form-row {
  position: relative;
}

.form-groups {
  border-radius: 0.5rem;
  padding: 1rem;
  background: linear-gradient(
    190deg,
    rgba(233, 77, 24, 0.92) 0%,
    rgba(21, 59, 76, 0.92) 100%
  );
}

.select-group-button {
  float: right;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#size-input-container {
  flex: 1;
  margin-right: 2rem;
}

.upload-format:not(:first-of-type) {
  margin-left: 0.25rem;
}

#file-preview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 20rem;
}

#image3d,
#image3d2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 22rem;
  filter: drop-shadow(0 0 0.5rem #000);

  &.hide {
    opacity: 0;
    z-index: -1;
    user-select: none;
  }
}

.preview.upload {
  margin-top: 0.5rem;
}

.preview-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
}

.hide {
  position: absolute;
  opacity: 0;
  z-index: -1;
  user-select: none;
}

.admin-panel {
  & > h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
}

.description {
  margin: 0.5rem 0.25rem 0 0.25rem;
}

#btn-missing-models {
  margin-top: 0.5rem;
}

#btn-recreate-models {
  margin: 1rem auto 0 auto;
}
</style>
