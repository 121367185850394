<template>
  <main>
    <header class="menu-spacer"></header>

    <MessageBox :messageData="message" ref="messageBox" />

    <article v-if="!isLoading" class="form-container">
      <form class="form-element box box-large" @submit.prevent="submit">
        <h1>{{ text.register_title }}</h1>
        <div v-if="!registrationSuccessful">
          <div v-html="text.register_welcome_text"></div>
          <form @submit.prevent="submit">
            <div class="form-row">
              <label class="form-label" for="email">{{ text.register_email }}:</label>
              <input class="form-field" type="email" id="email" name="email" v-model="form.email" />
            </div>
            <div class="form-row">
              <input class="form-checkbox" type="checkbox" name="terms" v-model="terms">
              <label class="form-label form-checkbox-label" v-html="text.register_terms"></label>
            </div>
            <div class="form-double-col-flex">

              <button class="btn" :disabled="!form.email || !terms" type="submit">{{ text.register_register }}</button>

              <p class="no-margin full-width text-right">
                {{ text.register_already_have_account }}
                <router-link :to="`/${this.$route.meta.language}/login?registration=successful`">{{ text.register_login }}</router-link>
              </p>
            </div>
          </form>
        </div>
        <div v-else v-html="text.register_register_response"></div>
      </form>
    </article>

    <article v-if="!isLoading && faq.length > 0" class="form-container">
      <h2 class="form-element box-unstyled">{{ text.register_faq }}</h2>
    </article>
    <Accordeon v-for="item, index in faq" :key="index" :index="index" :title="item.question" :content="item.answer" />

    <Loader v-if="isLoading" position="fixed" />
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import { oMetaDataTemplate, oMessageBoxDataTemplate, fnRunSimultaneously, fnSetPageInformations } from '@/modules/globalFunctions.js';
import MessageBox from '@/components/MessageBox.vue';
import Accordeon from '@/components/Accordeon.vue';
import Loader from "@/components/Loader.vue";

const oTextTemplate = {
  register_title: '',
  register_welcome_text: '',
  register_email: '',
  register_terms: '',
  register_already_have_account: '',
  register_register: '',
  register_login: '',
  register_register_response: '',
  register_faq: '',
  register_email_already_registered: ''
};

export default {
  name: 'Register',
  metaInfo() {
    return this.metaData.content;
  },
  components: {
    MessageBox,
    Accordeon,
    Loader
  },
  data() {
    return {
      url: process.env.VUE_APP_ARTISTS_API,
      metaData: {
        page: 'artists_register',
        content: oMetaDataTemplate
      },
      text: {
        ...oTextTemplate
      },
      isLoading: true,
      terms: false,
      form: {
        email: ''
      },
      message: oMessageBoxDataTemplate,
      registrationSuccessful: false,
      showError: false,
      faq: []
    };
  },
  watch: {
    /**
     * Fetch texts on route change
     */
    $route() {
      this.init();
    }
  },
  created: async function () {
    await this.init();
  },
  methods: {
    ...mapActions(['Register']),

    /**
     * Initialization function
     */
    async init() {
      const _this = this;

      this.isLoading = true;
      this.faq = [];

      await fnSetPageInformations(this, oTextTemplate);

      await fnRunSimultaneously([
        // Get FAQ
        async () => {
          const oResponseFaq = await axios.post('https://arxafrica.net/lib/php/data/getFaq.php', {
            language: _this.$route.meta.language,
            role: 'artist'
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          _this.faq = oResponseFaq?.data || [];
        }
      ], `${this.metaData.page} | FAQ`);

      this.isLoading = false;
    },

    async submit() {
      // Terms confirmations required
      if (!this.terms) return;

      // Trim, lowercase
      if (this.form.email) this.form.email = this.form.email.trim().toLowerCase();

      try {
        const oResponse = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          url: `${this.url}/users/invite`,
          data: this.form
        });

        if (oResponse?.status === 200) {
          if (oResponse?.data === '') this.registrationSuccessful = true;
          if (oResponse?.data?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE') this.$refs.messageBox.showMessage('error', this.text.register_email_already_registered);
        }
      } catch (error) {
        this.showError = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";
</style>
