<template>
  <div class="vimeo-thumb" :style="{ backgroundImage : 'url(' + source + ')' }" />
</template>

<script>
import axios from 'axios';

export default {
  name: 'VimeoThumb',
  data() {
    return {
      source: ''
    };
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    this.getVideoThumb(this.id);
  },
  methods: {
    /**
     * Get link to video thumb
     *
     * @param {string} id Video thumb ID
     */
    async getVideoThumb(id) {
      const oResponse = await axios({
        method: 'get',
        url: `${process.env.VUE_APP_ARTISTS_API}/videos/${id}/pictures`,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (oResponse?.data?.data[0]?.sizes[3]?.link) this.source = oResponse.data.data[0].sizes[3].link;
    }
  }
}
</script>

<style>
.vimeo-thumb {
  overflow: hidden;
  display: block;
  padding-top: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
