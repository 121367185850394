<template>
  <div>
    <div id="bug-report">
      <button type="button" class="btn secondary" @click="openModal()"><Icon name="help" /><span>{{ texts.bug_help }}</span></button>
    </div>
    <div id="bug-report-overlay" :aria-hidden="overlayHidden.toString()" @click="closeModalViaOverlay($event)">
      <div id="bug-report-modal" class="box">
        <button type="button" class="btn close" @click="closeModal()"><Icon name="close" /></button>

        <div class="bug-report-content form-container" :aria-hidden="showSuccess.toString()">
          <div class="form-element">
            <h2>{{ texts.bug_report_form }}</h2>
          </div>
          <form @submit.prevent="sendData" class="form-element">
            <div class="form-row" :aria-hidden="emailGiven && !!bug.email">
              <label class="form-label" for="email">{{ texts.bug_email }}:</label>
              <input class="form-field" type="text" name="email" v-model="bug.email" :disabled="emailGiven && !!bug.email" />
            </div>
            <div class="form-row">
              <label class="form-label" for="name">{{ texts.bug_name }}:</label>
              <input class="form-field" type="text" name="name" v-model="bug.name" />
            </div>
            <div class="form-row">
              <label class="form-label" for="subject">{{ texts.bug_subject }}:</label>
              <input class="form-field" type="text" name="subject" v-model="bug.subject" />
            </div>
            <div class="form-row">
              <label class="form-label" for="description">{{ texts.bug_description }}:</label>
              <textarea class="form-field" name="description" rows="8" v-model="bug.description"></textarea>
            </div>
            <div class="form-footer-single-button">
              <button class="btn" type="submit" :disabled="!bug.subject || !bug.description">{{ texts.bug_submit }}</button>
            </div>
          </form>

          <div class="form-element">
            <div class="form-row">
              <h2>{{ texts.bug_report_mail }}<br/><a :href="`mailto:${this.targetMail}`">{{ targetMail }}</a></h2>
            </div>
          </div>
        </div>

        <div class="bug-report-content form-container" :aria-hidden="(!showSuccess).toString()" v-html="texts.bug_success"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from "@/components/Icon.vue";
import $ from 'jquery';
import axios from 'axios';

export default {
  name: 'BugReport',
  components: {
    Icon
  },
  props: {
    texts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      overlayHidden: true,
      bug: {
        email: '',
        name: '',
        subject: '',
        description: '',
        useragent: ''
      },
      emailGiven: false,
      showSuccess: false,
      targetMail: 'support@arxafrica.net'
    };
  },
  computed: {
    ...mapGetters({ User: 'StateUser' })
  },
  methods: {
    openModal() {
      $('body').css('overflow', 'hidden');

      // Fill email if possible
      if (this.User) {
        this.emailGiven = !!this.User;
        this.bug.email = this.User;
      }

      this.overlayHidden = false;
    },

    closeModal() {
      $('body').css('overflow', '');
      this.overlayHidden = true;
      this.emailGiven = false;
      this.showSuccess = false;
    },

    closeModalViaOverlay(oEvent) {
      if ($(oEvent.target).attr('id') === 'bug-report-overlay') this.closeModal();
    },

    async sendData() {
      try {
        const bIncompleteForm = Object.keys(this.bug).some(sKey => {
          if (sKey === 'useragent' || (this.bug[sKey] && this.bug[sKey].trim())) return false;
          alert(`Please fill field ${sKey}`);
          return true;
        });

        // const bEmailValid = this.bug.email.toLowerCase().trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        // const iNameLength = this.bug.name?.replace(/[0-9 ]+/g, '').trim().length || 0;
        // const iSubjectLength = this.bug.subject?.replace(/[0-9 ]+/g, '').trim().length || 0;
        // const iMessageWordCount = this.bug.description?.trim().match(/\S+/g).length || 0;

        if (bIncompleteForm) return;

        // Get additional data
        this.bug.useragent = navigator.userAgent;

        // if (
        //   bEmailValid &&
        //   iNameLength  >= 3 &&
        //   iMessageWordCount >= 4 &&
        //   iSubjectLength >= 4
        // )
        await axios.post(`${this.url}/items/bugs`, this.bug);

        // Empty inputs
        Object.keys(this.bug).forEach(sKey => {
          if (sKey !== 'email' || !this.emailGiven) this.bug[sKey] = '';
        });

        this.showSuccess = true;
      } catch (oError) {
        alert('Something went wrong! Please send an email manually!');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";
</style>
