<template>
  <div>Error 404</div>
</template>

<script>
export default {
  name: 'ErrorPage',
  async created() {
    location.href = 'https://arxafrica.net/404';
  }
}
</script>
