<template>
  <article id="faq" class="form-container">
    <div class="form-element box accordeon" aria-expanded="false" :data-element="index">
      <h3 class="accordeon-header" @click="toggleAccordeon">{{ item.title }}</h3>
      <div class="js-foldable accordeon-content" style="display:none" v-html="item.content"></div>
    </div>
  </article>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Accordeon',
  props: {
    index: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: {
        index: this.index,
        title: this.title,
        content: this.content
      }
    };
  },
  methods: {
    /**
     * Toggle an accordeon element and collapse all other accordeons
     * @param oEvent
     */
    toggleAccordeon(oEvent) {
      const $accordeon = $(oEvent.target).parents('.accordeon');
      const bExpanded = JSON.parse($accordeon.attr('aria-expanded') || 'false');
      const sId = $accordeon.attr('data-element') || '';
      const fnChangeAria = ($accordeon, sDirection, sValue) => $accordeon.find('.js-foldable')[sDirection](300, 'swing', () => $accordeon.attr('aria-expanded', sValue));

      [...$(`.accordeon:not([data-element="${sId}"])`)].map(elem => fnChangeAria($(elem), 'slideUp', 'false'));
      fnChangeAria($accordeon, bExpanded ? 'slideUp' : 'slideDown', JSON.stringify(!bExpanded));
    }
  }
}
</script>

<style scoped lang="scss">
@import "./../scss/_form.scss";
@import "./../scss/_accordeon.scss";
</style>
