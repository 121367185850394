import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store';
import Register from '@/views/Register';
import Login from '@/views/Login';
import Posts from '@/views/Posts';
import Artwork from '@/views/Artwork';
import Exhibitions from '@/views/Exhibitions';
import Exhibition from '@/views/Exhibition';
import ExhibitionEdit from '@/views/ExhibitionEdit';
import Profile from '@/views/Profile';
import Accept from '@/views/Accept';
import ResetPassword from '@/views/ResetPassword';
import RequestPassword from '@/views/RequestPassword';
import ErrorPage from '@/views/404';

Vue.use(VueRouter);
Vue.use(VueMeta);

/**
 * Routes in multiple languages.
 * Routes without language key point to default language (en)
 *
 * Matomo:
 * Exclude page tracking with meta 'analyticsIgnore: true'
 */
const routes = [
  { meta: { guest: true }, path: '/register', redirect: '/en/register' },
  { meta: { guest: true, language: 'en' }, path: '/en/register', component: Register },
  { meta: { guest: true, language: 'de' }, path: '/de/register', component: Register },
  { meta: { guest: true, language: 'fr' }, path: '/fr/register', component: Register },

  { meta: { guest: true }, path: '/login', redirect: '/en/login' },
  { meta: { guest: true, language: 'en' }, path: '/en/login', component: Login },
  { meta: { guest: true, language: 'de' }, path: '/de/login', component: Login },
  { meta: { guest: true, language: 'fr' }, path: '/fr/login', component: Login },

  { meta: { requiresAuth: true }, path: '/', redirect: '/en/' },
  // Bug: Wrong routing if user gets changed. Workaround: Check for user role 'exhibitor' and reload browser location if needed
  { meta: { requiresAuth: true, language: 'en' }, path: '/en/', component: store.getters.isExhibitor ? Exhibitions : Posts },
  { meta: { requiresAuth: true, language: 'de' }, path: '/de/', component: store.getters.isExhibitor ? Exhibitions : Posts },
  { meta: { requiresAuth: true, language: 'fr' }, path: '/fr/', component: store.getters.isExhibitor ? Exhibitions : Posts },

  { meta: { requiresAuth: true }, path: '/artwork', redirect: '/en/' },
  { meta: { requiresAuth: true, language: 'en' }, path: '/en/artwork', redirect: '/en/' },
  { meta: { requiresAuth: true, language: 'de' }, path: '/de/artwork', redirect: '/de/' },
  { meta: { requiresAuth: true, language: 'fr' }, path: '/fr/artwork', redirect: '/fr/' },

  { meta: { requiresAuth: true }, path: '/artwork/:id', redirect: to => `/en/artwork/${to.params.id}` },
  { meta: { requiresAuth: true, language: 'en' }, path: '/en/artwork/:id', component: Artwork },
  { meta: { requiresAuth: true, language: 'de' }, path: '/de/artwork/:id', component: Artwork },
  { meta: { requiresAuth: true, language: 'fr' }, path: '/fr/artwork/:id', component: Artwork },

  { meta: { requiresAuth: true }, path: '/profile', redirect: '/en/profile' },
  { meta: { requiresAuth: true, language: 'en' }, path: '/en/profile', component: Profile },
  { meta: { requiresAuth: true, language: 'de' }, path: '/de/profile', component: Profile },
  { meta: { requiresAuth: true, language: 'fr' }, path: '/fr/profile', component: Profile },

  { meta: { guest: true }, path: '/accept', redirect: '/en/accept' },
  { meta: { guest: true, language: 'en' }, path: '/en/accept', component: Accept },
  { meta: { guest: true, language: 'de' }, path: '/de/accept', component: Accept },
  { meta: { guest: true, language: 'fr' }, path: '/fr/accept', component: Accept },

  { meta: { guest: true }, path: '/request-password', redirect: '/en/request-password' },
  { meta: { guest: true, language: 'en' }, path: '/en/request-password', component: RequestPassword },
  { meta: { guest: true, language: 'de' }, path: '/de/request-password', component: RequestPassword },
  { meta: { guest: true, language: 'fr' }, path: '/fr/request-password', component: RequestPassword },

  { meta: { guest: true }, path: '/reset-password', redirect: '/en/reset-password' },
  { meta: { guest: true, language: 'en' }, path: '/en/reset-password', component: ResetPassword },
  { meta: { guest: true, language: 'de' }, path: '/de/reset-password', component: ResetPassword },
  { meta: { guest: true, language: 'fr' }, path: '/fr/reset-password', component: ResetPassword },

  // Requires role 'exhibitor':
  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/exhibition', redirect: '/en' },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/en/exhibition', redirect: '/en' },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/de/exhibition', redirect: '/de' },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/fr/exhibition', redirect: '/fr' },

  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/exhibitions/', redirect: '/en/exhibitions/' },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'en' }, path: '/en/exhibitions', component: Exhibitions },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'de' }, path: '/de/exhibitions', component: Exhibitions },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'fr' }, path: '/fr/exhibitions', component: Exhibitions },

  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/exhibition/:id', redirect: to => `/en/exhibition/${to.params.id}` },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'en' }, path: '/en/exhibition/:id', component: Exhibition },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'de' }, path: '/de/exhibition/:id', component: Exhibition },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'fr' }, path: '/fr/exhibition/:id', component: Exhibition },

  { meta: { requiresAuth: true, requiredRole: 'exhibitor' }, path: '/exhibition/:id/edit', redirect: to => `/en/exhibition/${to.params.id}/edit` },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'en' }, path: '/en/exhibition/:id/edit', component: ExhibitionEdit },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'de' }, path: '/de/exhibition/:id/edit', component: ExhibitionEdit },
  { meta: { requiresAuth: true, requiredRole: 'exhibitor', language: 'fr' }, path: '/fr/exhibition/:id/edit', component: ExhibitionEdit },

  // 404
  { path: '*', component: ErrorPage },
];

// Initialize router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Redirect unauthorized routing
router.beforeEach((to, from, next) => {
  try {
    to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated
      ? next('/login')
      : next();
  } catch (oError) {
    location.href = 'https://arxafrica.net/404';
  }
});

// Redirect authenticated users
router.beforeEach((to, from, next) => {
  try {
    to.matched.some(record => record.meta.guest) && store.getters.isAuthenticated
      ? next('/')
      : next();
  } catch (oError) {
    location.href = 'https://arxafrica.net/404';
  }
});

export default router;
